import { Alert, LoggedUser, Router, toaster, utils } from '@autoprog/core-client';

import { Moment } from 'moment';

import OpenDocuments from '@libs/customElement/OpenDocuments';

import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';

import BillTab, { updateFormEvent as updateBillData } from '@modules/BillsCustomer/js/customElements/BillsCustomersTab';
import DeliveryTab from '@modules/Deliveries/js/customElements/DeliveriesTab';
import ProviderOrderTab from '@modules/OrdersProvider/js/customElements/ProviderOrderTabs';

import QuotesTab, { updateFormEvent as updateQuoteData } from '../customElements/QuotesTab';
import BillingRequestTab from '../customElements/BillingRequestTab';
import CE_AddressDeliveryReadOnly from '@libs/customElement/AddressDeliveryReadonly';
import ComptaTab from '../../../Comptabilité/js/customElements/ComptaTab';
import MaterialsTab from '../customElements/MaterialsTab';
import OutputStockTab from '../customElements/OutputStockTab';

import Decimal from '@libs/utils/Decimal';
import Loader from '@libs/Loader';
import Notifications from '@modules/Apps/js/libs/Notifications';

import M_AdditionalInformation from '../modals/editPage/AdditionalInformation';
import M_DetailsOrder from '../modals/editPage/DetailsOrder';
import M_GeneralInformation from '../modals/editPage/GeneralInformation';
import M_SelectQuote from '../modals/SelectQuote';

import M_EditQuote from '../../../OrdersCustomer/js/modals/EditQuote';

import M_OrderFromQuote from '../modals/orderFromQuote';

import M_PrintPreview from '@js/libs/modals/PrintPreview';

import S_C_Address from '@services/Customer/CustomerAddressService';
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Quote from '@services/QuoteService';

import '../../css/pageControllerID.scss';
import PriceWithPercentModel from '@js/libs/model/_app/PriceWithPercent';

class CommandCustomerCtrl extends ControllerPageID {
	private N_BillTab: BillTab | null = null;
	private N_ProviderOrderTab: ProviderOrderTab | null = null;
	private N_DeliveryTab: DeliveryTab | null = null;
	private N_BillingRequestTab: BillingRequestTab | null = null;
	private N_MaterialsTab: MaterialsTab | null = null;
	private N_QuotesTab: QuotesTab | null = null;
	private N_OutputStockTab: OutputStockTab | null = null;

	private N_ComptaTab: ComptaTab | null = null;
	private errorCompta: boolean = false;

	private price: { priceHT: Decimal, bill: Decimal, credit: Decimal, billingRequest: Decimal };

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.price = {
			priceHT: new Decimal(0),
			bill: new Decimal(0),
			credit: new Decimal(0),
			billingRequest: new Decimal(0)
		};

		this.options = CommandCustomerCtrl.options || {};

		CommandCustomerCtrl.options = {};

		this.routeReturn = 'module/orders/customers';

		this.init('commands-customer', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		CommandCustomerCtrl.options = options || {};

		if (id) {
			if (options.updatedQuoteId) {
				const data = await S_C_Order.getInstance().getQuoteToOrder(id, options.updatedQuoteId);
				await new M_EditQuote(data.customer).setEditData(data.orderQuote).open()
					.then((data: any) => S_C_Order.getInstance().save(data, { idOrder: id, type: 'updateQuote' }))
					.catch(() => {});
			}

			await OpenDocuments.checkOpen(id, 'commands-customer');
			Router.getInstance().navigate(`/module/ordersPage/customer?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/ordersPage/customer');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initTabs();
		this.initEditButton();

		const data = await this.getData();

		this.setData(data);

		this.postInit();

		this.initFullscreen();

		this.updateEditButton();

		if (!this.id) {
			this.openOrderFromQuote(data.data.quoteNumber ?? '', data.data.label ?? '', data.data.description ?? '');
		}
	}

	private async openOrderFromQuote(quoteNumber: string, label: string, description: string) {
		const quoteToOrderData = await S_Quote.getInstance().convertToOrder(this.options.idQuote!);

		const data = {
			quoteID: this.options.idQuote,
			quoteNumber,
			infosCustomer: {
				orderNumber: this.form?.getDataByName('infosCustomer.orderNumber') as string,
				addonNumber: this.form?.getDataByName('infosCustomer.addonNumber') as string,
				customer: this.form?.getDataByName('infosCustomer.customer') as string,
				contact: this.form?.getDataByName('infosCustomer.contact') as string
			},
			manager: LoggedUser.getInstance().get('id') as string,
			label,
			description,
			deliveryAddress: quoteToOrderData.deliveryAddress,
			entryDate: quoteToOrderData.entryDate ? new Date(quoteToOrderData.entryDate) : undefined,
			date: quoteToOrderData.date ? new Date(quoteToOrderData.date) : undefined,
			deliveryDate: quoteToOrderData.deliveryDate ? new Date(quoteToOrderData.deliveryDate) : undefined,
			finalCustomer: quoteToOrderData.finalCustomer,
			sites: quoteToOrderData.sites,
			constributors: quoteToOrderData.constributors,
			informedPeople: quoteToOrderData.informedPeople,
			purchaseManager: quoteToOrderData.purchaseManager,
			planningManager: quoteToOrderData.planningManager,
			price: quoteToOrderData.price,
			selectedGroups: quoteToOrderData.selectedGroups
		};

		new M_OrderFromQuote(data).open().then(async (data: any) => {
			if (!data.selectedOrderId) {
				// Sauvegarde d'une nouvelle commande
				await this.save(true);

				// On sauvegarde les données dans la commande avec le devis ajouté
				await this.service?.save(data, { idOrder: this.id, type: 'updateQuote', isNewOrder: true });
			} else {
				// On sauvegarde les données dans la commande avec le devis ajouté
				await this.service?.save(data, { idOrder: data.selectedOrderId, type: 'updateQuote', isNewOrder: true });

				this.id = data.selectedOrderId;
			}

			// Définition de l'url
			this.setUrl();

			// Rafraichissement de la page
			delete this.options.idQuote;
			this.reload();
		}).catch(() => {
			this.return();
		});
	}

	private openGeneralInformation() {
		const res = {
			infosCustomer: {
				orderNumber: this.form?.getDataByName('infosCustomer.orderNumber') as string,
				addonNumber: this.form?.getDataByName('infosCustomer.addonNumber') as string,
				customer: this.form?.getDataByName('infosCustomer.customer') as string,
				contact: this.form?.getDataByName('infosCustomer.contact') as string
			},
			manager: this.form?.getDataByName('manager') as string
		};

		new M_GeneralInformation(res).open().then(async (data) => {
			this.setDataForm(data);

			if (res.infosCustomer.customer !== data.infosCustomer.customer.id) {
				await this.updateAddress();
			}

			this.updateTitle();
			this.enableSaveButton();
		});
	}

	private openDetailsOrder(isFirstOpen: boolean = false) {
		const res = {
			label: this.form?.getDataByName('label') as string,
			description: this.form?.getDataByName('description') as string
		};

		const modal = new M_DetailsOrder(res);

		modal.open().then((data) => {
			this.setDataForm(data);

			if (!isFirstOpen) {
				this.enableSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openAdditionalInformation(isFirstOpen: boolean = false) {
		const res = {
			infosCustomer: {
				customer: this.form?.getDataByName('infosCustomer.customer') as string
			},
			autoliquidation: this.form?.getDataByName('autoliquidation') as boolean,
			billAddress: {
				id: this.form?.getDataByName('billAddress.id') as string,
				text: this.form?.getDataByName('billAddress.text') as string
			},
			billDeliveryAddress: {
				id: this.form?.getDataByName('billDeliveryAddress.id') as string,
				text: this.form?.getDataByName('billDeliveryAddress.text') as string
			},
			deliveryAddress: {
				address: this.form?.getDataByName('deliveryAddress.address') as string,
				site: this.form?.getDataByName('deliveryAddress.site') as string,
				text: this.form?.getDataByName('deliveryAddress.text') as string,
				type: this.form?.getDataByName('deliveryAddress.type') as string,
				GPSCoordinates: this.form?.getDataByName('deliveryAddress.GPSCoordinates') as string
			},
			deadlinePayment: {
				day: this.form?.getDataByName('deadlinePayment.day') as number,
				type: this.form?.getDataByName('deadlinePayment.type') as string,
				fixedDate: this.form?.getDataByName('deadlinePayment.fixedDate') as Moment
			},
			finalCustomer: this.form?.getDataByName('finalCustomer') as string,
			detailsBill: this.form?.getDataByName('detailsBill') as string,
			comment: this.form?.getDataByName('comment') as string
		};

		let modal: M_AdditionalInformation;

		if (isFirstOpen) {
			modal = new M_AdditionalInformation(res).setPreviousCallback(() => {
				this.openDetailsOrder(isFirstOpen);
			});
		} else {
			modal = new M_AdditionalInformation(res);
		}

		modal.open().then((data) => {
			this.setDataForm(data);

			this.updateTitle();

			if (!isFirstOpen || !this.options?.idQuote) {
				this.enableSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsOrder = this.el.querySelector('[data-edit="detailsOrder"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			this.openGeneralInformation();
		});

		N_edit_DetailsOrder.addEventListener('click', () => {
			this.openDetailsOrder();
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			this.openAdditionalInformation();
		});
	}

	protected initButton() {
		super.initButton();

		const N_cancel = this.el.querySelector('#cancel') as HTMLElement;
		const N_report = this.el.querySelector('#report') as HTMLDivElement;

		N_report.addEventListener('click', async () => {
			const quotes = this.N_QuotesTab?.data;

			if (quotes.length > 1) {
				const res = {
					quotes
				};

				new M_SelectQuote(res).open().then(async (data) => {
					(await new M_PrintPreview('commands-customer', this.id, 'pdfReport').getToken(data)).open();
				});
			} else {
				const ids = quotes.map((item: any) => item.quoteID);
				(await new M_PrintPreview('commands-customer', this.id, 'pdfReport').getToken(ids)).open();
			}
		});

		const title = '<i class="icon icon-warning text-danger"></i> Annulation de la commande <i class="icon icon-warning text-danger"></i>';
		const content = '<span class="font-weight-bold">Attention</span>, cette opération va réinitialiser le stock et les devis associés à la commande seront refusés.<br><br>Voulez-vous continuer ?';

		N_cancel.addEventListener('click', () => {
			Alert.confirm(title, content).then(async () => {
				Loader.getInstance().open();
				await this.save(true);

				await S_C_Order.getInstance().cancelOrder(this.id);

				toaster.success(`Commande N° ${this.form?.getDataByName('internalNumber')} annulée`);

				Loader.getInstance().close();

				this.return();
			}).catch(() => {
				Loader.getInstance().close();
			});
		});

		const N_closeNotification = this.el.querySelector('#close_notification') as HTMLButtonElement;

		if (this.options.notification) {
			(N_closeNotification.parentNode as HTMLElement).classList.remove('d-none');
		}

		N_closeNotification.addEventListener('click', async () => {
			await Notifications.closeNotification(this.options.notification);
			this.return();
		});
	}

	private initTabs() {
		this.N_ProviderOrderTab = this.el.querySelector(ProviderOrderTab.tagName) as ProviderOrderTab;
		this.N_ProviderOrderTab.setParentElement(this.el);

		this.N_BillTab = this.el.querySelector(BillTab.tagName) as BillTab;
		this.N_BillTab.setParentElement(this.el);

		this.N_BillTab.addEventListener('update.form', ((e: CustomEvent<updateBillData>) => {
			this.price.bill = e.detail.priceHT;
			this.price.credit = e.detail.priceCredit;

			this.form?.setDataByName('paymentPrice', e.detail.paymentPrice);
			this.form?.setDataByName('notPaymentPrice', e.detail.notPaymentPrice);

			this.updatePrice();
		}) as EventListener);

		this.N_BillTab.addEventListener('load', ((e: CustomEvent<{ number: number }>) => {
			const N_cancel = this.el.querySelector('#cancel') as HTMLElement;

			if (e.detail.number !== 0) {
				N_cancel.classList.add('disabled');
			} else {
				N_cancel.classList.add('cursor-pointer');
			}
		}) as EventListener);

		this.N_DeliveryTab = this.el.querySelector(DeliveryTab.tagName) as DeliveryTab;
		this.N_DeliveryTab.setParentElement(this.el);

		this.N_BillingRequestTab = this.el.querySelector(BillingRequestTab.tagName) as BillingRequestTab;
		this.N_BillingRequestTab.setParentElement(this.el);

		this.N_BillingRequestTab.addEventListener('load', ((e: CustomEvent<{ sum: Decimal }>) => {
			this.price.billingRequest = e.detail.sum;

			this.updatePrice();

			this.N_BillTab?.initData(this.errorCompta);
		}) as EventListener);

		this.initProducts();
		this.initQuotes();

		this.initCompta();
	}

	private initProducts() {
		this.N_MaterialsTab = this.el.querySelector(MaterialsTab.tagName) as MaterialsTab;

		this.N_MaterialsTab.setParentElement(this.el);

		this.N_MaterialsTab.addEventListener('update', () => {
			this.enableSaveButton();
		});

		this.N_OutputStockTab = this.el.querySelector(OutputStockTab.tagName) as OutputStockTab;

		this.N_OutputStockTab.setParentElement(this.el);
	}

	private initQuotes() {
		this.N_QuotesTab = this.el.querySelector(QuotesTab.tagName) as QuotesTab;

		this.N_QuotesTab.setParentElement(this.el);

		this.N_QuotesTab.setCustomer(() => {
			return this.form?.getDataByName('infosCustomer.customer');
		});

		this.N_QuotesTab.addEventListener('update.form', ((e: CustomEvent<updateQuoteData>) => {
			this.price.priceHT = e.detail.price;

			this.updatePrice();
		}) as EventListener);

		this.N_QuotesTab.addEventListener('refresh', () => {
			this.reload();
		});

		this.N_QuotesTab.addEventListener('silent-refresh', () => {
			this.silentReload();
		});

		this.N_QuotesTab.setCheckDelete((quote) => {
			return new Promise<void>((resolve, reject) => {
				// Si la commande n'est pas sauvegardée

				if (!this.isSaved) {
					reject(new Error('Veuillez sauvegarder la commande'));
				}

				// Si les factures ne sont pas encore chargées

				if (!this.N_BillTab!.isLoad) {
					reject(new Error('Attendez le chargement des factures avant de supprimer un devis'));
				}

				// Si le devis est déjà partiellement ou intégralement facturé

				const bills = this.N_BillTab?.bills;

				const quoteGroups: {[key: string]: number} = quote.selectedGroups;
				const quoteBills = [];

				for (const bill of bills ?? []) {
					let hasGroupBilled = false;

					for (const content of bill.content ?? []) {
						if (Object.keys(quoteGroups).includes(content.groupID)) {
							hasGroupBilled = true;
						}
					}

					if (hasGroupBilled) {
						quoteBills.push(bill);
					}
				}

				if (quoteBills.length) {
					const billsNumbersText = quoteBills.reduce((res, bill) => {
						return res + '<br> • ' + bill.infos.number;
					}, '');

					let quoteBilledText = 'Le devis est déjà facturé partiellement ou intégralement par la facture suivante :<br>';

					if (quoteBills.length > 1) {
						quoteBilledText = 'Le devis est déjà facturé partiellement ou intégralement par les factures suivantes :<br>';
					}

					reject(new Error(quoteBilledText + billsNumbersText));
				}

				// Si le montant non facturé < montant du devis

				const notBill = this.N_QuotesTab!.sum.minus(this.N_BillTab!.sum);

				if (notBill.toNumber() < quote.price) {
					reject(new Error('Le montant facturé serait supérieur au montant des devis'));
				}

				// Si il y a des demandes de facturation en attente

				const billingRequests = this.N_BillingRequestTab?.data || [];

				let find = false;
				for (const br of billingRequests) {
					if (br.state === 'waiting' && !br.deleted && !find) {
						find = true;
						break;
					}
				}

				if (find) {
					reject(new Error('Veuillez terminer ou supprimer toutes les demandes de facturation en cours'));
				} else {
					resolve();
				}
			});
		});
	}

	private async initCompta() {
		this.N_ComptaTab = this.el.querySelector(ComptaTab.tagName) as ComptaTab;

		this.N_ComptaTab.setParentElement(this.el);
	}

	private get isLock() {
		const finish = this.form?.getDataByName('infos.finish');
		return finish || !LoggedUser.getInstance().hasPermission('ORDERS._CUSTOMERS.EDIT');
	}

	protected setDataForm(data: { [key: string]: any; }) {
		if (data.deliveries?.state) {
			const stateDeliveries: { [key: string]: any } = {
				0: 'À préparer',
				1: 'En cours de préparation',
				2: 'Prête partiellement',
				3: 'Prête en totalité',
				4: 'Expédiée partiellement',
				5: 'Expédiée en totalité'
			};

			data.deliveries.state = stateDeliveries[data.deliveries?.state];
		}

		super.setDataForm(data);

		//FIXME:
		/*
		const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
		N_sites.update(data.infos.hasFinalCustomer);
		*/
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.N_MaterialsTab!.data = data.data.content;
		this.N_QuotesTab!.data = data.data.orders;
		this.N_ComptaTab!.data = data.data.compta ? data.data.compta.data : [];
		this.errorCompta = data.data.compta ? data.data.compta.error : false;

		const N_report = this.el.querySelector('#report') as HTMLElement;
		N_report.classList.remove('disabled');

		/*const N_comptaErrorAlert = this.el.querySelector('#comptaError') as HTMLDivElement;

		if (data.compta.error) {
			N_comptaErrorAlert.classList.remove('d-none');
		} else {
			N_comptaErrorAlert.classList.add('d-none');
		}*/
		this.N_BillTab?.initData(this.errorCompta);
		this.N_ProviderOrderTab?.initData();
		this.N_OutputStockTab?.initData();
		this.N_DeliveryTab?.initData();
		this.N_BillingRequestTab?.setMode('order').initData();

		const N_AddressDeliveryReadOnly = this.el.querySelector<CE_AddressDeliveryReadOnly>(CE_AddressDeliveryReadOnly.tagName)!;
		N_AddressDeliveryReadOnly.update(data.data.deliveryAddress.type);
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		newData.content = this.N_MaterialsTab!.data || [];

		return newData;
	}

	protected enableSaveButton() {
		super.enableSaveButton();

		const N_report = this.el.querySelector('#report') as HTMLElement;

		N_report.classList.add('disabled');
	}

	private async updateAddress() {
		this.form?.setDataByName('infos.addressID', '');
		this.form?.setDataByName('infos.fullAddress', '');

		this.form?.setDataByName('infos.addressIDDelivery', '');
		this.form?.setDataByName('infos.fullAddressDelivery', '');

		const customer = this.form?.getDataByName('infos.customer') as string;

		if (customer) {
			const customerBillAddressList = await S_C_Address.getInstance().getBillAddressByCustomer(customer) || [];

			if (customerBillAddressList[0]) {
				const id = customerBillAddressList[0]._id as string;

				const addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(id);
				const addressInfos = await S_C_Address.getInstance().getAddressInfos(id);

				this.form?.setDataByName('infos.addressID', addressIDCustomer);
				this.form?.setDataByName('infos.fullAddress', addressInfos.fullAddress);
			}
		}
	}

	private updatePrice() {
		const notBill = this.price.priceHT.plus(this.price.credit).minus(this.price.bill);

		const notBillTxt = PriceWithPercentModel.calculAndConvertToModel(notBill, this.price.priceHT).getText();
		const billTxt = PriceWithPercentModel.calculAndConvertToModel(this.price.bill, this.price.priceHT).getText();
		const creditTxt = PriceWithPercentModel.calculAndConvertToModel(this.price.credit, this.price.priceHT).getText();
		const billingRequestTxt = PriceWithPercentModel.calculAndConvertToModel(this.price.billingRequest, this.price.priceHT).getText();

		this.form?.setDataByName('billingRequest.priceHT', billingRequestTxt);

		this.form?.setDataByName('priceHT', this.price.priceHT);
		this.form?.setDataByName('bill.notBill', notBillTxt);
		this.form?.setDataByName('bill.priceHT', billTxt);
		this.form?.setDataByName('bill.priceCredit', creditTxt);
		this.N_BillTab!.disabledCreateButton(notBill.lessThan(0) || notBill.equals(0));
		this.N_BillTab!.disabledCreateButton(notBill.lessThan(0) || notBill.equals(0));
		this.N_BillingRequestTab!.disabledCreateButton(notBill.lessThan(0) || notBill.equals(0));

		const N_nonValoriseeAlert = this.el.querySelector<HTMLDivElement>('#nonValorisee')!;

		if (this.price.priceHT.equals(0) && this.N_QuotesTab!.data.length === 0) {
			N_nonValoriseeAlert.classList.remove('d-none');
		} else {
			N_nonValoriseeAlert.classList.add('d-none');
		}
	}

	/*
	private async updateDeadlinePayment() {
		const id_customer = this.form?.getDataByName('infosCustomer.customer') as string;

		const customer = await S_Customer.getInstance().getById(id_customer);

		if (customer) {
			this.form!.setDataByName('deadlinePayment.day', customer.deadlinePayment);
			this.form!.setDataByName('deadlinePayment.type', customer.typeDeadlinePayment);
			this.form!.setDataByName('deadlinePayment.fixedDate', customer.fixedDateDeadline);
		}
	}
	*/

	private updateEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsOrder = this.el.querySelector('[data-edit="detailsOrder"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_DetailsOrder.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;
	}
}

export default CommandCustomerCtrl;
