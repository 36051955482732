import { Module, Modules, Router } from '@autoprog/core-client';

import C_BillsProvider from './js/controllers/Bills.Provider';
import T_BillsProvider from './tpl/bills.provider.html';

import CE_BillsProviderTab from './js/customElements/BillsProviderTab';
import CE_PaymentTab from './js/customElements/Payment';
import CE_PriceBillProvider from './js/customElements/PriceBillProvider';

class BillProviderModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_PaymentTab.register();
		CE_BillsProviderTab.register();
		CE_PriceBillProvider.register();
	}

	public async preInit() {

	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/billsPage/provider',
			controller: C_BillsProvider as any,
			tpl: T_BillsProvider,
			requireValidUser: true,
			permission: 'BILLS._PROVIDERS.OPEN'
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Factures-Fournisseur', BillProviderModule);
