// TEMPLATE
import T_NavbarUser from '../../tpl/customElements/navbar-user.html';

// STYLE
import '../../css/customElements/navbar-user.scss';

// CORE
import { LoggedUser, Router } from '@autoprog/core-client';

// CONTROLLERS

/**
 * CustomElement fait pour être intégré dans la barre de navigation du haut sur la version PC.
 * 
 * Affiche le nom de l'utilisateur connecté et propose un menu déroulant au clic.
 */
class NavbarUser extends HTMLElement {
	/**
	 * Nom de tag du Custom Element
	 */
	public static readonly tagName: string = 'ap-navbar-user';

	/**
	 * Détermine si l'évènement à la connexion est initialisé
	 */
	private static isLoginEventInit: boolean = false;

	/**
	 * Méthode appelée lorsque l'élément est connecté pour la première fois au DOM
	 */
	public async connectedCallback() {
		this.innerHTML = T_NavbarUser;

		if (!NavbarUser.isLoginEventInit) {
			NavbarUser.isLoginEventInit = true;
			this.initLoginEvent();
		}

		this.init();
	}

	/**
	 * Initialise l'évènement qui est levé à la connexion de l'utilisateur pour rafraichir les informations
	 */
	private initLoginEvent() {
		LoggedUser.getInstance().on('login', () => {
			this.initUserInfos();
		});
	}

	/**
	 * Fonction d'initialisation principale
	 */
	private init() {
		this.initTooltip();
		this.disableTextSelection();
		this.initUserInfos();
		this.initMenuDropdownButtons();
		this.removeTooltipOnClick();
	}

	/**
	 * Initialise le tooltip au survol du bouton
	 */
	private initTooltip() {
		const N_Button = this.querySelector('button') as HTMLButtonElement;
		N_Button.setAttribute('tooltip', 'Utilisateur');
	}

	/**
	 * Désactive la selection du texte
	 */
	private disableTextSelection() {
		this.classList.add('text-not-selectable');
	}

	/**
	 * Initialise les informations de l'utilisateur
	 */
	private initUserInfos() {
		const loggedUser = LoggedUser.getInstance();
		loggedUser.whenLogged().then(() => {
			const firstname = loggedUser.get('firstname');
			const lastname = loggedUser.get('lastname');

			const N_UserName = this.querySelector('#user-name') as HTMLSpanElement;

			N_UserName.innerHTML = firstname + ' ' + lastname;
		});
	}

	/**
	 * Initialise les boutons du menu déroulant
	 */
	private initMenuDropdownButtons() {
		const N_Disconnect = document.querySelector('#navbar-user-disconnect') as HTMLDivElement;
		const N_Profile = document.querySelector('#navbar-user-profile') as HTMLDivElement;

		const router = Router.getInstance();

		N_Disconnect.addEventListener('click', async () => {
			if (await LoggedUser.getInstance().emitAsync('before-logout')) {
				LoggedUser.getInstance().logout();
			}
		});

		N_Profile.addEventListener('click', () => {
			router.navigate('/module/profile/user');
		});
	}

	/**
	 * Retire le tooltip du bouton quand on clique dessus
	 */
	private removeTooltipOnClick() {
		const N_UserButton = this.querySelector('#user-button') as HTMLButtonElement;

		N_UserButton?.addEventListener('click', () => {
			$(N_UserButton).tooltip('hide');
		});
	}

	/**
	 * Méthode d'enregistrement du Custom Element
	 */
	public static register() {
		customElements.define(NavbarUser.tagName, NavbarUser);
	}
}

export default NavbarUser;
