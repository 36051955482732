import M_CreateOrder from '@modules/OrdersProvider/js/modals/CreateOrderByProduct';

/**
 * Classe pour récupérer des élémenents de contextMenu liées aux produits
 */
class CreateOrderContextMenu {
	/**
	 * Retourne le contextMenu pour commander le produit passé en paramètre
	 * @param product
	 * @param disabled
	 * @returns
	 */
	public static orderProduct(products: { productID: string, quantity: number }[], disabled: boolean = false, afterChangePage: () => void = () => { }, beforeChangePage: () => void = () => { }) {
		return {
			name: `Commander ${products.length > 1 ? 'les produits (' + products.length + ')' : 'le produit'}`,
			disabled,
			icon: '<ap-icon name="shopping-cart/line"></ap-icon>',
			action: async () => {
				beforeChangePage && beforeChangePage();
				await new M_CreateOrder(products).open();
				afterChangePage && afterChangePage();
			}
		};
	}
}

export default CreateOrderContextMenu;
