import { DOM, SlimScroll } from '@autoprog/core-client';

import CE_PageTabsMenuContent from './PageTabsMenuContent';
import CE_PageTabsMenuItem from './PageTabsMenuItem';

class PageTabsMenu extends HTMLElement {
	public static readonly tagName: string = 'ap-page-tabs-menu';

	private _currentIndex = 0;

	public async connectedCallback() {
		await DOM.nextTick();

		const N_pageTabsMenuItem = this.querySelectorAll<CE_PageTabsMenuItem>('ap-page-tabs-menu-item');

		const N_PageTabsMenuContent = this.parentElement?.querySelector<CE_PageTabsMenuContent>(CE_PageTabsMenuContent.tagName);

		N_pageTabsMenuItem[0].open();
		const href = N_pageTabsMenuItem[0].href;
		N_PageTabsMenuContent?.setActive(href);

		let index = 0;
		for (const N_el of N_pageTabsMenuItem) {
			N_el.dataset.index = index.toString();
			N_el.addEventListener('click', () => {
				const href = N_el.href;

				this.setActive(href);
			});
			index++;
		}

		new SlimScroll(this);

		this.classList.add('text-not-selectable');

		const N_el = this.querySelector('ap-page-tabs-menu-title') as HTMLElement;

		let value = localStorage.getItem('apps_hidden_navigation_page') === 'true';

		if (!value) {
			this.classList.add('active');
		}

		N_el?.addEventListener('click', () => {
			this.classList.toggle('active');

			value = !value;

			localStorage.setItem('apps_hidden_navigation_page', value.toString());
		});
	}

	public setActive(href: string) {
		const N_pageTabsMenuItem = this.querySelectorAll<CE_PageTabsMenuItem>('ap-page-tabs-menu-item');
		const N_PageTabsMenuContent = this.parentElement?.querySelector<CE_PageTabsMenuContent>(CE_PageTabsMenuContent.tagName);

		let N_item: CE_PageTabsMenuItem | null = null;

		for (const N_el of N_pageTabsMenuItem) {
			N_el?.close();

			if (N_el.href === href) {
				N_item = N_el;
			}
		}

		this._currentIndex = Number(N_item?.dataset.index);

		N_PageTabsMenuContent?.setActive(href);
		N_item?.open();
	}

	public get currentIndex() {
		return this._currentIndex;
	}

	public static register() {
		customElements.define(PageTabsMenu.tagName, PageTabsMenu);
	}
}

export default PageTabsMenu;
