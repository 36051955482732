import round from './libsV1_V2/round';
import roundUp from './libsV1_V2/roundUp';

import QuoteData from '../QuoteData';

import calculatePUGroup from './libsV1_V2/calculatePUGroup';
import calculateSumGroup from './libsV1_V2/calculateSumGroup';
import humanizeNumber from './libsV1_V2/humanizeNumber';
import verifSum from './libsV1_V2/verifSum';

class CalculV1 {
	private constructor() { }

	public static calculatePrice(item: { [key: string]: any }, pref: { [key: string]: any }) {
		pref.price = pref.price || {};
		pref.marges = pref.marges || {};

		pref.coefTotal = pref.coefTotal || 1;

		const result = {
			price: 0,
			sumMat: 0,
			sumMO: 0,
			sum: 0
		};

		if (item.quantity && !item.disabled) {
			if (item.time && pref.price[item.type]) {
				let tmp = parseFloat(item.time) * pref.price[item.type];

				if (!item.notApplyCoefOnMO) {
					tmp *= pref.coefTotal;
				}

				tmp = roundUp(tmp, pref);

				result.sumMO = roundUp(tmp * item.quantity, pref);

				result.price += tmp;
			}

			if (item.price) {
				let tmp = 0;

				const marge = (pref.marges[(item.typeMat || '').trim()] || 0) / 100 + 1;

				tmp = item.price * marge;

				if (item.typeMat === QuoteData.DISCOUNT) {
					tmp = roundUp(tmp, pref);

					result.price -= tmp;

					result.sumMat = roundUp(tmp * item.quantity * -1, pref);
				} else {
					if (!item.notApplyCoefOnMat) {
						tmp *= pref.coefTotal;
					}

					tmp = roundUp(tmp, pref);

					result.price += tmp;

					result.sumMat = roundUp(tmp * item.quantity, pref);
				}
			}
		}

		result.price = round(result.price, pref);

		result.sum = round(result.sumMO + result.sumMat, pref);

		return verifSum(result, pref as { decimalNumber: number });
	}

	public static calculateGlobalPrice(grid: any[], pref: { [key: string]: any } = {}) {
		const data = {
			all: 0,
			option: 0,
			notOption: 0
		};

		for (const group of grid) {
			let price = 0;

			if (!group.disabled) {
				for (const item of group.details) {
					price += this.calculatePrice(item, pref).sum;
				}
			}

			const tmp = (price * (group.quantity || 0));

			data.all += tmp;

			if (group.isOption) {
				data.option += tmp;
			} else {
				data.notOption += tmp;
			}
		}

		return {
			all: roundUp(data.all, pref),
			option: roundUp(data.option, pref),
			notOption: roundUp(data.notOption, pref)
		};
	}

	public static humanizeNumber(number: string, pref: { [key: string]: any }, ignoreRound = false) {
		return humanizeNumber(number, pref, ignoreRound);
	}

	public static calculatePUGroup(data: any) {
		return calculatePUGroup(data, this);
	}

	public static calculateSumGroup(data: any) {
		return calculateSumGroup(data, this);
	}
}

export default CalculV1;
