// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/additionalInformation.html';

// LIBS
import Modal from '@libs/Modal';

// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Companies from '@services/CompaniesService';
import S_Customer from '@services/Customer/CustomerService';
import S_P_Address from '@services/Provider/ProviderAddressService';
import S_Provider from '@services/Provider/ProviderService';
import S_User from '@services/User/UserService';

//TYPES
import { Company } from '@modules/Settings/js/types/company';
import { CompanyAddress } from '@modules/Settings/js/types/companyAddress';

type AdditionalInformationData = {
	infos: {
		provider: string,
		deliveryPrice: string,
		date: Moment,
		sendDate: Moment,
		deliveryDate: Moment,
		user: string,
		email: string,
		comments: string
	},
	address: {
		id: string
		text: string
	}
	addressDelivery: {
		type: string,
		customer: {
			id: string,
			address: {
				id: string
				text: string
			}
		},
		internal: {
			id: string,
			text: string
		}
	},
	company: Company
};

class AdditionalInformation extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 | CE_Address } = {};

	private form: Form | null;

	private company: Company | undefined;

	constructor(data: AdditionalInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.company = data.company;

			this.init();

			this.initAddressInternal();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);

			this.updateType();
			this.initEvents();
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.provider = await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider);
					data.infos.user = await S_User.getInstance().getDataToSelect2ByID(data.infos.user);

					data.address.id = await S_P_Address.getInstance().getDataToSelect2ByID(data.address.id);

					data.addressDelivery.internal.id = { text: data.addressDelivery.internal.id, id: data.addressDelivery.internal.id };

					data.addressDelivery.customer.id = await S_Customer.getInstance().getDataToSelect2ByID(data.addressDelivery.customer.id);
					data.addressDelivery.customer.address.id = await S_C_Address.getInstance().getDataToSelect2ByID(data.addressDelivery.customer.address.id);

					this.resolve(data);
				}));
			});

			this.updateSaveButton();
		});
	}

	private init() {
		const N_provider = this.element.querySelector('[name="infos.provider"]') as CE_Select2;
		const N_addressID = this.element.querySelector('[name-select="address.id"]') as CE_Address;
		const N_user = this.element.querySelector('[name="infos.user"]') as CE_Select2;

		const N_customer = this.element.querySelector('[name="addressDelivery.customer.id"]') as CE_Select2;
		const N_addressCustomerID = this.element.querySelector('[name-select="addressDelivery.customer.address.id"]') as CE_Address;

		N_provider.create(this.element, { disabled: true });

		N_addressID.setRef({ id_provider: N_provider.selectElement! });
		N_addressID.create(this.element);

		N_user.create(this.element);

		N_customer.create(this.element);

		N_addressCustomerID.setRef({ id_customer: N_customer.selectElement! });
		N_addressCustomerID.create(this.element);

		this.selectPostinit['infos.provider'] = N_provider;
		this.selectPostinit['infos.user'] = N_user;

		this.selectPostinit['address.id'] = N_addressID;
		this.selectPostinit['addressDelivery.customer.id'] = N_customer;
		this.selectPostinit['addressDelivery.customer.address.id'] = N_addressCustomerID;
	}

	private updateType() {
		const type = this.form?.getDataByName('addressDelivery.type');

		const N_address_customer_elements: NodeListOf<Element> = this.element.querySelectorAll('[address-type="customer"]');
		const N_address_internal_elements: NodeListOf<Element> = this.element.querySelectorAll('[address-type="internal"]');

		if (type === 'customer') {
			for (const N of N_address_internal_elements) {
				!N.classList.contains('d-none') && N.classList.add('d-none');
			}
			for (const N of N_address_customer_elements) {
				N.classList.contains('d-none') && N.classList.remove('d-none');
			}
		}

		if (type === 'internal') {
			for (const N of N_address_customer_elements) {
				!N.classList.contains('d-none') && N.classList.add('d-none');
			}
			for (const N of N_address_internal_elements) {
				N.classList.contains('d-none') && N.classList.remove('d-none');
			}
		}
	}

	private initAddressInternal() {
		const N_address_internal_id = this.element.querySelector('#address_internal_id') as HTMLSelectElement;

		const addressList: CompanyAddress[] = this.company?.addressList || [];

		for (const address of addressList) {
			const option = new Option(address.name, address.name);
			N_address_internal_id.appendChild(option);
		}
	}

	private initEvents() {
		const N_addressType = this.element.querySelector('[name="addressDelivery.type"]') as HTMLSelectElement;
		const N_addressInternalID = this.element.querySelector('#address_internal_id') as HTMLSelectElement;
		const N_date = this.element.querySelector('[name="infos.date"]') as HTMLInputElement;

		const addressList: CompanyAddress[] = this.company?.addressList || [];

		N_addressType.addEventListener('change', () => {
			this.updateType();
		});

		N_addressInternalID.addEventListener('change', () => {
			const address = _.find(addressList, { name: N_addressInternalID.value });
			this.form?.setDataByName('addressDelivery.internal.text', S_Companies.getInstance().getFullAddressByCompanyAdress(address));
		});

		N_date.addEventListener('input', () => {
			this.updateSaveButton();
		});

		this.selectPostinit['infos.user'].on('change', async (value) => {
			const data = await S_User.getInstance().getById(value as string);
			this.form?.setDataByName('infos.email', data.email || '');
		});

		this.selectPostinit['addressDelivery.customer.id'].on('change', async (customer) => {
			const address = await S_C_Address.getInstance().getByCustomerToSelect2(customer as string);

			if (address.length && customer) {
				this.form?.setDataByName('addressDelivery.customer.address.id', address[0]);
			} else {
				this.form?.setDataByName('addressDelivery.customer.address.id', { id: '', text: '' });
			}
		});
	}

	private async setData(data: AdditionalInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				provider: await S_Provider.getInstance().getDataToSelect2ByID(data.infos.provider),
				deliveryPrice: data.infos.deliveryPrice,
				date: data.infos.date,
				sendDate: data.infos.sendDate,
				deliveryDate: data.infos.deliveryDate,
				user: await S_User.getInstance().getDataToSelect2ByID(data.infos.user),
				email: data.infos.email,
				comments: data.infos.comments
			},
			address: {
				id: await S_P_Address.getInstance().getDataToSelect2ByID(data.address.id),
				text: data.address.text
			},
			addressDelivery: {
				type: data.addressDelivery.type,
				customer: {
					id: await S_Customer.getInstance().getDataToSelect2ByID(data.addressDelivery.customer.id),
					address: {
						id: await S_C_Address.getInstance().getDataToSelect2ByID(data.addressDelivery.customer.address.id),
						text: data.addressDelivery.customer.address.text
					}
				},
				internal: {
					id: data.addressDelivery.internal.id,
					text: data.addressDelivery.internal.text
				}
			}
		};

		this.form?.setData(res);
	}

	private updateSaveButton() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		const date = this.form?.getDataByName('infos.date') as Moment;

		N_save.disabled = !date;
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default AdditionalInformation;
