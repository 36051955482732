import { GridOptions } from '@ag-grid-enterprise/all-modules';

import '@css/customElements/inputBillDue.scss';
import moment from 'moment';

type ValuesBillDue = {
	monthBillDue: string,
	delayBillDue: boolean
};

class InputBillDue extends HTMLElement {
	public static readonly tagName: string = 'ap-input-bill-due';
	protected _gridOptions: GridOptions = {};

	private timeout: ReturnType<typeof setTimeout> | null = null;

	private _values: ValuesBillDue = { monthBillDue: '', delayBillDue: false };

	public async connectedCallback() {
		this.innerHTML = `
			<div id="content-input-months">
				<label for="input-months">Factures à échéance : </label>
				<input type="month" id="input-months">
			</div>
			<div class="content-checkbox">
				<label for="checkbox-delay">Afficher les retards</label>
				<i class="icon icon-question-circle "></i>
				<input id="checkbox-delay" type="checkbox">
			</div>

        `;
	}

	public setGridOptions(gridOptions: GridOptions) {
		this._gridOptions = gridOptions;
		const icon = this.querySelector('.icon');
		icon && (icon.setAttribute('popover', JSON.stringify({ content: "Affiche l'ensemble des factures en retard de paiement et non payées sur l'ensemble de l'exercice en cours.", trigger: 'hover', placement: 'top', sanitize: false })));
	}

	private initEvents() {
		const N_input = this.querySelector('#input-months') as HTMLInputElement;
		const contentCheckbox = this.querySelector('.content-checkbox') as HTMLDivElement;
		const N_checkbox = this.querySelector('#checkbox-delay') as HTMLInputElement;

		if (this._values.monthBillDue) {
			N_input.value = this._values.monthBillDue;
			contentCheckbox.style.display = 'flex';
			N_checkbox.checked = this._values.delayBillDue;
			this.filterRows(this._values.delayBillDue);
		}

		this._gridOptions.api?.addEventListener('filterChanged', () => {
			if (N_input.value) {
				this.filterRows(N_checkbox.checked);
			}
		});

		N_input.addEventListener('input', () => {
			if (N_input.value) {
				contentCheckbox.style.display = 'flex';

				this.filterRows(N_checkbox.checked);
			} else {
				contentCheckbox.style.display = 'none';
				N_checkbox.checked = false;
				this._gridOptions.api?.setRowData(this._gridOptions.rowData!);
			}
		});

		N_checkbox.addEventListener('click', () => {
			this.filterRows(N_checkbox.checked);
		});
	}

	private filterRows(displayDelay:boolean) {
		const N_input = this.querySelector('#input-months') as HTMLInputElement;

		if (displayDelay) {
			const month = moment(N_input.value, 'YYYY-MM');

			this.timeout && clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				this._gridOptions.api?.setRowData(
					this._gridOptions.rowData!.filter(row => {
						//si la date d'échéance est comprise dans le mois séléctionnée
						if (moment(row.infos.datePayment.value).format('YYYY-MM') === month.format('YYYY-MM')) {
							return true;
						}
						//Si la paiement est en retard.
						if (row.paid.isToLate.value && month.endOf('month').format('x') > row.infos.datePayment.value) {
							return true;
						}

						return false;
					})
				);
			}, 500);
		} else {
			const month = moment(N_input.value, 'YYYY-MM').format('YYYY-MM');

			this.timeout && clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				this._gridOptions.api?.setRowData(this._gridOptions.rowData!);
				this._gridOptions.api?.setRowData(
					this._gridOptions.rowData!.filter(row => {
					//si la date d'échéance est comprise dans le mois séléctionnée
						if (moment(row.infos.datePayment.value).format('YYYY-MM') === month) {
							return true;
						}

						return false;
					}));
			}, 500);
		}
	}

	public get values() {
		return this._values;
	}

	public set values(delayValue:ValuesBillDue) {
		this._values = delayValue;
		this.initEvents();
	}

	public static register() {
		customElements.define(InputBillDue.tagName, InputBillDue);
	}
}

export default InputBillDue;
