import { Module, Modules, Router } from '@autoprog/core-client';

import C_Bills from './js/controllers/Bills';
import T_tabs from '@tpl/tabs.html';

import Navigation from '@libs/Navigation';

import '@modules/BillsCustomer/module';
import '@modules/BillsProvider/module';

class BillModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Documents',
			order: 100
		});
		navigation.addLink({
			order: 2,
			category: 'Documents',
			name: 'Factures',
			type: 'module',
			module: 'bills',
			permissions: ['BILLS._CUSTOMERS', 'BILLS._PROVIDERS'],
			icon: 'bill-euro/line'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/bills',
			controller: C_Bills as any,
			tpl: T_tabs,
			requireValidUser: true,
			permission: 'BILLS._CUSTOMERS.SHOW || BILLS._PROVIDERS.SHOW'
		});

		router.addRoute({
			url: '/module/bills/:tabs',
			controller: C_Bills as any,
			tpl: T_tabs,
			requireValidUser: true,
			permission: 'BILLS._CUSTOMERS.SHOW || BILLS._PROVIDERS.SHOW'
		});
	}

	public async postInit() { }
}

Modules.registerModule('Factures', BillModule);
