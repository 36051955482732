// CORE
import { LoggedUser, Router, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';
import OpenDocuments from '@libs/customElement/OpenDocuments';

// TYPES
import { listDeadlinePaymentType } from '@type/_app/deadlinePayment';

// MODAL
import M_AdditionalInformation from '../modals/editPage/AdditionalInformation';
import M_Dates from '../modals/editPage/Dates';
import M_DetailsBill from '../modals/editPage/DetailsBill';
import M_GeneralInformation from '../modals/editPage/GeneralInformation';
import M_PrintPreview from '@libs/modals/PrintPreview';

// CUSTOM_ELEMENT
import CE_CGVTab from '@libs/customElement/CGVTab';
import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';

import CE_BillingRequests from '@modules/OrdersCustomer/js/customElements/BillingRequestTab';

import CE_BillPrintOption from '../customElements/BillPrintOption';
import CE_ComptaTab from '../customElements/ComptaTab';
import CE_ContentTab from '../customElements/ContentTab';
import CE_PaymentTab from '../customElements/Payment';

import C_OrdersCustomer from '@modules/OrdersCustomer/js/controllers/Orders.Customer';

import '../../css/pageControllerID.scss';
import DecimalApps from '@js/libs/utils/Decimal';

import { PrintAlertOptions } from '@js/libs/modals/PrintPreview';

class BillsCustomerCtrl extends ControllerPageID {
	private N_PaymentTab: CE_PaymentTab | null = null;
	private N_CGVTab: CE_CGVTab | null = null;
	private N_PrintOptionTab: CE_BillPrintOption | null = null;
	private N_BillingRequestTab: CE_BillingRequests | null = null;
	private N_ComptaTab: CE_ComptaTab | null = null;
	private N_ContentTab: CE_ContentTab | null = null;

	private idsBillingRequest: string[];

	private pageData: any = {};

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.reloadAfterSave = true;

		this.idsBillingRequest = [];

		this.options = BillsCustomerCtrl.options || {};

		BillsCustomerCtrl.options = {};

		this.routeReturn = 'module/bills/customers';

		this.init('bills', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		BillsCustomerCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'bills');
			Router.getInstance().navigate(`/module/billsPage/customer?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/billsPage/customer');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initTabs();

		this.pageData = await this.getData();

		this.setData(this.pageData);

		this.postInit();

		this.initFullscreen();
		this.initEditButton();

		this.updateEditButton();

		if (!this.id) {
			this.enableSaveButton();
		}
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsBill = this.el.querySelector('[data-edit="detailsBill"]') as HTMLButtonElement;
		const N_edit_Dates = this.el.querySelector('[data-edit="dates"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			const res = {
				infos: {
					customer: this.form?.getDataByName('infos.customer') as string,
					contact: this.form?.getDataByName('infos.contact') as string,
					label: this.form?.getDataByName('infos.label') as string
				}
			};

			new M_GeneralInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateTitle();
				this.enableSaveButton();
			});
		});

		N_edit_DetailsBill.addEventListener('click', () => {
			const res = {
				infos: {
					number: this.form?.getDataByName('infos.number') as string,
					index: this.form?.getDataByName('infos.index') as string,
					type: this.form?.getDataByName('infos.type') as string
				}
			};

			new M_DetailsBill(res).open().then((data) => {
				this.setDataForm(data);
				this.updateTitle();
				this.enableSaveButton();
			});
		});

		N_edit_Dates.addEventListener('click', () => {
			const res = {
				infos: {
					date: this.form?.getDataByName('infos.date') as Moment,
					datePayment: this.form?.getDataByName('infos.datePayment') as Moment,
					sendDate: this.form?.getDataByName('infos.sendDate') as Moment,
					number: this.form?.getDataByName('infos.number') as string
				},
				deadlinePayment: {
					day: this.form?.getDataByName('deadlinePayment.day') as number,
					type: this.form?.getDataByName('deadlinePayment.type') as listDeadlinePaymentType,
					fixedDate: this.form?.getDataByName('deadlinePayment.fixedDate') as number
				},
				relaunch: {
					date: this.form?.getDataByName('relaunch.date') as Moment
				}
			};

			new M_Dates(res).open().then((data) => {
				this.N_ContentTab!.updateDateAvancement(this.form?.getDataByName('infos.date') as Moment, data.infos.date);

				this.setDataForm(data);
				this.updateTitle();
				this.updateEditButton();
				this.enableSaveButton();
			});
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			const res = {
				infos: {
					customer: this.form?.getDataByName('infos.customer') as string,
					autoliquidation: this.form?.getDataByName('infos.autoliquidation') as boolean,
					description: this.form?.getDataByName('infos.description') as string,
					commentFinish: this.form?.getDataByName('infos.commentFinish') as string,
					comments: this.form?.getDataByName('infos.comments') as string,
					finalCustomer: this.form?.getDataByName('infos.finalCustomer') as string,
					number: this.form?.getDataByName('infos.number') as string,
					datePayment: this.form?.getDataByName('infos.datePayment') as Moment,
					sendDate: this.form?.getDataByName('infos.sendDate') as Moment,
					date: this.form?.getDataByName('infos.date') as Moment,
					email: this.form?.getDataByName('infos.email') as string
				},
				sites: {
					sites: this.form?.getDataByName('sites.sites') as string[],
					siteCustom: this.form?.getDataByName('sites.siteCustom') as string,
					hasFinalCustomer: this.form?.getDataByName('sites.hasFinalCustomer') as boolean
				},
				address: {
					id: this.form?.getDataByName('address.id') as string,
					text: this.form?.getDataByName('address.text') as string
				},
				addressDelivery: {
					id: this.form?.getDataByName('addressDelivery.id') as string,
					text: this.form?.getDataByName('addressDelivery.text') as string
				},
				deadlinePayment: {
					day: this.form?.getDataByName('deadlinePayment.day') as number,
					type: this.form?.getDataByName('deadlinePayment.type') as listDeadlinePaymentType,
					fixedDate: this.form?.getDataByName('deadlinePayment.fixedDate') as number
				},
				relaunch: {
					date: this.form?.getDataByName('relaunch.date') as Moment
				}
			};

			new M_AdditionalInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateTitle();
				this.enableSaveButton();

				const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
				N_sites.update(data.sites.hasFinalCustomer);
			});
		});
	}

	protected initButton() {
		super.initButton();

		const N_openOrderButton = this.el.querySelector('#open_order') as HTMLElement;

		N_openOrderButton.addEventListener('click', () => {
			const idOrder = this.form?.getDataOnAllDataByName('order.number');
			C_OrdersCustomer.open(idOrder);
		});
	}

	protected initPrint() {
		const N_print = this.el.querySelector('#print') as HTMLButtonElement;

		N_print && N_print.addEventListener('click', () => {
			let alert: PrintAlertOptions | undefined;

			if (this.pageData.data.quoteHasPrintHiddenProduct) {
				alert = {
					type: 'danger',
					htmlContent: '<b>Attention</b>, un devis associé à cette facture contenait un ou plusieurs <b>produits masqués à l\'impression</b>.'
				};
			}

			new M_PrintPreview(this.tableOpenDocument, this.id, 'pdf', alert).open();
		});
	}

	private initTabs() {
		this.initBillingRequest();
		this.initContent();
		this.initPayments();
		this.initCGV();
		this.initPrintOption();
		this.initCompta();
	}

	private async initBillingRequest() {
		this.N_BillingRequestTab = this.el.querySelector(CE_BillingRequests.tagName) as CE_BillingRequests;

		this.N_BillingRequestTab.setParentElement(this.el);
	}

	private initContent() {
		this.N_ContentTab = this.el.querySelector(CE_ContentTab.tagName) as CE_ContentTab;

		this.N_ContentTab.setParentElement(this.el);

		this.N_ContentTab!.setGetFormData(() => {
			return {
				infos: {
					type: this.form?.getDataByName('infos.type')
				},
				version: this.form?.getDataByName('version'),
				order: {
					advancePriceHT: this.form?.getDataOnAllDataByName('order.advancePriceHT')
				}
			};
		});

		this.N_ContentTab.addEventListener('update', () => {
			this.updatePrice();
			this.enableSaveButton();
		});
	}

	private initPrintOption() {
		this.N_PrintOptionTab = this.el.querySelector(CE_BillPrintOption.tagName) as CE_BillPrintOption;

		this.N_PrintOptionTab!.setParentElement(this.el);

		this.N_PrintOptionTab!.setID(() => {
			return this.id;
		});

		this.N_PrintOptionTab!.setGetData(() => {
			return this.saveData;
		});

		this.N_PrintOptionTab.setOnUpdate(() => {
			this.enableSaveButton();
		});
	}

	/**
	 * Initialise l'onglet Comptabilité de la facture
	 */
	private async initCompta() {
		//TODO: à réimplémenter avec la feature d'export des écritures comptables
		// this.N_ComptaTab = this.el.querySelector(CE_ComptaTab.tagName) as CE_ComptaTab;

		// this.N_ComptaTab.setCallback(() => {
		// 	return this.saveData;
		// });

		// this.N_ComptaTab.setParentElement(this.el);
	}

	private async initPayments() {
		this.N_PaymentTab = this.el.querySelector(CE_PaymentTab.tagName) as CE_PaymentTab;

		this.N_PaymentTab.setParentElement(this.el);

		this.N_PaymentTab.setUpdateInfos(async () => {
			this.updatePrice();
		});

		this.N_PaymentTab.setCallback(async () => {
			this.updatePrice();
			this.updateEditButton();
			await this.save();
		});

		this.N_PaymentTab.setGetID(() => {
			return this.id;
		});

		this.N_PaymentTab.setGetglobalPriceTTC(() => {
			return this.form?.getDataOnAllDataByName('priceTTC');
		});
	}

	private initCGV() {
		this.N_CGVTab = this.el.querySelector(CE_CGVTab.tagName) as CE_CGVTab;

		this.N_CGVTab.setParentElement(this.el);

		this.N_CGVTab.setOnUpdate(() => {
			this.enableSaveButton();
		});
	}

	private get isLock() {
		const finish = this.form?.getDataByName('infos.sendDate');

		if (LoggedUser.getInstance().hasPermission('BILLS._CUSTOMERS.EDIT_SENDED')) {
			return false;
		}

		return finish || !LoggedUser.getInstance().hasPermission('BILLS._CUSTOMERS.EDIT');
	}

	protected convertData(data: { [key: string]: any }) {
		const types: { [key: string]: string } = {
			'credit-error': 'Avoir (Erreur)',
			credit: 'Avoir (Commercial)',
			normal: 'Avancement',
			advance: 'Acompte',
			last: 'Définitive'
		};

		if (data.infos.type) {
			data.infos.type = {
				id: data.infos.type,
				text: types[data.infos.type]
			};
		}

		return data;
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.N_ContentTab!.data = data.data.content || [];
		this.N_ContentTab!.orderID = data.data.order.number.id;
		this.N_ContentTab!.billID = data.data._id;
		this.N_ContentTab!.billType = data.data.infos.type;
		this.N_ContentTab!.billOriginID = data.data.billOrigin.id;
		this.N_ContentTab!.rectifiedContent = async (options: any) => {
			BillsCustomerCtrl.options = options;
			this.options = options;

			// Lorsque le contenu est rectifié, on met à jour le contenu de la page
			const data = await this.getData();
			this.pageData.data.content = data.data.content;

			this.setData(this.pageData);
			this.enableSaveButton();
		};

		this.N_CGVTab!.data = data.data.CGV || [];

		this.N_PrintOptionTab!.data = data.data.options || {};

		if (data.data.quoteHasPrintHiddenProduct) {
			this.N_PrintOptionTab?.displayDetailsGroupWarning();
		}

		this.N_PaymentTab?.iniData(data.data.payments);

		const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
		N_sites.update(data.data.sites.hasFinalCustomer);

		const N_edit_DetailsBill = this.el.querySelector<HTMLButtonElement>('[data-edit="detailsBill"]')!;
		const N_billOrigin = this.el.querySelector<HTMLElement>('#billOrigin')!;

		if (data.data.infos.type === 'credit' || data.data.infos.type === 'credit-error') {
			N_edit_DetailsBill.classList.remove('invisible');
			N_billOrigin.classList.remove('d-none');
		}

		this.N_BillingRequestTab!.setMode('bill').initData();

		this.updatePrice();

		this.idsBillingRequest = this.options.idsBillingRequest;

		this.options = {};
	}

	protected postInit(): void {
		super.postInit();

		this.N_PrintOptionTab!.postInit();

		if (this.saveData.version === '1' && this.saveData.infos.type !== 'advance') {
			this.N_PrintOptionTab!.disableDisplayDetailGroupOption();
		} else if (this.saveData.infos.type === 'advance') {
			this.N_PrintOptionTab!.removeDisplayDetailGroupOption();
		}
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		newData.infos = newData.infos || {};

		if (newData.infos.date) {
			newData.infos.date = newData.infos.date.valueOf();
		}

		if (newData.infos.datePayment) {
			newData.infos.datePayment = newData.infos.datePayment.valueOf();
		}

		if (newData.infos.sendDate) {
			newData.infos.sendDate = newData.infos.sendDate.valueOf();
		}

		if (newData.relaunch && newData.relaunch.date) {
			newData.relaunch.date = newData.relaunch.date.valueOf();
		}

		newData.content = this.N_ContentTab!.data;
		newData.payments = this.N_PaymentTab!.data;
		newData.CGV = this.N_CGVTab!.data;
		newData.options = this.N_PrintOptionTab?.data;

		if (newData.order) {
			newData.order = newData.order.number;
		}

		return newData;
	}

	protected get otherDataSave() {
		return {
			idsBillingRequest: this.idsBillingRequest
		};
	}

	private updateEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsBill = this.el.querySelector('[data-edit="detailsBill"]') as HTMLButtonElement;
		const N_edit_Dates = this.el.querySelector('[data-edit="dates"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_Dates.disabled = this.isLock;
		N_edit_DetailsBill.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;

		this.N_ContentTab!.isLock = this.isLock;
		this.N_CGVTab!.isLock = this.isLock;
	}

	private async updatePrice() {
		let paidPrice = this.N_PaymentTab!.getPaidPrice();

		let { priceTTC, price, priceTVA } = await this.N_ContentTab!.getInfos();

		const version = this.form!.getDataByName('version');
		const type = this.form!.getDataByName('type');

		if (version === '1' && type === 'last') {
			const advancePriceHT = DecimalApps.setDisplayNumber(this.form!.getDataOnAllDataByName('order.advancePriceHT'));
			const advancePriceTTC = DecimalApps.setDisplayNumber(this.form!.getDataOnAllDataByName('order.advancePriceTTC'));
			const advancePriceTVA = advancePriceTTC.minus(advancePriceHT);

			priceTTC = priceTTC.minus(advancePriceTTC);
			price = price.minus(advancePriceHT);
			priceTVA = priceTVA.minus(advancePriceTVA);
		}

		const notPaid = priceTTC.minus(paidPrice).toDecimalPlaces(2);

		const percent_paidPrice = paidPrice.times(100).dividedBy(priceTTC).toDecimalPlaces(2);
		const percent_notPaidPrice = notPaid.times(100).dividedBy(priceTTC).toDecimalPlaces(2);

		paidPrice = paidPrice.toDecimalPlaces(2);

		this.form?.setData({
			notPaymentPrice: `${notPaid.setSuffixAndHumanizeNumber('€')} | ${percent_notPaidPrice.humanizePercent()}%`,
			paymentPrice: `${paidPrice.setSuffixAndHumanizeNumber('€')} | ${percent_paidPrice.humanizePercent()}%`,
			price: price.toNumber(),
			priceTTC: priceTTC.toNumber(),
			priceTVA: priceTVA.toNumber()
		});

		this.N_PaymentTab!.update();
	}
}

export default BillsCustomerCtrl;
