// CORE
import { Controller } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
// LIBS
// PRINTER
// UTILS

// MODAL
import M_Edit from '../modals/Edit';
import M_Input from '../modals/Input';
import M_Output from '../modals/Output';

// CUSTOM_ELEMENT
// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class StockMobile extends Controller {
	private allData: { [key: string]: any[] } = {};

	private data: { [key: string]: any }[] = [];

	private el: HTMLElement;

	private startIndex = 0;
	private endIndex = 100;

	private currentStock = '';

	private N_btnSearch: HTMLButtonElement;
	private N_inputSearch: HTMLInputElement;
	private N_listProducts: HTMLElement;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.getLocation().then(() => {
			this.getData().then(() => {
				this.displayData();
			});
		});

		this.N_btnSearch = this.el.querySelector('#search-btn') as HTMLButtonElement;
		this.N_inputSearch = this.el.querySelector('#search-input') as HTMLInputElement;
		this.N_listProducts = this.el.querySelector('#list-products') as HTMLElement;

		this.N_inputSearch.addEventListener('keydown', (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				this.filterData();
			}
		});

		this.N_btnSearch.addEventListener('click', () => {
			this.filterData();
		});
	}

	private async getLocation() {
		const N_location = this.el.querySelector('#location') as HTMLSelectElement;

		const data = await S_Stock.getInstance().getRealStock();

		for (const item of data) {
			const option = new Option(item.name, item._id, item.default, item.default);
			if (item.default) {
				this.currentStock = item._id;
			}
			N_location.appendChild(option);
		}

		N_location.addEventListener('change', () => {
			this.currentStock = N_location.value;
			this.displayData();
		});

		const N_listProducts = this.el.querySelector('#list-products') as HTMLElement;

		N_listProducts.innerHTML = `
			<div class="align-items-center d-flex h-100 justify-content-center">
				<i class="icon icon-solid-spinner icon-spin text-muted" style="font-size:75px"></i>
			</div>
		`;

		N_listProducts.addEventListener('scroll', () => {
			if (N_listProducts.scrollTop + N_listProducts.clientHeight >= N_listProducts.scrollHeight && this.allData[this.currentStock].length > this.endIndex) {
				this.loadChunk();
			}
		});

		N_listProducts.classList.add('scroll-y');
	}

	private async getData() {
		const data = await S_StockEvent.getInstance().getCurrentStock();

		this.allData = {};

		for (const item of data.rowData) {
			this.allData[item.stock.value] = this.allData[item.stock.value] || [];
			this.allData[item.stock.value].push(item);
		}

		this.data = this.allData[this.currentStock] || [];
	}

	protected displayData() {
		this.startIndex = 0;
		this.endIndex = 100;

		const N_listProducts = this.el.querySelector('#list-products') as HTMLElement;
		N_listProducts.innerHTML = '';

		this.data = this.allData[this.currentStock] || [];

		this.loadChunk();
	}

	private updatecard() {
		for (const item of this.data) {
			const N_div = this.el.querySelector<HTMLElement>(`[data-product="${item.product._id.value}"]`);

			if (N_div) {
				this.renderCard(N_div, item);
			}
		}
	}

	private loadChunk() {
		const N_listProducts = this.el.querySelector('#list-products') as HTMLElement;

		const endIndex = this.endIndex > this.data.length ? this.data.length : this.endIndex;

		const data = this.data.slice(this.startIndex, endIndex);

		for (const item of data) {
			let N_div = this.el.querySelector<HTMLElement>(`[data-product="${item.product._id.value}"]`);

			if (!N_div) {
				N_div = document.createElement('div') as HTMLElement;
				N_listProducts.appendChild(N_div);
			}

			this.renderCard(N_div, item);
		}

		this.startIndex = this.endIndex;
		this.endIndex += 100;
	}

	private renderCard(N_div: HTMLElement, item: { [key: string]: any }) {
		N_div.dataset.product = item.product._id.value;

		N_div.classList.add('card-mobile', 'flex-column', 'p-2');
		N_div.classList.remove('loading');

		N_div.innerHTML = `
			<div class="cell-loading p-2">
				<div class="w-100 ">
					<div class="font-weight-bold text-blue-grey-900">
						${item.product.label.formattedValue || '&nbsp;'}
					</div>
					<div class="text-muted text-xs mt-1 d-flex">
						Ref : ${item.product.reference.formattedValue || '&nbsp;'}

						<div class="ml-auto">
							Emplacement : ${item.product.locationStock.formattedValue || 'N/A'}
						</div>
					</div>
					
				</div>
			
				<div class="d-flex mt-2 align-item-center">
					<div class="flex-grow-1">
						Quantité : <span id="quantity">${item.quantity.formattedValue}</span>
					</div>
					<button class="btn btn-edit p-2 btn-success btn-xs" id="add">
						<i class="icon icon-solid-plus"></i>
					</button>
					<button class="btn btn-edit p-2 mx-2 btn-danger btn-xs" id="remove">
						<i class="icon icon-solid-minus"></i>
					</button>
					<button class="btn btn-edit p-2 btn-info btn-xs" id="edit">
						<i class="icon icon-edit"></i>
					</button>
				</div>
			</div>
		`;

		const N_edit = N_div.querySelector('#edit') as HTMLElement;
		const N_add = N_div.querySelector('#add') as HTMLElement;
		const N_remove = N_div.querySelector('#remove') as HTMLElement;

		const productID = item.product._id.value;
		const stockID = item.stock.value;
		const quantity = item.quantity.value;

		N_edit.addEventListener('click', async () => {
			await new M_Edit(productID, quantity, stockID).open();
			N_div.classList.add('loading');
			await this.getData();
			this.updatecard();
		});

		N_add.addEventListener('click', async () => {
			await new M_Input(productID, quantity, stockID).open();
			N_div.classList.add('loading');
			await this.getData();
			this.updatecard();
		});

		N_remove.addEventListener('click', async () => {
			await new M_Output(productID, quantity, stockID).open();
			N_div.classList.add('loading');
			await this.getData();
			this.updatecard();
		});
	}

	/**
	 * Recharge les données avec le filtre appliqué
	 */
	private filterData() {
		const filter = (this.N_inputSearch.value || '').toLowerCase();

		this.data = _.filter(this.allData[this.currentStock], (item) => {
			const search = `${item.product[S_Product.getInstance().referenceKey]} ${item.product.label.value}`.toLowerCase();

			if (search && search.includes(filter)) {
				return true;
			} else {
				return false;
			}
		});

		this.N_listProducts.innerHTML = '';

		this.startIndex = 0;
		this.endIndex = 100;

		if (!this.data.length) {
			this.N_listProducts.innerHTML = `
				<div class="text-muted d-flex justify-content-center h3">
					Aucun élément trouvé
				<div>
			`;
		} else {
			this.loadChunk();
		}
	}

	public destructor() {

	}
}

export default StockMobile;
