import GenericService from '../GenericService';

import Utils from '@libs/utils/Utils';

import moment from 'moment';
class BillService extends GenericService {
	private static instance: BillService | null = null;

	constructor() {
		super('bills');
	}

	public static getInstance(): BillService {
		if (!BillService.instance) {
			BillService.instance = new BillService();
		}

		return BillService.instance;
	}

	public async getByOrder(id: string) {
		return await this.get_function('getByOrder', { id });
	}

	public getNewNumber(date = moment()) {
		return this.get_function('getNewNumber', { date: date.valueOf().toString() });
	}

	public getLastNumberByCompany(date = moment()) {
		return this.get_function('getLastNumberByCompany', { date: date.valueOf().toString(), companyID: Utils.companyID });
	}

	public getCurrentByCustomer(customer: string) {
		return this.get_function('getCurrentByCustomer', { customer, companyID: Utils.companyID });
	}

	public getNotPaidById(id: string) {
		return this.get_function('getNotPaidById', { id });
	}

	public getComptaData(data: { [key: string]: any }) {
		return this.post_function('getComptaData', { data });
	}

	public getComptaByIds(ids: string[]) {
		return this.post_function('getComptaByIds', { ids });
	}

	public getBillStateByOrder(id_order: string, id_billToIgnore?: string) {
		return this.get_function('getBillStateByOrder', { id_order, id_billToIgnore });
	}

	public getBillStateByID(id_bill: string) {
		return this.get_function('getBillStateByID', { id_bill });
	}
}

export default BillService;
