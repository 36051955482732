// CORE
import { Module, Modules, Router, global } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_Database from '@tpl/database.html';
import T_Inventory from './tpl/Inventory.html';
import T_Stock from './tpl/Stock.html';
import T_StockMobile from './tpl/Stock.mobile.html';
import T_StockTactile from './tpl/Stock.tactile.html';

import './css/stock.scss';

// LIBS
import C_ControllerDatabase from '@js/controllers/ControllerDatabase';

import C_Database from '@js/controllers/Database';
import C_History from './js/controllers/History';
import C_Inventory from './js/controllers/Inventory';
import C_Stock from './js/controllers/Stock';
import C_StockMobile from './js/controllers/Stock.mobile';
import C_StockTactile from './js/controllers/Stock.tactile';

import Navigation from '@libs/Navigation';
import NavigationMobile from '@libs/NavigationMobile';
import Notifications from '@modules/Apps/js/libs/Notifications';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import InputTracaForm from './js/libs/customElement/InputTracaForm';
import OutputTracaForm from './js/libs/customElement/OutputTracaForm';

// SERVICE

class StockModule extends Module {
	private page_stock = {
		order: 2,
		category: 'Stock',
		name: 'Emplacement Stock',
		type: 'database',
		database: 'stock',
		icon: 'map-pin-2/line'
	};

	private page_stock_event = {
		order: 1,
		category: 'Stock',
		name: 'Historique Stock',
		type: 'database',
		database: 'stock_events',
		icon: 'history'
	};

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Stock',
			order: 10
		});
		navigation.addLink({
			order: 0,
			category: 'Stock',
			name: 'Stock',
			type: 'module',
			module: 'stock',
			permissions: ['STOCK'],
			icon: 'database-2/line'
		});

		navigation.addLink(this.page_stock_event);
		navigation.addLink(this.page_stock);

		NavigationMobile.getInstance().addLink({
			icon: 'icon-solid-database',
			name: 'Stock',
			order: 6,
			dataset: {
				link: 'stocks'
			}
		});

		Notifications.addCategory(['stock'], {
			textFilter: 'Stock',
			isFilter: true,
			icon: 'icon-solid-database'
		});
	}

	public async init() {
		InputTracaForm.register();
		OutputTracaForm.register();

		const router = Router.getInstance();

		if (global.IS_MOBILE) {
			router.addRoute({
				url: '/module/stock',
				controller: C_StockMobile as any,
				tpl: T_StockMobile,
				requireValidUser: true,
				permission: 'STOCK.SHOW'
			});
		} else {
			router.addRoute({
				url: '/module/stock',
				controller: C_Stock as any,
				tpl: T_Stock,
				requireValidUser: true,
				permission: 'STOCK.SHOW'
			});
		}

		router.addRoute({
			url: '/module/stock/tactile',
			controller: C_StockTactile as any,
			tpl: T_StockTactile,
			requireValidUser: true,
			permission: 'STOCK.SHOW'
		});

		router.addRoute({
			url: '/module/stock/inventory',
			controller: C_Inventory as any,
			tpl: T_Inventory,
			requireValidUser: true,
			permission: 'STOCK.INVENTORIES'
		});

		//Page des emplacements de stock
		router.addRoute({
			url: '/database/stock',
			tpl: T_Database,
			controller: C_ControllerDatabase,
			data: {
				...this.page_stock,
				instance: C_Database
			},
			requireValidUser: true,
			default: false,
			permission: 'STOCK._STOCKS.SHOW'
		});

		//Page d'historique des événements de stock
		router.addRoute({
			url: '/database/stock_events',
			tpl: T_Database,
			controller: C_ControllerDatabase,
			data: {
				...this.page_stock_event,
				instance: C_History
			},
			requireValidUser: true,
			default: false,
			permission: 'STOCK._STOCK_EVENTS.SHOW'
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Stock', StockModule);
