import DashboardController, { DashboardConfigColumns, DashboardOptions } from '@js/controllers/DashboardController';

//NODE_MODULES
import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import moment from 'moment';
import tippy from 'tippy.js';
import { toaster } from '@autoprog/core-client';

//LIBS
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

//CONTROLLER
import C_BillsCustomer from '../controllers/Bills.Customer';

//MODALS
import M_AddPayment from '../modals/AddLinePayment';
import M_SelectCustomerToPrint from '../modals/SelectCustomerToPrint';

import M_PrintPreview from '@libs/modals/PrintPreview';
import M_SetRelaunchDate from '@libs/modals/SetRelunchDate';
import M_SetSendDate from '@libs/modals/SetSendDate';

import M_ExportCompta from '../modals/ExportCompta';

import M_EditBillingRequest from '@modules/OrdersCustomer/js/modals/EditBillingRequest';

//CUSTOM-ELEMENT
import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

// EXPORTS CSV
import Export from '@libs/export/Export';
import ExportUtils from '@libs/utils/ExportUtils';

// SERVICE
import S_C_Bill from '@services/Customer/CustomerBillService';

class Customer extends DashboardController {
	private lastNumber: string | null = null;

	constructor(el: HTMLElement) {
		super(el, 'Facture - clients', 'BILLS._CUSTOMERS', 'bills');
	}

	/**
	 * Récupère le dernier numéro de facture
	 */
	private async retrieveLastNumber() {
		this.lastNumber = await S_C_Bill.getInstance().getLastNumberByCompany();
	}

	protected async getData(initStateSaver?: boolean) {
		// On récupère le dernier numéro de facture avant de recharger les données du dashboard
		this.retrieveLastNumber();

		return super.getData(initStateSaver);
	}

	protected initHeader() {
		super.initHeader();

		const N_export_compta = this.N_header.querySelector('#export_compta') as HTMLButtonElement;
		const N_recap = this.N_header.querySelector('#recap') as HTMLElement;
		const N_byCustomer = this.N_header.querySelector('#byCustomer') as HTMLElement;
		const N_exportElement = this.N_header.querySelector('#export_excel') as HTMLButtonElement;

		N_export_compta.addEventListener('click', () => {
			new M_ExportCompta().open();
		});

		N_recap.addEventListener('click', async () => {
			(await new M_PrintPreview('bills', '', 'pdfList').getToken(this.getDataToPrint())).open();
		});

		N_byCustomer.addEventListener('click', () => {
			new M_SelectCustomerToPrint().open();
		});

		N_exportElement.addEventListener('click', () => {
			const csvString = new Export()
				.included(['infos.customer', 'infos.label', 'infos.number', 'order.number', 'infos.date', 'infos.sendDate', 'infos.datePayment', 'relaunch.date', 'relaunch.number', 'infos.type', 'price', 'priceTVA', 'priceTTC', 'paymentPrice', 'notPaymentPrice', 'paid.state', 'send.isSend'])
				.fromTableName('bills')
				.fromGridOptionsNewDashboard(this.N_grid!.gridOptions, this.configColumns)
				.createCSV();

			const a = ExportUtils.createFileLink(csvString);
			a.download = `Export-Factures-Client-${moment().format('DD-MM-YYYY')}`;
			a.click();
			a.remove();
		});
	}

	protected buttonView(id: string) {
		C_BillsCustomer.open(id);
	}

	protected buttonPrint(id: string) {
		new M_PrintPreview('bills', id).open();
	}

	protected getDisabledActionButtons(data: { [key: string]: any }) {
		const lastNumberDigits = this.lastNumber?.slice(-5) || null;
		const rowLastNumberDigits = data._rowData_.infos.number.slice(-5);

		// On veut empêcher la suppression si :
		// - la facture a déjà un paiement
		// - la facture a été envoyé
		// - ce n'est pas la dernière facture (pas le dernier numéro)

		return {
			delete: data._rowData_.payments.length !== 0 || data._rowData_.infos.sendDate || lastNumberDigits !== rowLastNumberDigits
		};
	}

	private getDataToPrint() {
		const data: { [key: string]: any }[] = [];

		this.N_grid?.api?.forEachNodeAfterFilterAndSort((node: any) => {
			const tmp = _.cloneDeep(node.data);
			data.push(tmp._id.value);
		});

		return data;
	}

	protected cellRendererActionIcons(params: any) {
		const N_div = document.createElement('div');
		N_div.classList.add('container-icons-aggrid');

		const N_sendState = document.createElement('div');
		N_sendState.classList.add('icon-state');
		N_sendState.innerHTML = '<ap-icon name="mail-send/line"></ap-icon>';

		const N_toRelaunch = document.createElement('div');
		N_toRelaunch.classList.add('icon-state');
		N_toRelaunch.innerHTML = '<ap-icon name="history/line"></ap-icon>';

		const N_paymentState = document.createElement('div');
		N_paymentState.classList.add('icon-state');
		N_paymentState.innerHTML = '<ap-icon name="bank/line"></i>"></ap-icon>';

		let paymentStateTooltip = '';

		//Payée en totalité
		if (params.data.paid.state.value === '2') {
			paymentStateTooltip = 'Paiement total';
			N_paymentState.style.setProperty('--color', 'var(--ap-green-500)');
			N_paymentState.style.setProperty('--bg-color', 'var(--ap-green-100)');
		}

		//Payée partiellement
		if (params.data.paid.state.value === '1') {
			paymentStateTooltip = 'Paiement partiel';
			N_paymentState.style.setProperty('--color', 'var(--ap-orange-500)');
			N_paymentState.style.setProperty('--bg-color', 'var(--ap-orange-100)');
		}

		//Aucun paiement
		if (params.data.paid.state.value === '0') {
			paymentStateTooltip = 'Aucun paiement';
			N_paymentState.style.setProperty('--color', 'var(--ap-red-500)');
			N_paymentState.style.setProperty('--bg-color', 'var(--ap-red-100)');
		}

		let isSendTooltip = '';
		let isToLateTooltip = '';

		//envoyé
		if (params.data.send.isSend.value === '1') {
			isSendTooltip = 'Envoyé';
			N_sendState.style.setProperty('--color', 'var(--ap-purple-500)');
			N_sendState.style.setProperty('--bg-color', 'var(--ap-purple-50)');
		} else {
			N_sendState.classList.add('disabled');
		}

		if (params.data.relaunch.active.value) {
			isToLateTooltip = 'À relancer';
			N_toRelaunch.style.setProperty('--color', 'var(--ap-indigo-500)');
			N_toRelaunch.style.setProperty('--bg-color', 'var(--ap-indigo-50)');
		} else {
			N_toRelaunch.classList.add('disabled');
		}

		if (isSendTooltip) {
			tippy(N_sendState, {
				content: isSendTooltip,
				arrow: false,
				placement: 'right'
			});
		}

		if (isToLateTooltip) {
			tippy(N_toRelaunch, {
				content: isToLateTooltip,
				arrow: false,
				placement: 'right'
			});
		}

		if (paymentStateTooltip) {
			tippy(N_paymentState, {
				content: paymentStateTooltip,
				arrow: false,
				placement: 'right'
			});
		}

		N_div.append(N_paymentState, N_sendState, N_toRelaunch);

		return N_div;
	}

	protected getWidthColActionIcon(): number {
		return 160;
	}

	protected _getRowStyle(params: any): { [key: string]: string } {
		if (params.data) {
			//paiement en retard
			if (params.data.paid.isToLate.value) {
				return {
					'background-color': 'var(--ap-red-50)',
					color: 'var(--ap-red-900)'
				};
			}
		}

		return {
			'background-color': 'white'
		};
	}

	protected _getContextMenu(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
		if (params.node) {
			const _id = params.node.data._id.value;
			const idOrder = params.node.data.order.id.value;

			return [
				...super._getContextMenu(params),
				'separator',
				{
					name: 'Définir date d\'envoi',
					icon: '<ap-icon name="mail-add/line"></ap-icon>',
					disabled: params.node.data.send.isSend.value === '1',
					action: async () => {
						new M_SetSendDate('bills', _id).open().then(() => {
							this.getData();
						});
					}
				},
				{
					name: 'Définir prochaine relance',
					icon: '<ap-icon name="restart/line"></ap-icon>',
					disabled: !params.node.data.relaunch.active.value,
					action: () => {
						new M_SetRelaunchDate('bills', _id).open().then(() => {
							this.getData();
						});
					}
				}, 'separator', {
					name: 'Ajout paiement',
					icon: '<ap-icon name="money-euro-circle/line"></ap-icon>',
					disabled: params.node.data.paid.state.value === '2',
					action: () => {
						new M_AddPayment(_id).open().then(async (payment) => {
							const data = await S_C_Bill.getInstance().getById(_id);

							data.payments = data.payments || [];

							data.payments.push(payment);

							await S_C_Bill.getInstance().save(data);

							toaster.success('Sauvegarde réussi');

							this.getData();
						});
					}
				}, {
					name: 'Créer un avoir',
					icon: '<ap-icon name="bill/line"></ap-icon>',
					disabled: params.node.data.isCredit.value,
					action: () => {
						new M_EditBillingRequest(idOrder, _id).setMode('create-credit').open();
					}
				},
				{
					name: 'export',
					action: () => {
						params.api?.exportDataAsCsv();
					}
				}
			];
		} else {
			return [];
		}
	}

	protected _onDataUpdate(params: any) {
		let priceTTC = new Decimal(0);
		let priceHT = new Decimal(0);
		let paymentPrice = new Decimal(0);
		let toLatePayment = new Decimal(0);

		const lengthSelectedRows = params.api.getSelectedRows().length;

		params.api.forEachNodeAfterFilter((node: any) => {
			if ((lengthSelectedRows && node.isSelected()) || (!lengthSelectedRows)) {
				priceTTC = priceTTC.plus(node.data.priceTTC.value);
				priceHT = priceHT.plus(node.data.price.value);

				paymentPrice = paymentPrice.plus(node.data.paymentPrice.value);

				if (node.data.paid.isToLate.value) {
					toLatePayment = toLatePayment.plus(Decimal.setDisplayNumber(node.data.priceTTC.value).minus(node.data.paymentPrice.value));
				}
			}
		});

		const notPaid = priceTTC.minus(paymentPrice).toDecimalPlaces(2);
		const tva = priceTTC.minus(priceHT).toDecimalPlaces(2);

		priceHT = priceHT.toDecimalPlaces(2);
		priceTTC = priceTTC.toDecimalPlaces(2);
		paymentPrice = paymentPrice.toDecimalPlaces(2);
		toLatePayment = toLatePayment.toDecimalPlaces(2);

		const values = [{
			label: 'Montant HT',
			value: priceHT.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TVA',
			value: tva.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TTC',
			value: priceTTC.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TTC payé',
			value: paymentPrice.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TTC à payer',
			value: notPaid.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Dont en retard (TTC)',
			value: toLatePayment.setSuffixAndHumanizeNumber('€')
		}];

		if (lengthSelectedRows) {
			values.unshift({
				label: `Factures sélectionnées (${lengthSelectedRows})`,
				value: ''
			});
		}

		this.setDataTitle(values);
	}

	protected getTitleContextMenu(data: { [key: string]: any }): string {
		return `${data.infos.number.formattedValue} / ${data.infos.customer.formattedValue}`;
	}

	protected get configColumns(): DashboardConfigColumns {
		return [
			{
				key: '_id',
				type: 'primaryKey',
				name: 'ID'
			},
			{
				key: 'infos.number',
				type: 'string',
				name: 'Numéro'
			},
			{
				key: 'infos.finalCustomer',
				type: 'string',
				name: 'Client Final'
			},
			{
				key: 'infos.type',
				type: 'object',
				name: 'Type',
				object: {
					normal: 'Avancement',
					credit: 'Avoir (Commercial)',
					'credit-error': 'Avoir (Erreur)',
					advance: 'Acompte',
					last: 'Définitive'
				}
			},
			{
				key: 'infos.date',
				type: 'date',
				name: 'Date'
			},
			{
				key: 'infos.sendDate',
				type: 'date',
				name: "Date d'envoi"
			},
			{
				key: 'infos.label',
				type: 'string',
				name: 'Objet'
			},
			{
				key: 'infos.customer',
				type: 'string',
				name: 'Client'
			},
			{
				key: 'infos.contact',
				type: 'string',
				name: 'Contact'
			},
			{
				key: 'sites',
				type: 'string',
				name: 'Site(s)'
			},
			{
				key: 'infos.datePayment',
				type: 'date',
				name: 'Date échéance'
			},
			{
				key: 'order.internalNumber',
				type: 'string',
				name: 'N° Affaire'
			},
			{
				key: 'order.number',
				type: 'string',
				name: 'N° Commande'
			},
			{
				key: 'relaunch.date',
				type: 'date',
				name: 'Date de relance'
			},
			{
				key: 'relaunch.number',
				type: 'number',
				name: 'Nb. de relance'
			},
			{
				key: 'relaunch.active',
				type: 'boolean',
				name: 'À relancer',
				hide: true
			},
			{
				key: 'day.advance',
				type: 'number',
				name: "Jour d'avance"
			},
			{
				key: 'day.delay',
				type: 'number',
				name: 'Jour de retard'
			},
			{
				key: 'paid.isToLate',
				type: 'boolean',
				name: 'Retard',
				hide: true
			},
			{
				key: 'paid.state',
				type: 'object',
				filter: 'multiple',
				object: {
					0: 'Pas commencé',
					1: 'En cours de paiement',
					2: 'Payée à 100%'
				},
				name: 'Paiement',
				hide: true
			},
			{
				key: 'paid.percent',
				type: 'string',
				name: 'Payée'
			},
			{
				key: 'lastPayment',
				type: 'date',
				name: 'Date dernier paiement'
			},
			{
				key: 'price',
				type: 'number',
				name: 'Montant HT (€)'
			},
			{
				key: 'priceTVA',
				type: 'number',
				name: 'Montant TVA (€)'
			},
			{
				key: 'priceTTC',
				type: 'number',
				name: 'Montant TTC (€)'
			},
			{
				key: 'paymentPrice',
				type: 'number',
				name: 'Montant payé TTC (€)'
			},
			{
				key: 'notPaymentPrice',
				type: 'number',
				name: 'Montant à payer TTC (€)'
			},
			{
				key: 'send.isSend',
				type: 'object',
				filter: 'multiple',
				object: {
					0: 'Non envoyé',
					1: 'Envoyée'
				},
				name: 'État envoi',
				hide: true
			}
		];
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [{
						value: Utils.userID,
						label: 'Mes factures',
						hideIcon: true
					}]
				}, {
					column: 'relaunch.active',
					type: 'switch',
					filters: [{
						value: 'true',
						label: 'À relancer',
						hideIcon: true
					}]
				}, {
					column: 'paid.isToLate',
					type: 'switch',
					filters: [{
						value: 'true',
						label: 'En retard',
						hideIcon: true,
						backgroundColor: 'var(--ap-red-50)'
					}]
				}, {
					type: 'separator'
				}, {
					column: 'send.isSend',
					type: 'checkbox',
					checkboxListTitle: 'Envoi',
					filters: [{
						value: '0',
						label: 'Non envoyée'
					}, {
						value: '1',
						label: 'Envoyée'
					}]
				}, {
					type: 'separator'
				}, {
					column: 'paid.state',
					type: 'checkbox',
					checkboxListTitle: 'Paiement',
					filters: [{
						value: '0',
						label: 'Aucun'
					}, {
						value: '1',
						label: 'Partiel'
					}, {
						value: '2',
						label: 'Total'
					}]
				}
			]
		];
	}

	protected get optionsColumnsGrid(): DashboardOptions {
		return {
			columns: {
				displayed: {
					icons: true,
					selection: true
				}
			},
			actionButtons: {
				displayed: {
					add: false,
					edit: false,
					view: true,
					duplicate: true,
					delete: true,
					print: true
				}
			}
		};
	}
}

export default Customer;
