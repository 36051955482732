import { Module, Modules, Router } from '@autoprog/core-client';

import C_OrdersProvider from './js/controllers/Orders.Provider';
import T_OrdersProvider from './tpl/orders.provider.html';

import C_RecapProvider from './js/controllers/Recap.provider';
import T_RecapProvider from './tpl/recap.provider.html';

import C_SettingsOrderProvider from './js/controllers/Settings.OrderProvider';
import T_SettingsOrderProvider from './tpl/settings.OrderProvider.html';

import CE_ContentTabs from './js/customElements/ContentTab';
import CE_InputStockTab from './js/customElements/InputStockTab';
import CE_OrderCustomer from './js/customElements/OrderCustomer';
import CE_OrderPrintOption from './js/customElements/OrderPrintOption';
import CE_ProviderOrderTabs from './js/customElements/ProviderOrderTabs';

import CE_BrandLine from './js/customElements/createOrderByProduct/BrandLine';
import CE_ProductLine from './js/customElements/createOrderByProduct/ProductLine';

import Notifications from '@modules/Apps/js/libs/Notifications';

import SettingsRegister from '@modules/Settings/js/libs/SettingsRegister';

class OrderProviderModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_InputStockTab.register();
		CE_ProviderOrderTabs.register();
		CE_ContentTabs.register();
		CE_OrderCustomer.register();
		CE_OrderPrintOption.register();

		CE_BrandLine.register();
		CE_ProductLine.register();
	}

	public async preInit() { }

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/ordersPage/provider',
			controller: C_OrdersProvider as any,
			tpl: T_OrdersProvider,
			requireValidUser: true,
			permission: 'ORDERS._PROVIDERS.OPEN'
		});

		router.addRoute({
			url: '/module/commands_export/provider',
			controller: C_RecapProvider as any,
			tpl: T_RecapProvider,
			requireValidUser: true,
			permission: 'ORDERS._PROVIDERS.RECAP_BILLS'

		});

		Notifications.addCategory(['command-providers'], {
			textFilter: 'Commande Fournisseur',
			icon: 'icon-solid-file-alt',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					C_OrdersProvider.open(item.data.id, { notification: item._id });
				}
			}
		});

		const settingsRegister = SettingsRegister.getInstance();
		settingsRegister.addCategory({
			id: 'general',
			order: 0
		});

		settingsRegister.addLink({
			id: 'apps',
			Controller: C_SettingsOrderProvider,
			tpl: T_SettingsOrderProvider,
			icon: 'file-text/line',
			order: 20,
			category: 'general',
			name: 'Commande - Fournisseur',
			permission: 'ORDERS._PROVIDERS.DEFAULT_SETTINGS'
		});
	}

	public async postInit() { }
}

Modules.registerModule('Commandes-Fournisseur', OrderProviderModule);
