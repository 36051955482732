// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import moment, { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/addLinePayment.html';

// LIBS
import Modal from '@libs/Modal';
import Utils from '@libs/utils/Utils';

import S_Companies from '@services/CompaniesService';
import S_P_Bills from '@services/Provider/ProviderBillService';

// UTILS
import Decimal from '@libs/utils/Decimal';

class AddLinePaymentBill extends Modal {
	private type: 'add' | 'edit' = 'add';

	private defaultPrice: number = 0;

	constructor(idBill: string = '', data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		data = _.cloneDeep(data);

		if (Object.keys(data).length) {
			this.type = 'edit';
			this.defaultPrice = data.price;
		}

		this.on('opened', async () => {
			let max = new Decimal(0);

			const N_title = this.element.querySelector('.modal-title') as HTMLDivElement;

			if (this.type === 'edit') {
				N_title.innerHTML = 'Éditer un paiement';
			}

			if (idBill) {
				const tmp = await S_P_Bills.getInstance().getNotPaidById(idBill);
				max = Decimal.setDisplayNumber(tmp);
			}

			if (data.price) {
				const price = Decimal.setDisplayNumber(data.price);
				max = max.plus(price);
				data.price = price.toNumber();
			} else {
				data.price = max;
			}

			const N_type = this.element.querySelector('[name="type"]') as HTMLSelectElement;
			const N_bank = this.element.querySelector('[name="bank"]') as HTMLSelectElement;
			const N_container_numberCheque = this.element.querySelector('#container_numberCheque') as HTMLSelectElement;
			const N_container_bank = this.element.querySelector('#container_bank') as HTMLSelectElement;

			const company = await S_Companies.getInstance().getById(Utils.companyID);

			for (const item of (company.bankList || [])) {
				N_bank.appendChild(new Option(item.name, item.name));
			}

			if (max.lessThan(0)) {
				data.type = 'Remboursement';
				N_type.disabled = true;
				N_title.innerHTML += ' (remboursement)';
			} else {
				N_type.addEventListener('change', () => {
					if (N_type.value === 'Chèque') {
						N_container_numberCheque.classList.remove('d-none');
						N_container_bank.classList.remove('d-none');
					} else if (N_type.value === 'Espèce') {
						N_container_bank.classList.add('d-none');
						N_container_numberCheque.classList.add('d-none');
					} else if (N_type.value === 'Virement') {
						N_container_bank.classList.remove('d-none');
						N_container_numberCheque.classList.add('d-none');
					} else if (N_type.value === 'Paypal') {
						N_container_bank.classList.add('d-none');
						N_container_numberCheque.classList.add('d-none');
					} else if (N_type.value === 'Traite') {
						N_container_bank.classList.add('d-none');
						N_container_numberCheque.classList.add('d-none');
					} else if (data.type === 'Remboursement') {
						N_container_bank.classList.add('d-none');
						N_container_numberCheque.classList.add('d-none');
					}
				});
			}

			if (data.type) {
				if (data.type === 'Remboursement') {
					N_container_bank.classList.add('d-none');
					N_container_numberCheque.classList.add('d-none');
				} else if (data.type === 'Chèque') {
					N_container_numberCheque.classList.remove('d-none');
					N_container_bank.classList.remove('d-none');
				} else if (data.type === 'Espèce') {
					N_container_bank.classList.add('d-none');
					N_container_numberCheque.classList.add('d-none');
				} else if (N_type.value === 'Paypal') {
					N_container_bank.classList.add('d-none');
					N_container_numberCheque.classList.add('d-none');
				} else if (N_type.value === 'Traite') {
					N_container_bank.classList.add('d-none');
					N_container_numberCheque.classList.add('d-none');
				} else {
					data.type = 'Virement';

					N_container_bank.classList.remove('d-none');
					N_container_numberCheque.classList.add('d-none');
				}
			}

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const N_maxPayment = this.element.querySelector('#max-payment') as HTMLSpanElement;
			const N_minPayment = this.element.querySelector('#min-payment') as HTMLSpanElement;

			const form = new Form(N_form);

			if (data.date) {
				data.date = moment(data.date, 'x');
			} else {
				data.date = moment();
			}

			data.price = Decimal.setDisplayNumber(data.price).humanizeNumber();

			if (max.lessThan(0)) {
				N_minPayment.innerHTML = `&nbsp;- Min ( <span class="text-monospace" id="min">${max.setSuffixAndHumanizeNumber('€')}</span> )`;
				N_maxPayment.innerHTML = `&nbsp;- Max ( <span class="text-monospace" id="max">${new Decimal(0).setSuffixAndHumanizeNumber('€')}</span> )`;
			} else {
				N_maxPayment.innerHTML = `&nbsp;- Max ( <span class="text-monospace" id="max">${max.setSuffixAndHumanizeNumber('€')}</span> )`;
			}

			form.setData(data);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_maxBtn = this.element.querySelector('#applyMaxButton') as HTMLButtonElement;
			const N_price = this.element.querySelector('[name="price"]') as HTMLInputElement;

			if (max.equals(0)) {
				this.displayFullyPaid();
			}

			N_save.disabled = this.checkError(data.price, max);

			N_price.addEventListener('input', () => {
				const data = form.getData();
				N_save.disabled = this.checkError(data.price as string, max);
			});

			N_save.addEventListener('click', async () => {
				const data = form.getData();

				if (data.type === 'Espèce') {
					data.numberCheque = '';
					data.bank = '';
				} else if (data.type === 'Virement') {
					data.numberCheque = '';
				}

				data.date = (data.date as Moment).format('x');
				data.user = Utils.userID;

				data.price = Decimal.setDisplayNumber(data.price as string).toNumber();

				if (idBill) {
					const tmp = await S_P_Bills.getInstance().getNotPaidById(idBill);
					max = Decimal.setDisplayNumber(tmp);
				}

				if (this.defaultPrice) {
					const defaultPrice = Decimal.setDisplayNumber(this.defaultPrice);
					max = max.plus(defaultPrice);
				}

				N_maxPayment.innerHTML = `&nbsp;- Max ( <span class="text-monospace" id="max">${max.setSuffixAndHumanizeNumber('€')}</span> )`;

				if (!this.checkError(data.price, max)) {
					this.resolve(data);
				}
			});

			N_maxBtn.addEventListener('click', (e) => {
				e.preventDefault();
				data.price = max.humanizeNumber();
				form.setData(data);
				N_save.disabled = this.checkError(data.price as string, max);
			});
		});
	}

	private checkError(price: number | string, max: Decimal) {
		price = Decimal.setDisplayNumber(price).toNumber();

		const N_error = this.element.querySelector('#error') as HTMLElement;
		const N_maxBtn = this.element.querySelector('#applyMaxButton') as HTMLButtonElement;

		N_error.classList.add('d-none');
		N_maxBtn.classList.add('d-none');

		if (max.equals(0)) {
			this.displayFullyPaid();
			return true;
		}

		if (max.lessThan(0)) {
			if (price > 0) {
				N_error.classList.remove('d-none');
				N_maxBtn.classList.remove('d-none');
				return true;
			}

			if (price < max.toNumber()) {
				N_error.classList.remove('d-none');
				N_maxBtn.classList.remove('d-none');
				return true;
			}
		} else {
			if (price < 0) {
				N_error.classList.remove('d-none');
				N_maxBtn.classList.remove('d-none');
				return true;
			}

			if (price > max.toNumber()) {
				N_error.classList.remove('d-none');
				N_maxBtn.classList.remove('d-none');
				return true;
			}
		}

		return false;
	}

	private displayFullyPaid() {
		const N_form = this.element.querySelector('form') as HTMLFormElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_form.innerHTML = `
			<div class="h-100 w-100 text-center text-danger">
				<i class="icon icon-warning text-danger display-1"></i>
				<div class="font-weight-bold mt-4"> 
					Ajout du paiement impossible <br>
					Facture payée entièrement 
				</div>
			</div>
		`;
		N_save.classList.add('d-none');
	}
}

export default AddLinePaymentBill;
