import { Controller, LoggedUser, Router, Tabs } from '@autoprog/core-client';

import C_Customers from '@modules/OrdersCustomer/js/tabs/OrdersCustomerTabs';
import T_TabsCustomer from '@modules/OrdersCustomer/tpl/tabs/ordersCustomerTabs.html';

import C_Provider from '@modules/OrdersProvider/js/tabs/OrdersProviderTabs';
import T_TabsProvider from '@modules/OrdersProvider/tpl/tabs/ordersProviderTabs.html';

import History from '@libs/History';

class OrdersCtrl extends Controller {
	private tabs: Tabs;

	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		params = params || {};

		let defaultTab: 'customers' | 'providers' | undefined = params.tabs;

		const navElement = el.querySelector('.tabs-container') as HTMLElement;

		this.tabs = new Tabs({
			navElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		const loggedUser = LoggedUser.getInstance();

		//Gestion des permissions sur les uri pointant sur les onglets de la page
		if (defaultTab === 'customers' && !loggedUser.hasPermission('ORDERS._CUSTOMERS.SHOW')) {
			Router.getInstance().navigate('#403');
			return;
		} else if (defaultTab === 'providers' && !loggedUser.hasPermission('ORDERS._PROVIDERS.SHOW')) {
			Router.getInstance().navigate('#403');
			return;
		} else if (defaultTab !== 'customers' && defaultTab !== 'providers' && defaultTab !== undefined) {
			Router.getInstance().navigate('#404');
		}

		if (loggedUser.hasPermission('ORDERS._CUSTOMERS.SHOW')) {
			defaultTab = defaultTab || 'customers';
		}

		this.tabs.add({
			name: 'Clients',
			id: 'customers',
			default: defaultTab === 'customers',
			permission: 'ORDERS._CUSTOMERS.SHOW',
			controller: C_Customers,
			template: T_TabsCustomer
		});

		if (loggedUser.hasPermission('ORDERS._PROVIDERS.SHOW')) {
			defaultTab = defaultTab || 'providers';
		}

		this.tabs.add({
			name: 'Fournisseurs',
			id: 'providers',
			default: defaultTab === 'providers',
			permission: 'ORDERS._PROVIDERS.SHOW',
			controller: C_Provider,
			template: T_TabsProvider
		});

		let firstLoad = true;
		this.tabs.on('change', (data: { [key: string]: any }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/orders/${data.id}`);
			}
		});
	}

	public destructor() { }
}

export default OrdersCtrl;
