// CORE
import { Controller, LoggedUser, Router } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';
import moment from 'moment';
import tippy from 'tippy.js';

// TEMPLATE
// LIBS
import { GetContextMenuItemsParams, GetQuickFilterTextParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import AgGridStateSaver from '@libs/agGrid/StateSaver';
import CreateOrderContextMenu from '@modules/OrdersProvider/js/libs/ProductContextMenu';
import NumberCellRenderer from '@js/libs/agGrid/cellRenderer/NumberCellRenderer';
import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

// PRINTER

// UTILS
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

// MODAL
import M_AddEditProduct from '@modules/Products/js/modals/AddEditProduct';
import M_Edit from '../modals/Edit';
import M_Input from '../modals/Input';
import M_M_Input from '../modals/multiple/Input';
import M_M_Output from '../modals/multiple/Output';
import M_Output from '../modals/Output';
import M_ViewProduct from '@modules/Products/js/modals/ViewProduct';
import M_export from '../modals/Export';

// CUSTOM_ELEMENT
import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';
import CE_FilterButton from '@libs/customElement/FilterButton';
import CE_GridSidebar from '@libs/customElement/GridSidebar';
import CE_HeaderDashboard from '@libs/customElement/HeaderDashboard';
import CE_SearchBar from '@libs/customElement/searchBar';

// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';
import S_StockInventory from '@services/StockInventoryService';
import h from 'hyperscript';

class StockController extends Controller {
	private N_grid: CE_AgGrid;
	private N_title: CE_HeaderDashboard;

	private _isLoad: boolean;

	private currentStock: string = '';
	private lastInventory: string = '';

	private el: HTMLElement;
	private stateSaver: AgGridStateSaver;

	private viewAllProductsChecked = false;
	private viewNegativesQuantitiesChecked = false;

	private abortController: AbortController;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.N_title = document.querySelector('ap-header-dashboard') as CE_HeaderDashboard;

		this._isLoad = false;
		this.isLoad = false;

		this.abortController = new AbortController();

		this.N_grid = el.querySelector<CE_AgGrid>('#grid')!;

		this.N_grid.setGridOptions({
			columnDefs: [
				{
					headerComponentParams: {
						template: `	
							<div class="ag-cell-label-container" role="presentation">
								<div ref="eLabel" class="ag-header-cell-label" role="presentation">
									<ap-icon class="h5" name="more/fill"></ap-icon>
								</div>
							</div>
						`
					},
					headerClass: 'ag-theme-custom-text-center',
					field: '_icons_',
					filter: false,
					width: 90,
					suppressSizeToFit: true,
					suppressMovable: true,
					checkboxSelection: true,
					headerCheckboxSelectionFilteredOnly: true,
					headerCheckboxSelection: true,
					resizable: false,
					pinned: 'left',
					sortable: false,
					suppressColumnsToolPanel: true,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');
						N_div.classList.add('container-icons-aggrid');

						const cellRenderer = new ProductCellRenderer();
						cellRenderer.init({ params, value: params.data.product._id.value, isOnlyImage: true });

						N_div.append(cellRenderer.getGui());

						return N_div;
					}
				},
				{
					headerName: 'Référence',
					field: 'product.reference',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Code Produit',
					field: 'product.internalCode',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Désignation',
					field: 'product.label',
					filterParams: {
						textFormatter: (result: string) => {
							if (result == null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				},
				{
					field: 'stock',
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					field: 'displayStock',
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					field: 'hasNegativeQuantity',
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					width: 150,
					suppressSizeToFit: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						applyMiniFilterWhileTyping: true
					}
				},
				{
					headerName: 'Empl.',
					field: 'product.locationStock',
					width: 150,
					suppressSizeToFit: true
				},
				{
					field: 'typeAlert',
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						values: ['normal', 'warning', 'alert', 'negative']
					},
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					headerName: 'Dernière Vérification',
					field: 'lastUpdate.date',
					width: 200,
					suppressSizeToFit: true,
					getQuickFilterText(params: GetQuickFilterTextParams) {
						return moment(params.value, 'x').format('DD/MM/YYYY');
					},
					cellRenderer: (params) => {
						if (params.data.lastUpdate && params.data.lastUpdate.user) {
							return `
                                ${moment(params.data.lastUpdate.date).format('DD/MM/YYYY HH:mm')} - 
                                <span class="text-xs text-muted text-center w-100">Par ${params.data.lastUpdate.user}</span>        
                            `;
						} else {
							return `
                                <span class="text-muted">Aucune vérification</span>
                            `;
						}
					}
				},
				{
					headerName: 'Qté',
					field: 'quantity',
					width: 80,
					filter: 'agNumberColumnFilter',
					suppressSizeToFit: true,
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					cellRenderer: NumberCellRenderer
				},
				{
					headerName: 'Qté commandée',
					suppressSizeToFit: true,
					width: 200,
					field: 'lastOrder',
					cellClass: 'text-monospace text-right',
					valueGetter: (params) => {
						const data = params.data.lastOrder || {};
						let total = 0;
						if (Object.keys(data.customer || {}).length) {
							for (const id in data.customer) {
								total += data.customer[id].quantity;
							}
						}

						if (Object.keys(data.internal || {}).length) {
							for (const id in data.internal) {
								total += data.internal[id].quantity;
							}
						}

						return total;
					},
					cellRenderer: (params) => {
						let total = 0;
						let numberOrder = 0;

						const tooltip: string[] = [];

						const data = params.data.lastOrder || {};

						if (Object.keys(data.customer || {}).length) {
							tooltip.push('<div class="d-flex font-weight-bold my-2 border-bottom-2x border-dark">Livraison direct client</div>');

							for (const id in data.customer) {
								total += data.customer[id].quantity;
								numberOrder++;

								tooltip.push(`<div class="d-flex">${data.customer[id].text} <div class="ml-auto pl-2">(Qté: ${data.customer[id].quantity.toFixed(2).replace(/\.?0+$/, '')})</div></div>`);
							}
						}

						if (Object.keys(data.internal || {}).length) {
							tooltip.push('<div class="d-flex font-weight-bold my-2 border-bottom-2x border-dark">Livraison interne</div>');

							for (const id in data.internal) {
								total += data.internal[id].quantity;
								numberOrder++;

								tooltip.push(`<div class="d-flex">${data.internal[id].text} <div class="ml-auto pl-2">(Qté: ${data.internal[id].quantity.toFixed(2).replace(/\.?0+$/, '')})</div><div class="ml-auto pl-2">(${data.internal[id].sendingDate ? 'Envoyé' : 'Non Envoyé'})</div></div>`);
							}
						}

						const N_div = document.createElement('div');

						N_div.classList.add('w-100');
						N_div.setAttribute('popover', JSON.stringify({ title: 'Détail commande:', content: tooltip.join('') || 'Aucune commande', trigger: 'hover', placement: 'right', sanitize: false }));

						N_div.innerHTML = `${total.toFixed(2).replace(/\.?0+$/, '')} (${numberOrder} Commande${numberOrder > 1 ? 's' : ''})`;

						return N_div;
					}
				},
				{
					headerName: 'Coût de revient',
					field: 'product.purchasePrice',
					width: 100,
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					cellClassRules: {
						'bg-amber-200': (params: any) => {
							return params.data.product.error.purchasePrice.value;
						},
						'text-center': (params: any) => {
							return params.data.product.error.purchasePrice.value;
						}
					},
					cellRenderer: (params) => {
						if (params.data.product.error.purchasePrice.value) {
							return '<ap-icon class="h2 mt-1" name="alert/line" tooltip="Aucun prix d\'achat"></ap-icon>';
						}

						const cellRenderer = new NumericCellRenderer();
						cellRenderer.init(params);

						return cellRenderer.getGui();
					},
					cellRendererParams: {
						suffix: '€'
					}
				},
				{
					headerName: 'PU de vente',
					field: 'product.price',
					width: 100,
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						suffix: '€'
					}
				},
				{
					headerName: 'Total coût de revient',
					field: 'totalPurchasePrice',
					width: 150,
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					cellClassRules: {
						'bg-amber-200': (params: any) => {
							return params.data.product.error.purchasePrice.value;
						},
						'text-center': (params: any) => {
							return params.data.product.error.purchasePrice.value;
						}
					},
					cellRenderer: (params) => {
						if (params.data.product.error.purchasePrice.value) {
							return '<ap-icon class="h2 mt-1" name="alert/line" tooltip="Aucun prix d\'achat"></ap-icon>';
						}

						const cellRenderer = new NumericCellRenderer();
						cellRenderer.init(params);

						return cellRenderer.getGui();
					},
					cellRendererParams: {
						suffix: '€'
					}

				},
				{
					headerName: 'Prix total de vente',
					field: 'totalPrice',
					width: 150,
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						suffix: '€'
					}
				},
				{
					headerName: 'Action',
					field: '_id_1',
					headerClass: 'ag-theme-custom-text-center',
					width: 150,
					sortable: false,
					resizable: false,
					filter: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					suppressColumnsToolPanel: true,
					pinned: 'right',
					cellRenderer: (params) => {
						const productID = params.data.product._id.value;
						const stockID = params.data.stock.value;
						const quantity = params.data.quantity.value;

						const N_div = h<HTMLElement>('div.container-action-aggrid');

						const N_moreActionBtn = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { type: 'default', tooltip: 'Mouvement Stock' } });

						N_moreActionBtn.innerHTML = '<ap-icon name="arrow-up-down/line" class="icon-2x"></ap-icon>';

						N_moreActionBtn.setAttribute('permission', 'STOCK.INPUT || STOCK.OUTPUT || STOCK.UPDATE');

						const N_tooltip = document.createElement('div');
						N_tooltip.classList.add('d-flex', 'flex-column');
						N_tooltip.innerHTML = `
							<button class="btn btn-blue-50 mb-2" id="input" permission="STOCK.INPUT"><i class="icon icon-solid-sign-in-alt mr-2 text-indigo"></i>Entrée</button>
							<button class="btn btn-blue-50 mb-2" id="output" permission="STOCK.OUTPUT"><i class="icon icon-solid-sign-out-alt mr-2 text-red"></i>Sortie</button>
							<button class="btn btn-blue-50" id="edit" permission="STOCK.UPDATE"><i class="icon icon-solid-sync-alt mr-2"></i>Mettre à jour</button>
						`;

						const N_input = N_tooltip.querySelector<HTMLButtonElement>('#input')!;
						const N_output = N_tooltip.querySelector<HTMLButtonElement>('#output')!;
						const N_update = N_tooltip.querySelector<HTMLButtonElement>('#edit')!;

						N_input.addEventListener('click', () => {
							new M_Input(productID, quantity, stockID).open().then(() => {
								this.getData();
							}).catch(() => { });
						});

						N_output.addEventListener('click', () => {
							new M_Output(productID, quantity, stockID).open().then(() => {
								this.getData();
							}).catch(() => { });
						});

						N_update.addEventListener('click', () => {
							new M_Edit(productID, quantity, stockID).open().then(() => {
								this.getData();
							}).catch(() => { });
						});

						tippy(N_moreActionBtn, {
							trigger: 'click',
							allowHTML: true,
							interactive: true,
							theme: 'material',
							appendTo: document.body,
							content: N_tooltip
						});

						const N_seeProduct = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: 'PRODUCTS.OPEN', type: 'seeMore', tooltip: 'Voir Détail Produit' } });

						N_seeProduct.addEventListener('click', async () => {
							new M_ViewProduct(productID).open().then((update) => {
								if (update) {
									this.getData();
								}
							});
						});

						const N_editProduct = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: 'PRODUCTS.EDIT', type: 'edit', tooltip: 'Éditer Produit' } });

						N_editProduct.addEventListener('click', async () => {
							new M_AddEditProduct(productID, 0).open().then(() => {
								this.getData();
							});
						});

						N_div.appendChild(N_moreActionBtn);
						N_div.appendChild(N_seeProduct);
						N_div.appendChild(N_editProduct);

						return N_div;
					}
				}
			],
			defaultColDef: {
				floatingFilter: true,
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true
			},
			rowSelection: 'multiple',
			getContextMenuItems: this.getContextMenu.bind(this),
			getRowStyle: (params: any) => {
				if (this.N_grid.isLoad) {
					if (params.node.data.typeAlert.value === 'alert') {
						return {
							'background-color': 'var(--ap-orange-100)',
							color: 'var(--ap-orange-900)'
						};
					} else if (params.node.data.typeAlert.value === 'negative') {
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					} else if (params.node.data.typeAlert.value === 'warning') {
						return {
							'background-color': 'var(--ap-yellow-50)',
							color: 'var(--ap-yellow-900)'
						};
					}
				}

				return {};
			},
			getRowHeight() {
				return 42 + 5 * 2;
			},
			onFilterChanged: (params: any) => {
				params.columnApi.setColumnsVisible(['displayStock', 'stock'], false);
				this.updatePrice();
			},
			isFullWidthCell: () => {
				return !this.isLoad;
			},
			fullWidthCellRenderer: () => {
				return '<div class="cell-loading"></div>';
			},
			rowData: []
		});

		this.stateSaver = new AgGridStateSaver(this.N_grid!.gridOptions, 'stock-view');

		this.initList();
		this.initMultipleOutput();
		this.initMultipleInput();
		this.initInventory();

		this.initHeaderDashboard();
		this.initRefreshButton();

		this.initGridSidebar();
		this.getData(true);

		const N_viewAllProducts = el.querySelector('#viewAllProducts') as HTMLInputElement;

		N_viewAllProducts.addEventListener('change', async () => {
			if (this.N_grid.api) {
				this.viewAllProductsChecked = N_viewAllProducts.checked;

				const filterInstance = this.N_grid.api?.getFilterInstance('displayStock');

				filterInstance?.setModel({
					type: 'equals',
					filter: this.viewAllProductsChecked ? '' : 'true'
				});

				this.N_grid.api?.onFilterChanged();
			}
		});

		const N_viewNegativesQuantities = el.querySelector('#viewNegativesQuantities') as HTMLInputElement;

		N_viewNegativesQuantities.addEventListener('change', async () => {
			if (this.N_grid.api) {
				this.viewNegativesQuantitiesChecked = N_viewNegativesQuantities.checked;

				const filterInstance = this.N_grid.api?.getFilterInstance('hasNegativeQuantity');

				filterInstance?.setModel({
					type: 'equals',
					filter: this.viewNegativesQuantitiesChecked ? '' : 'false'
				});

				this.N_grid.api?.onFilterChanged();
			}
		});

		const N_export = el.querySelector('#export') as HTMLButtonElement;

		N_export.addEventListener('click', () => {
			new M_export(this.N_grid!.gridOptions).setStock(this.currentStock).open().then(() => {

			}).catch(() => { });
			//new M_Export(this.currentStock).open().then(() => { }).catch(() => { });
		});

		this.initFilterButton();
	}

	private set isLoad(value: boolean) {
		if (value) {
			document.body.classList.remove('loading');
		} else {
			document.body.classList.add('loading');
		}

		this._isLoad = value;
	}

	private get isLoad() {
		return this._isLoad;
	}

	private initRefreshButton() {
		const N_reload = this.el.querySelector('#reload') as HTMLButtonElement;

		N_reload.addEventListener('click', () => {
			Utils.removeTooltip();
			this.getData();
		}, {
			signal: this.abortController.signal
		});
	}

	private initFilterButton() {
		const N_filter = this.el.querySelector('ap-filter-button') as CE_FilterButton;
		N_filter.setConfig([[{
			column: 'typeAlert',
			type: 'checkbox',
			checkboxListTitle: 'Alerte : ',
			filters: [{
				iconColor: 'grey-200',
				value: 'normal',
				label: 'Aucune alerte'
			}, {
				iconColor: 'yellow-200',
				value: 'warning',
				label: 'A réapprovisionner'
			}, {
				iconColor: 'orange-100',
				value: 'alert',
				label: 'Alerte'
			}, {
				iconColor: 'red-100',
				value: 'negative',
				label: 'Négative'
			}]
		}]], this.N_grid, ['stock', 'displayStock', 'hasNegativeQuantity']);

		const N_search = this.el.querySelector('ap-search-bar') as CE_SearchBar;
		N_search.setGridOptions(this.N_grid);
	}

	private initGridSidebar() {
		const N_GridSidebar = this.el.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.N_grid.gridOptions);
	}

	private async getLastInventory() {
		const N_inventory = this.el.querySelector('#inventory') as HTMLButtonElement;
		const data = await S_StockInventory.getInstance().getLastInventory(this.currentStock);

		if (data.length) {
			this.lastInventory = data[0]._id;
			N_inventory.innerHTML = '<i class="icon icon-ri-shopping-bag-3-fill mr-2"></i>Continuer l\'inventaire';
		} else {
			this.lastInventory = '';
			N_inventory.innerHTML = '<i class="icon icon-ri-shopping-bag-3-line mr-2"></i>Créer un inventaire';
		}
	}

	private initMultipleOutput() {
		const N_multiple_output = this.el.querySelector<HTMLButtonElement>('#multiple_output')!;

		N_multiple_output.addEventListener('click', () => {
			new M_M_Output(this.currentStock).open().then(() => {
				this.getData();
			}).catch(() => { });
		});
	}

	private initMultipleInput() {
		const N_multiple_input = this.el.querySelector<HTMLButtonElement>('#multiple_input')!;

		N_multiple_input.addEventListener('click', () => {
			new M_M_Input(this.currentStock).open().then(() => {
				this.getData();
			}).catch(() => { });
		});
	}

	private initInventory() {
		const N_inventory = this.el.querySelector<HTMLButtonElement>('#inventory')!;

		N_inventory.addEventListener('click', () => {
			Router.getInstance().navigate(`/#module/stock/inventory?stock=${this.currentStock}${this.lastInventory ? '&id=' + this.lastInventory : ''}`);
		});
	}

	private initList() {
		const N_list = this.el.querySelector<HTMLSelectElement>('#list')!;

		S_Stock.getInstance().getRealStock().then((data) => {
			const optionNoFilterStock = new Option('Aucun emplacement', 'noFilterStock', false, false);
			N_list.append(optionNoFilterStock);
			for (const item of data) {
				const option = new Option(item.name, item._id, item.default, item.default);

				N_list.append(option);

				if (item.default) {
					this.currentStock = item._id;
				}
			}

			this.getLastInventory();
		});

		N_list.addEventListener('change', () => {
			if (this.N_grid.api) {
				this.currentStock = N_list.value;

				const filterInstance = this.N_grid.api.getFilterInstance('stock');

				if (this.currentStock === 'noFilterStock') {
					filterInstance?.setModel(null);
				} else {
					filterInstance?.setModel({
						type: 'equals',
						filter: this.currentStock
					});
				}

				this.N_grid.api.onFilterChanged();

				this.getLastInventory();

				this.updatePrice();
			}
		});
	}

	private initHeaderDashboard() {
		const N_HeaderDashboard = this.el.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
		N_HeaderDashboard.setTitle('Stock');
		N_HeaderDashboard.setGridOptions(this.N_grid.gridOptions);
	}

	private async getData(initStateSaver: boolean = false) {
		const filterModel = this.N_grid.api?.getFilterModel();

		this.N_title.hideNumber();
		this.N_grid.api?.setFilterModel(null);
		this.isLoad = false;
		this.N_grid.resetValue();

		const data = await S_StockEvent.getInstance().getCurrentStock({
			user: Utils.userID
		});

		this.isLoad = true;
		this.N_grid.value = data.rowData;
		this.N_title.displayNumber();

		this.N_grid.api?.setFilterModel(filterModel);

		if (initStateSaver) {
			this.stateSaver.setData(data.settings);
		}

		// Initialisation du select du stock
		const filterInstanceStock = this.N_grid.api?.getFilterInstance('stock');
		const stockId = filterInstanceStock?.getModel()?.filter;

		const N_list = this.el.querySelector<HTMLSelectElement>('#list')!;
		if (stockId) {
			N_list.value = stockId;
		} else {
			N_list.value = 'noFilterStock';
		}

		// Initialisation du bouton pour afficher les quantités à zéro
		const filterInstanceDisplay = this.N_grid.api?.getFilterInstance('displayStock');
		const N_viewAllProducts = this.el.querySelector('#viewAllProducts') as HTMLInputElement;
		N_viewAllProducts.checked = filterInstanceDisplay?.getModel()?.filter !== 'true';

		// Initialisation du bouton pour afficher les quantités négatives
		const filterInstanceQuantity = this.N_grid.api?.getFilterInstance('hasNegativeQuantity');
		const N_viewNegativesQuantities = this.el.querySelector('#viewNegativesQuantities') as HTMLInputElement;
		N_viewNegativesQuantities.checked = filterInstanceQuantity?.getModel()?.filter !== 'false';

		this.N_grid.api?.onFilterChanged();
	}

	private getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		const result: any[] = [];

		const productID = params.node.data.product._id.value;
		const stockID = params.node.data.stock.value;
		const quantity = params.node.data.quantity.value;

		result.push({
			name: 'Mouvement Stock',
			subMenu: [
				{
					name: 'Entrée',
					icon: '<i class="icon icon-solid-sign-in-alt text-indigo"></i>',
					action: () => {
						new M_Input(productID, quantity, stockID).open().then(() => {
							this.getData();
						});
					}
				},
				{
					name: 'Sortie',
					icon: '<i class="icon icon-solid-sign-out-alt text-red"></i>',
					action: () => {
						new M_Output(productID, quantity, stockID).open().then(async () => {
							this.getData();
						});
					}
				},
				{
					name: 'Mettre à jour',
					icon: '<i class="icon icon-solid-sync-alt"></i>',
					action: () => {
						new M_Edit(productID, quantity, stockID).open().then(async () => {
							this.getData();
						});
					}
				}
			]
		});

		result.push('separator');

		result.push(CreateOrderContextMenu.orderProduct(this.convertProductToOrderProvider(params), !params.node));

		result.push('separator');

		if (LoggedUser.getInstance().hasPermission('PRODUCTS.OPEN')) {
			result.push({
				name: 'Voir Produit',
				icon: '<ap-icon name="eye/line" class="icon-2x"></ap-icon>',
				action: async () => {
					await new M_ViewProduct(productID).open();
					this.getData();
				}
			});
		}

		if (LoggedUser.getInstance().hasPermission('PRODUCTS.EDIT')) {
			result.push({
				name: 'Éditer Produit',
				icon: '<ap-icon name="edit/line" class="icon-2x"></ap-icon>',
				action: async () => {
					new M_AddEditProduct(productID, 0).open().then(() => {
						this.getData();
					});
				}
			});
		}

		let title = `${params.node.data.product[S_Product.getInstance().referenceKey].formattedValue} - ${params.node.data.product.label.formattedValue}`;
		if (title.length > 30) {
			title = title.slice(0, 30) + '...';
		}

		return [
			{
				name: title,
				disabled: true,
				cssClasses: ['title-context-menu']
			},
			...result
		];
	}

	private convertProductToOrderProvider(params: GetContextMenuItemsParams) {
		let products = params.api?.getSelectedRows() || [];

		if (products.length === 0) {
			products.push(params.node.data);
		}

		products = products.map(({ product, typeAlert, quantity }) => {
			return {
				productID: product._id.value,
				quantity: typeAlert.value === 'alert' || typeAlert.value === 'negative' ? product.alarmStock.value - quantity.value : 1
			};
		});

		return products;
	}

	private updatePrice() {
		const N_HeaderDashboard = this.el.querySelector('ap-header-dashboard') as CE_HeaderDashboard;

		const calculatePrice = {
			purchasePrice: new Decimal(0),
			price: new Decimal(0),
			numberProduct: 0
		};

		const notCalculatePrice = {
			purchasePrice: new Decimal(0),
			price: new Decimal(0),
			numberProduct: 0
		};

		let hasMissingProductPrices = false;

		this.N_grid.api?.forEachNodeAfterFilter((node) => {
			if (this.currentStock === node.data.stock.value) {
				const totalPurchasePrice = node.data.totalPurchasePrice ? Decimal.setDisplayNumber(node.data.totalPurchasePrice.value) : null;
				const totalPrice = Decimal.setDisplayNumber(node.data.totalPrice.value);

				if (totalPurchasePrice === null) {
					hasMissingProductPrices = true;
				}

				if (node.data.product.stock.calculatePrice.value) {
					calculatePrice.purchasePrice = calculatePrice.purchasePrice.plus(totalPurchasePrice ?? Decimal.setDisplayNumber(0));
					calculatePrice.price = calculatePrice.price.plus(totalPrice);
					calculatePrice.numberProduct++;
				} else {
					notCalculatePrice.purchasePrice = notCalculatePrice.purchasePrice.plus(totalPurchasePrice ?? Decimal.setDisplayNumber(0));
					notCalculatePrice.price = notCalculatePrice.price.plus(totalPrice);
					notCalculatePrice.numberProduct++;
				}
			}
		});

		const total = {
			purchasePrice: calculatePrice.purchasePrice.plus(notCalculatePrice.purchasePrice),
			price: calculatePrice.price.plus(notCalculatePrice.price),
			numberProduct: calculatePrice.numberProduct + notCalculatePrice.numberProduct
		};

		const headerDashboardLines = [
			{ label: 'Total', labelClass: 'font-weight-bold h6', value: '' },
			{ label: 'Nombre de produits', value: total.numberProduct.toString() },
			{ label: 'Prix d\'achat', value: total.purchasePrice.setSuffixAndHumanizeNumber('€').toString() },
			{ label: 'Prix de vente', value: total.price.setSuffixAndHumanizeNumber('€').toString() },
			{ label: '', value: '' },
			{ label: 'Valorisé', labelClass: 'font-weight-bold h6', value: '' },
			{ label: 'Nombre de produits', value: calculatePrice.numberProduct.toString() },
			{ label: 'Prix d\'achat', value: calculatePrice.purchasePrice.setSuffixAndHumanizeNumber('€').toString() },
			{ label: 'Prix de vente', value: calculatePrice.price.setSuffixAndHumanizeNumber('€').toString() },
			{ label: '', value: '' },
			{ label: 'Non valorisé', labelClass: 'font-weight-bold h6', value: '' },
			{ label: 'Nombre de produits', value: notCalculatePrice.numberProduct.toString() },
			{ label: 'Prix d\'achat', value: notCalculatePrice.purchasePrice.setSuffixAndHumanizeNumber('€').toString() },
			{ label: 'Prix de vente', value: notCalculatePrice.price.setSuffixAndHumanizeNumber('€').toString() }
		];

		if (hasMissingProductPrices) {
			headerDashboardLines.push({ label: '', value: '' }, { label: 'Attention, certains prix ne sont pas renseignés !', value: '', labelClass: 'text-red font-weight-bold' });
		}

		N_HeaderDashboard.setData(headerDashboardLines);
	}

	public destructor() {

	}
}

export default StockController;
