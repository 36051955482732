import h from 'hyperscript';

import CE_RecapLine from './RecapLine';

import DecimalApps from '@libs/utils/Decimal';

import S_Quotes from '@services/QuoteService';
import S_Taxes from '@modules/Comptabilité/js/services/TaxesService';

import Calcul from '@modules/Quotes/js/libs/Calcul';
import QuoteOption from '@modules/Quotes/js/libs/QuoteOptions';
import TaxeModel from '@modules/Comptabilité/js/model/Taxe';

export type dataRecapQuote = {
	quoteID: string,
	selectedGroups: {
		[groupID: string]: number
	}
};

export type priceByGroup = {
	[idGroup: string]: {
		[tva: string]: {
			sum: number
			bill: number
			notBill: number
			tva: string
		}
	}
};

class RecapQuote extends HTMLElement {
	public static readonly tagName: string = 'ap-billing-request-recap-quote';

	private N_subTitle: CE_RecapLine | null = null;
	private N_sum: CE_RecapLine | null = null;

	private N_groups: CE_RecapLine[] = [];

	public connectedCallback() {
		this.innerHTML = '';

		this.N_subTitle = h<CE_RecapLine>('ap-billing-request-recap-line', { attrs: { type: 'sub-title' } });
		this.append(this.N_subTitle);

		this.N_sum = h<CE_RecapLine>('ap-billing-request-recap-line', { attrs: { type: 'sum' } });
		this.append(this.N_sum);

		this.initEvent();
	}

	public async setData(data: dataRecapQuote, priceByGroup: priceByGroup) {
		this.setAttribute('id-quote', data.quoteID);

		if (this.mode === 'create-credit' || this.mode === 'edit-credit') {
			// Vérification du total de facturation des groupes du devis
			let totalBill = 0;

			for (const group of Object.values(priceByGroup)) {
				for (const tva of Object.values(group)) {
					totalBill += tva.bill;
				}
			}

			// Si le devis n'est pas facturé, on le masque dans le récapitulatif
			if (totalBill === 0) {
				this.classList.add('d-none');
			}
		}

		const quote = await S_Quotes.getInstance().getById(data.quoteID);

		this.N_subTitle!.setDataTitle({
			textColLeft: `Récapitulatif devis ${quote.infos.number} / ${quote.infos.label}`,
			textColRight: ''
		});

		this.dispatchEvent(new CustomEvent('register.quote', { detail: { id: data.quoteID, text: quote.infos.number } }));

		const priceQuote = {
			sum: new DecimalApps(0),
			bill: new DecimalApps(0),
			notBill: new DecimalApps(0)
		};

		const cacheTVA: { [idTVA: string]: string } = {};

		for (const item of quote.data) {
			let quantity = 0;
			if (data.selectedGroups && data.selectedGroups[item._idGrid] !== undefined) {
				quantity = data.selectedGroups[item._idGrid];
			} else {
				quantity = item.quantity;
			}

			const groupByTVA: { [key: string]: any } = {};

			if (quantity && priceByGroup[item._idGrid]) {
				for (const detail of item.details) {
					groupByTVA[detail.tva] = true;
				}

				for (const tva in groupByTVA) {
					if (!cacheTVA[tva]) {
						const taxe = await (new S_Taxes().getById(tva));
						if (taxe) {
							cacheTVA[tva] = new TaxeModel(taxe).displayAggrid();
						} else {
							cacheTVA[tva] = ' ';
						}
					}

					if (priceByGroup[item._idGrid][tva]) {
						priceByGroup[item._idGrid][tva].tva = cacheTVA[tva];
					}

					this.N_groups.push(this.displayLine(quote, item, priceByGroup[item._idGrid][tva], quantity, tva));

					priceQuote.sum = priceQuote.sum.plus(DecimalApps.setDisplayNumber(priceByGroup[item._idGrid][tva].sum));
					priceQuote.bill = priceQuote.bill.plus(DecimalApps.setDisplayNumber(priceByGroup[item._idGrid][tva].bill));
					priceQuote.notBill = priceQuote.notBill.plus(DecimalApps.setDisplayNumber(priceByGroup[item._idGrid][tva].notBill));
				}
			}
		}

		this.N_sum!.mode = this.mode;
		this.N_sum!.setDataTextLine({ category: 'Total', textColRight: 'Total', tva: '' });
		this.N_sum!.setDataPriceLine({
			sum: priceQuote.sum.toNumber(),
			bill: priceQuote.bill.toNumber(),
			notBill: priceQuote.notBill.toNumber(),
			tva: ''
		});
	}

	private displayLine(quote: any, item: any, priceByGroup: { sum: number, bill: number, notBill: number, tva: string }, quantity: number, tva: string) {
		const N_line = h<CE_RecapLine>('ap-billing-request-recap-line');

		N_line.mode = this.mode;

		N_line.addEventListener('update.price', () => {
			this.updateSum();
		});

		N_line.addEventListener('select.group', () => {
			const quoteOption = QuoteOption.newInstance();

			quoteOption.infos = quote.infos;
			quoteOption.pref = quote.pref;
			quoteOption.quoteOptions = quote.quoteOptions;
			quoteOption.version = quote.version;

			const calcul = new Calcul();

			const res = {
				_idQuote: quote._id,
				_idGrid: N_line.idGroup,
				tva: N_line.tva,
				name: item.name,
				category: item.category,
				subCategory: item.subCategory,
				details: [] as { [key: string]: any }
			};

			for (const detail of item.details) {
				if (detail.tva === res.tva) {
					res.details.push({
						reference: detail.reference,
						label: detail.label,
						price: calcul.calculatePrice({ ...detail, quantity: detail.quantity * quantity }).sum
					});
				}
			}

			this.dispatchEvent(new CustomEvent('select.group', { detail: res }));
		});

		this.append(N_line);

		N_line.setDataTextLine({ category: `<span class="category">${item.category}</span>${item.subCategory ? '<br><span class="sub-category">' + item.subCategory : '</span>'}`, textColRight: '', tva: 'TVA :' });
		N_line.setDataPriceLine(priceByGroup);
		N_line.idGroup = item._idGrid;
		N_line.tva = tva;

		return N_line;
	}

	private initEvent() {
		this.N_sum?.addEventListener('update.price', () => {
			this.dispatchEvent(new CustomEvent('update.price'));
		});

		this.N_sum?.addEventListener('update.percent', () => {
			this.dispatchEvent(new CustomEvent('update.percent'));
			this.updateChildren();
		});

		this.N_sum?.addEventListener('update.lastPrice', () => {
			this.setLastPrice();
			this.N_sum?.checkError();
		});
	}

	private updateSum() {
		let price = new DecimalApps(0);

		for (const N_line of this.N_groups) {
			price = price.plus(N_line.price);
		}

		this.N_sum!.setPrice(price.toNumber());

		this.dispatchEvent(new CustomEvent('update.price'));
	}

	public loading() {
		for (let i = 0; i < 5; i++) {
			const N_line = h<CE_RecapLine>('ap-billing-request-recap-line');
			this.append(N_line);
		}
	}

	public get price() {
		return this.N_sum!.price || 0;
	}

	public get idQuote() {
		return this.getAttribute('id-quote') || '';
	}

	public set value(data: { [key: string]: { [tva: string]: number } }) {
		for (const N_line of this.N_groups) {
			N_line.price = data[N_line.idGroup][N_line.tva];
		}
	}

	public get value() {
		const res: { [idGroup: string]: { [tva: string]: number } } = {};

		for (const N_line of this.N_groups) {
			res[N_line.idGroup] = res[N_line.idGroup] || {};
			res[N_line.idGroup][N_line.tva] = N_line.price;
		}

		return res;
	}

	public set mode(value: string) {
		this.setAttribute('mode', value);
	}

	public get mode() {
		return this.getAttribute('mode')!;
	}

	public setPercentFromOrder(value: number) {
		this.N_sum?.setPercentFromParent(value);

		this.updateChildren();
	}

	public setPriceFromOrder(value: number) {
		this.N_sum?.setPrice(value);

		this.updateChildren();
	}

	public updateChildren() {
		let sum = new DecimalApps(0);

		for (let i = 0; i < this.N_groups.length - 1; i++) {
			const N_line = this.N_groups[i];
			N_line!.setPercentFromParent(this.N_sum!.percent);

			sum = sum.plus(DecimalApps.setDisplayNumber(N_line.price));
		}

		const lastLine = this.N_groups[this.N_groups.length - 1];
		const priceLastLine = DecimalApps.setDisplayNumber(this.N_sum!.price).minus(sum).toNumber();

		lastLine?.setPrice(priceLastLine);
	}

	public setLastPrice() {
		this.N_sum?.setLastPrice();

		for (const N_line of this.N_groups) {
			N_line.setLastPrice();
		}
	}

	public toggleLastPrice(isLast: boolean) {
		this.N_sum?.toggleLastPrice(isLast);

		for (const N_line of this.N_groups) {
			N_line.toggleLastPrice(isLast);
		}
	}

	public static register() {
		customElements.define(RecapQuote.tagName, RecapQuote);
	}
}

export default RecapQuote;
