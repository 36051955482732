import { Module, Modules, Router } from '@autoprog/core-client';

import Navigation from '@libs/Navigation';

import PageUpload from './controllers/FileUpload';
import TemplateUpload from './tpl/fileUpload.html';

class FileUploadModule extends Module {
	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Données',
			order: 0
		});

		navigation.addLink({
			order: 10,
			category: 'Données',
			name: 'Import/Export de données',
			type: 'module',
			module: 'import_export',
			permissions: ['IMPORT', 'EXPORT'],
			icon: 'arrow-left-right/line'
		});
	}

	public async init() {
		const router = Router.getInstance();
		router.addRoute({
			url: '/module/import_export',
			controller: PageUpload,
			template: TemplateUpload,
			permission: 'IMPORT.SHOW || EXPORT.SHOW',
			requireValidUser: true
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('FileUpload', FileUploadModule);
