import { Module, Modules, Router } from '@autoprog/core-client';

import Navigation from '@libs/Navigation';
import NavigationMobile from '@libs/NavigationMobile';

import C_Products from './js/controllers/Products';
import C_UpdateBuyingPriceProducts from './js/controllers/UpdateBuyingPriceProducts';
import C_UpdatePriceProducts from './js/controllers/UpdatePriceProducts';

//CUSTOM ELEMENTS
import CE_ProductImagePreviewer from './js/libs/customElement/ProductImagePreviewer';
import CE_SelectCategorie from './js/libs/customElement/SelectCategorie';
import CE_SelectProductsTabs from './js/libs/customElement/SelectProductTabs';
import CE_SelectProvider from './js/libs/customElement/SelectProvider';

import CE_EditCategories from './js/libs/customElement/EditCategories';
import CE_EditCategoriesReadonly from './js/libs/customElement/EditCategoriesReadonly';
import CE_EditProductProviderReadonly from './js/libs/customElement/EditProductProviderReadonly';
import CE_EditProductStockReadonly from './js/libs/customElement/EditProductStockReadonly';
import CE_EditTags from './js/libs/customElement/EditTags';
import CE_EditTagsReadonly from './js/libs/customElement/EditTagsReadonly';

import CE_MultiInput from './js/libs/customElement/MultiInput';
import CE_SelectProduct from './js/libs/customElement/button-edit-aggrid/SelectProduct';

import CE_DetailProduct from './js/libs/customElement/viewProduct/DetailProduct';
import CE_ProviderProduct from './js/libs/customElement/viewProduct/ProviderProduct';

//TABS
import T_UpdateBuyingPriceProducts from './tpl/UpdateBuyingPriceProducts.html';
import T_UpdatePriceProducts from './tpl/UpdatePriceProducts.html';
import T_tabs from '@tpl/tabs.html';

class ProductsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_ProductImagePreviewer.register();
		CE_SelectProductsTabs.register();
		CE_SelectCategorie.register();

		CE_EditCategories.register();
		CE_EditCategoriesReadonly.register();
		CE_EditProductProviderReadonly.register();
		CE_EditProductStockReadonly.register();
		CE_EditTags.register();
		CE_EditTagsReadonly.register();

		CE_MultiInput.register();

		CE_DetailProduct.register();
		CE_ProviderProduct.register();

		CE_SelectProduct.register();
		CE_SelectProvider.register();
	}

	public async preInit() {
		const page_product = {
			category: 'Données',
			order: 2,
			name: 'Produits',
			type: 'module',
			module: 'apps/products',
			permissions: ['PRODUCTS', 'PRODUCTS._GROUPS', 'PRODUCTS._CATEGORIES', 'PRODUCTS._BRANDS'],
			icon: 'price-tag/line'
		};

		Navigation.getInstance().addCategory({
			name: 'Données',
			order: 0
		});

		Navigation.getInstance().addLink(page_product);

		NavigationMobile.getInstance().addLink({
			icon: 'price-tag/line',
			name: 'Produits',
			order: 1,
			dataset: {
				link: 'products'
			}
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/apps/products',
			controller: C_Products as any,
			tpl: T_tabs,
			requireValidUser: true,
			permission: 'PRODUCTS.SHOW || PRODUCTS._GROUPS.SHOW || PRODUCTS._CATEGORIES.SHOW || PRODUCTS._BRANDS.SHOW'
		});

		router.addRoute({
			url: '/module/apps/products/:tabs',
			controller: C_Products as any,
			tpl: T_tabs,
			requireValidUser: true,
			permission: 'PRODUCTS.SHOW || PRODUCTS._GROUPS.SHOW || PRODUCTS._CATEGORIES.SHOW || PRODUCTS._BRANDS.SHOW'
		});

		router.addRoute({
			url: '/module/apps/updateProducts/price',
			controller: C_UpdatePriceProducts as any,
			tpl: T_UpdatePriceProducts,
			requireValidUser: true,
			permission: 'PRODUCTS.UPDATE_PRICE'
		});

		router.addRoute({
			url: '/module/apps/updateProducts/buyingPrice',
			controller: C_UpdateBuyingPriceProducts as any,
			tpl: T_UpdateBuyingPriceProducts,
			requireValidUser: true,
			permission: 'PRODUCTS.UPDATE_PRICE'
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('Produits', ProductsModule);
