// NODE_MODULE
import { GroupCellRenderer } from '@ag-grid-enterprise/all-modules';

import CE_Aggrid from '@libs/customElement/AgGrid';

// LIBS
import C_Quote from '@modules/Quotes/js/controllers/AddQuotes';
import Settings from '@modules/Settings/js/libs/Settings';

//Services
import AccountsService from '../services/AccountsService';

type Account = {
	forHourlyRate: boolean,
	forProduct: boolean,
	forTaxe: boolean,
	label: string,
	number: string,
	type: string,
	_id: string,
	_rev: string,
};

type DataAccount = {
	key: string,
	label: string,
	name?:string,
	number:string,
	price:string
	quote: string,

};

class ComptaOrderTab extends HTMLElement {
	public static readonly tagName: string = 'ap-compta-order-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;
	private accountsService: AccountsService | null = null;
	private accounts : Array<Account> | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || ComptaOrderTab.tagName;
		this.accountsService = new AccountsService();

		const settingsAccounting = Settings.getInstance().get('accountings');

		if (!settingsAccounting?.enabled) {
			this.classList.add('d-none');
		}

		this.setAttribute('permission', 'ORDERS._CUSTOMERS._COMPTA.SHOW');

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="money-euro-circle/line" icon-active="money-euro-circle/fill" text="Comptabilité"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.removeAttribute('id');
		this.accounts = await this.accountsService.getAll();
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Comptabilité
				<div class="page-tabs-title-right">
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
	}

	private initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Devis',
					field: 'quote',
					rowGroup: true,
					hide: true
				},
				{
					headerName: 'Compte comptable',
					field: 'name',
					width: 250,
					suppressSizeToFit: true
				},
				{
					headerName: 'Libellé écriture compte',
					field: 'label'
				},
				{
					headerName: 'Montant HT',
					field: 'price',
					width: 120,
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true
				}
			],
			suppressContextMenu: true,
			groupRowRendererParams: {
				innerRenderer: (params: any) => {
					const N_div = document.createElement('div');
					N_div.classList.add('d-flex', 'align-items-center');
					N_div.style.gap = '4px';

					let error = false;
					for (const item of params.node.childrenAfterGroup) {
						if (item.data.key === 'AUCUN') {
							error = true;
							break;
						}
					}

					const N_view = document.createElement('ap-button');

					N_view.classList.add('btn-icon', 'btn-edit');
					N_view.setAttribute('confirmation', '');
					N_view.setAttribute('tooltip', 'Editer comptes comptables');
					N_view.innerHTML = '<ap-icon name="edit/line"></ap-icon>';

					N_view.addEventListener('click', async () => {
						C_Quote.open(params.node.childrenAfterGroup[0].data.quote);
					});

					N_div.innerHTML = `${error ? '<i class="icon icon-warning text-danger mr-2"></i>' : ''} ${params.node.childrenAfterGroup[0].data.number}`;

					N_div.append(N_view);

					return N_div;
				}
			},
			getRowClass: (params) => {
				if (params.data && params.data.key === 'AUCUN') {
					return 'text-danger';
				}
				return '';
			},
			groupRowRenderer: GroupCellRenderer,
			groupSelectsChildren: true,
			groupUseEntireRow: true,
			groupDefaultExpanded: 1
		});
	}

	//Permet de rajouter le nom du Compte Compatble pour pouvoir l'afficher.
	private addNameAccount(data: DataAccount[]) {
		for (const oneAccount of data) {
			const accountFound = this.accounts?.find((a) => a._id === oneAccount.key);
			if (oneAccount.key !== 'AUCUN') {
				oneAccount.name = `${accountFound?.number} - ${accountFound?.label}`;
			} else {
				oneAccount.name = oneAccount.key;
			}
		}
		return data;
	}

	public set data(data: any[]) {
		const dataWithNameAccount = this.addNameAccount(data);

		this.N_grid!.value = dataWithNameAccount;
	}

	public get data(): any[] {
		return this.N_grid!.value;
	}

	public static register() {
		customElements.define(ComptaOrderTab.tagName, ComptaOrderTab);
	}
}

export default ComptaOrderTab;
