//NODE_MODULES
import { ColDef, GetContextMenuItemsParams, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import { LoggedUser, Tab } from '@autoprog/core-client';
import _ from 'lodash';
import h from 'hyperscript';
//LIBS
import Alert from '@libs/Alert';
import Utils from '@libs/utils/Utils';

import AgGridStateSaver from '@libs/agGrid/StateSaver';
import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter';
import Select2Filter from '@libs/agGrid/SelectFilter';

import ServiceManager from '@js/managers/ServiceManager';

import M_export from '@libs/modals/ExportDashboard';

// SERVICE
import GenericService from '@services/GenericService';

//CUSTOM-ELEMENTS
import CE_FilterButton, { Config as ConfigFilter } from '@libs/customElement/FilterButton';
import CE_HeaderDashboard, { HeaderDashboardLine } from '@libs/customElement/HeaderDashboard';
import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';
import CE_GridSidebar from '@libs/customElement/GridSidebar';
import CE_SearchBar from '@libs/customElement/searchBar';
import DashboardUser from '@libs/customElement/dashboard/DashboardUser';

export type DashboardOptions = {
	columns: {
		displayed: {
			icons: boolean
			selection: boolean
		}
	},
	actionButtons: {
		displayed: {
			add: boolean
			edit: boolean
			view: boolean
			duplicate: boolean
			delete: boolean
			print: boolean
		}
	}
};

export type DashboardDisabledActionButtons = {
	edit?: boolean,
	view?: boolean,
	duplicate?: boolean,
	delete?: boolean,
	print?: boolean
};

type DashboardConfigColumn = {
	type: 'date' | 'string' | 'boolean' | 'object' | 'number' | 'primaryKey' | 'table'
	name: string
	key: string
	order?: string[]
	object?: { [key: string]: string }
	filter?: 'multiple' | 'list'
	hide?: boolean
};

export type DashboardConfigColumns = DashboardConfigColumn[];

class DashboardController extends Tab {
	protected N_grid: CE_AgGrid | null = null;

	protected el: HTMLElement;
	private title: string;
	private table: string;
	private permissionKey: string;
	private _isLoad: boolean;

	public tableService: GenericService;

	private abortController: AbortController;

	protected stateSaver: AgGridStateSaver | null = null;

	constructor(el: HTMLElement, title: string, permissionKey: string, table: string) {
		super(el);

		this._isLoad = false;
		this.isLoad = false;

		this.abortController = new AbortController();

		this.tableService = ServiceManager.get(table)!.getInstance();

		this.title = title;
		this.table = table;
		this.permissionKey = permissionKey;

		this.el = el;

		this.init();
	}

	private async init() {
		await this.preInit();

		this.initGridOption();
		this.initTitle();
		this.initHeader();

		await this.getData(true);
	}

	protected async preInit() { }

	protected get optionsColumnsGrid(): DashboardOptions {
		return {
			columns: {
				displayed: {
					icons: false,
					selection: false
				}
			},
			actionButtons: {
				displayed: {
					add: true,
					edit: true,
					view: true,
					duplicate: true,
					delete: true,
					print: true
				}
			}
		};
	}

	protected get configColumns(): DashboardConfigColumns {
		return [];
	}

	protected get configFilter(): ConfigFilter {
		return [];
	}

	private set isLoad(value: boolean) {
		if (value) {
			document.body.classList.remove('loading');
		} else {
			document.body.classList.add('loading');
		}

		this._isLoad = value;
	}

	private get isLoad() {
		return this._isLoad;
	}

	private initGridOption() {
		const gridOptions = {
			columnDefs: this.getColumnDefs(),
			isFullWidthCell: () => {
				return !this.isLoad;
			},
			fullWidthCellRenderer: () => {
				return '<div class="cell-loading"></div>';
			},
			defaultColDef: {
				floatingFilter: true,
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true
			},
			getRowStyle: this.getRowStyle.bind(this),
			getContextMenuItems: this.getContextMenu.bind(this),
			onRowDataChanged: this.onDataUpdate.bind(this),
			onFilterChanged: this.onDataUpdate.bind(this),
			onRowSelected: this.onDataUpdate.bind(this),
			getRowHeight: this.getRowHeight.bind(this),
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowSelection: this.optionsColumnsGrid.columns.displayed.selection ? 'multiple' : undefined,
			suppressScrollOnNewData: true
		} as GridOptions;

		const objFilterCreateBy: ColDef = {
			headerName: 'Créé par',
			field: '_createBy_',
			suppressColumnsToolPanel: Utils.userID !== '1',
			hide: Utils.userID !== '1'
		};

		gridOptions.columnDefs?.push(objFilterCreateBy);

		this.N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;

		this.N_grid?.setGridOptions(gridOptions);

		this.N_title.setGridOptions(this.N_grid!.gridOptions);

		const N_GridSidebar = this.el.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.N_grid!.gridOptions);
	}

	private getColumnDefs() {
		const columns: ColDef[] = [];

		for (const item of this.configColumns) {
			const obj: ColDef = {
				headerName: item.name,
				headerTooltip: item.name,
				field: item.key,
				hide: (item.type === 'primaryKey' && this.configColumns.length > 1) || item.hide,
				suppressColumnsToolPanel: item.hide,
				suppressSizeToFit: true
			};

			// Permet d'ignorer les accents
			if (item.type === 'string') {
				obj.filterParams = {
					textFormatter: (result: string) => {
						if (result === null) return null;
						return _.deburr(result.toLowerCase());
					},
					debounceMS: 200
				};
			}

			if (item.type === 'date') {
				obj.filter = 'agNumberColumnFilter';

				obj.floatingFilterComponent = DatePickerRangeFilter;

				obj.comparator = (valueA, valueB) => {
					valueA = Number(valueA || '0');
					valueB = Number(valueB || '0');
					return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
				};
			}

			if (item.type === 'boolean') {
				obj.floatingFilterComponent = Select2Filter;

				obj.cellClass = 'text-right';

				obj.floatingFilterComponentParams = {
					suppressFilterButton: true,
					options: {
						data: [{
							text: 'oui',
							id: 'true'
						}, {
							text: 'non',
							id: 'false'
						}]
					}
				};
			}

			if (item.type === 'object') {
				if (item.filter === 'multiple') {
					obj.filter = 'agSetColumnFilter';

					obj.valueFormatter = (params: any) => {
						return item.object![params.value] || params.value;
					};

					obj.floatingFilterComponentParams = {
						suppressFilterButton: false
					};

					obj.filterParams = {
						values: Object.keys(item.object!),
						valueFormatter: (params: any) => {
							return item.object![params.value] || params.value;
						}
					};
				} else {
					obj.floatingFilterComponent = Select2Filter;

					const data: { [key: string]: string }[] = [];

					if (item.order) {
						for (const key of item.order) {
							data.push({
								id: key,
								text: item.object![key]
							});
						}
					} else {
						for (const key in item.object) {
							data.push({
								id: key,
								text: item.object[key]
							});
						}
					}

					obj.floatingFilterComponentParams = {
						suppressFilterButton: true,
						options: {
							data
						}
					};
				}
			}

			if (item.type === 'number') {
				obj.filter = 'agNumberColumnFilter';

				obj.comparator = (valueA, valueB) => {
					valueA = valueA || '';
					valueB = valueB || '';
					return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
				};

				obj.floatingFilterComponentParams = {
					suppressFilterButton: false
				};

				obj.cellClass = 'ag-text-number';
			}

			if (item.type === 'table' && item.filter === 'list') {
				obj.filter = 'agSetColumnFilter';

				obj.floatingFilterComponentParams = {
					suppressFilterButton: false
				};

				obj.filterParams = {
					applyMiniFilterWhileTyping: true
				};
			}

			columns.push(obj);
		}

		columns.push({
			headerName: '',
			headerClass: 'ag-theme-custom-text-center',
			field: '_id',
			colId: '_id_1',
			filter: false,
			pinned: 'right',
			sortable: false,
			width: 150,
			suppressSizeToFit: true,
			suppressMovable: true,
			suppressColumnsToolPanel: true,
			cellRenderer: this.cellRendererActionCol.bind(this)
		});

		const colIcon: ColDef = {
			headerComponentParams: {
				template: `
					<div class="ag-cell-label-container" role="presentation">
						<div ref="eLabel" class="ag-header-cell-label" role="presentation">
							<ap-icon class="h5" name="more/fill"></ap-icon>
						</div>
					</div>
				`
			},
			headerCheckboxSelectionFilteredOnly: this.optionsColumnsGrid.columns.displayed.selection,
			headerCheckboxSelection: this.optionsColumnsGrid.columns.displayed.selection,
			headerClass: 'ag-theme-custom-text-center',
			field: '_icons_',
			filter: false,
			pinned: 'left',
			hide: !this.optionsColumnsGrid.columns.displayed.icons,
			sortable: false,
			resizable: false,
			width: this.getWidthColActionIcon(),
			suppressSizeToFit: true,
			suppressMovable: true,
			suppressColumnsToolPanel: true,
			cellRenderer: this.cellRendererActionIcons.bind(this)
		};

		if (this.optionsColumnsGrid.columns.displayed.selection) {
			colIcon.checkboxSelection = this.enabledCheckboxSelection.bind(this);
		}

		columns.push(colIcon);

		return columns;
	}

	private cellRendererActionCol(params: any) {
		const N_div = h<HTMLElement>('div.container-action-aggrid');

		const disabledButtons = this.getDisabledActionButtons(params.data);

		if (this.optionsColumnsGrid.actionButtons.displayed.edit) {
			const N_edit = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: `${this.permissionKey}.EDIT`, type: 'edit', tooltip: 'Éditer' } });

			N_edit.disabled = !!disabledButtons?.edit;

			N_edit.addEventListener('click', () => {
				this.buttonEdit(params.data._id.value);
			});

			N_div.appendChild(N_edit);
		}

		if (this.optionsColumnsGrid.actionButtons.displayed.view) {
			const N_seeMore = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: `${this.permissionKey}.OPEN`, type: 'seeMore', tooltip: 'Voir plus' } });

			N_seeMore.disabled = !!disabledButtons?.view;

			N_seeMore.addEventListener('click', () => {
				this.buttonView(params.data._id.value);
			});

			N_div.appendChild(N_seeMore);
		}

		if (this.optionsColumnsGrid.actionButtons.displayed.duplicate) {
			const N_duplicate = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: `${this.permissionKey}.DUPLICATE`, type: 'duplicate', tooltip: 'Dupliquer' } });

			N_duplicate.disabled = !!disabledButtons?.duplicate;

			N_duplicate.addEventListener('click', () => {
				Alert.confirm('', this.getTextConfirmDuplicate(params.node.data), {
					yesType: 'duplicate'
				}).then(() => {
					this.buttonDuplicate(params.data._id.value);
				});
			});

			N_div.appendChild(N_duplicate);
		}

		if (this.optionsColumnsGrid.actionButtons.displayed.delete) {
			const N_delete = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: `${this.permissionKey}.DELETE`, type: 'delete', tooltip: 'Supprimer' } });

			N_delete.disabled = !!disabledButtons?.delete;

			N_delete.addEventListener('click', () => {
				Alert.confirm('', this.getTextConfirmDelete(params.node.data), {
					yesType: 'delete'
				}).then(() => {
					this.buttonDelete(params.data._id.value);
				});
			});

			N_div.appendChild(N_delete);
		}

		if (this.optionsColumnsGrid.actionButtons.displayed.print) {
			const N_print = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { type: 'print', tooltip: 'Imprimer' } });
			N_print.disabled = !!disabledButtons?.print;

			N_print.addEventListener('click', () => {
				this.buttonPrint(params.data._id.value);
			});

			N_div.appendChild(N_print);
		}

		return N_div;
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected enabledCheckboxSelection(params: any) {
		return true;
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected getTextConfirmDelete(data: { [key: string]: any }) {
		return 'Etes-vous sur de vouloir supprimer?';
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected getTextConfirmDuplicate(data: { [key: string]: any }) {
		return 'Etes-vous sur de vouloir dupliquer?';
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected cellRendererActionIcons(params: any): string | HTMLElement {
		return '';
	}

	protected getWidthColActionIcon(): number {
		return 150;
	}

	private getContextMenu(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
		if (!this.isLoad) {
			return [];
		}
		return this._getContextMenu(params);
	}

	protected _getContextMenu(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
		const disabledButtons = this.getDisabledActionButtons(params.node.data);

		const res: MenuItemDef[] = [{
			name: this.getTitleContextMenu(params.node.data),
			disabled: true,
			cssClasses: ['title-context-menu']
		}];

		if (LoggedUser.getInstance().hasPermission('DEBUG.EXPORT_DASHBOARD')) {
			res.push({
				name: 'Exporter',
				icon: '<ap-icon name="download/line"></ap-icon>',
				subMenu: [
					{
						name: 'CSV Export (.csv)',
						action: () => params.api?.exportDataAsCsv()
					},
					{
						name: 'Excel Export (.xlsx)',
						action: () => params.api?.exportDataAsExcel()
					},
					{
						name: 'Excel Export (.xml)',
						action: () => params.api?.exportDataAsExcel({ exportMode: 'xml' })
					}
				]
			});
		}

		if (this.optionsColumnsGrid.actionButtons.displayed.edit) {
			res.push({
				name: 'Éditer',
				disabled: !!disabledButtons?.edit,
				icon: '<ap-icon name="pencil/line"></ap-icon>',
				action: () => {
					this.buttonEdit(params.node.data._id.value);
				}
			});
		}

		if (this.optionsColumnsGrid.actionButtons.displayed.view) {
			res.push({
				name: 'Voir plus',
				disabled: !!disabledButtons?.view,
				icon: '<ap-icon name="eye/line"></ap-icon>',
				action: () => {
					this.buttonView(params.node.data._id.value);
				}
			});
		}

		if (this.optionsColumnsGrid.actionButtons.displayed.print) {
			res.push({
				name: 'Imprimer',
				disabled: !!disabledButtons?.print,
				icon: '<ap-icon name="printer/line"></ap-icon>',
				action: () => {
					this.buttonPrint(params.node.data._id.value);
				}
			});
		}

		return res;
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected getRowHeight(params: any) {
		return 42 + 5 * 2;
	}

	private getRowStyle(params: any): { [key: string]: string } {
		if (!this.isLoad) {
			return {};
		}
		return this._getRowStyle(params);
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected _getRowStyle(params: any): { [key: string]: string } {
		return {};
	}

	private onDataUpdate(params: any) {
		if (this.isLoad) {
			this._onDataUpdate(params);
		}
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected _onDataUpdate(params: any) {

	}

	protected initHeader() {
		const N_add = this.N_header.querySelector('#add') as HTMLButtonElement;
		const N_reload = this.N_header.querySelector('#reload') as HTMLButtonElement;
		const N_export = this.N_header.querySelector('#export') as HTMLButtonElement;
		const N_filter = this.el.querySelector('ap-filter-button') as CE_FilterButton;
		const N_searchBar = this.el.querySelector('ap-search-bar') as CE_SearchBar;

		N_searchBar.setGridOptions(this.N_grid!.gridOptions);

		N_filter.setConfig(this.configFilter, this.N_grid!.gridOptions);

		N_reload.addEventListener('click', async () => {
			Utils.removeTooltip();
			this.getData();
		}, {
			signal: this.abortController.signal
		});

		N_add?.addEventListener('click', () => {
			this.buttonAdd();
		});

		N_export?.addEventListener('click', () => {
			new M_export(this.N_grid!.gridOptions, this.title).open().then(() => {

			}).catch(() => { });
		});

		if (!this.optionsColumnsGrid.actionButtons.displayed.add) {
			N_add?.remove();
		}
	}

	protected get N_header() {
		return this.el.querySelector('#header-grid') as HTMLElement;
	}

	protected get N_title() {
		return document.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
	}

	private initTitle() {
		if (this.N_title) {
			this.N_title.reset();
			this.N_title.setTitle(this.title);
		}
	}

	protected async getData(initStateSaver: boolean = false) {
		const filterModel = this.N_grid?.api?.getFilterModel();

		this.N_title.hideNumber();
		this.N_grid?.api?.setFilterModel(null);
		this.isLoad = false;
		this.stateSaver?.disableNextSave();
		this.N_grid?.resetValue();

		try {
			const { rowData, settings } = await this.tableService.getDataToAgGrid(this.abortController);
			this.isLoad = true;

			this.N_grid!.value = rowData;
			this.N_title.displayNumber();

			if (initStateSaver) {
				this.stateSaver = new AgGridStateSaver(this.N_grid!.gridOptions, this.table);
				this.stateSaver.disableNextSave();
				this.stateSaver.setData(settings);
			} else {
				this.N_grid?.api?.setFilterModel(filterModel);
			}

			return { rowData, settings };
		} catch (e) {
			return {};
		}
	}

	/**
	 * Renvoie un objet exprimant l'état "disabled" de chaque bouton d'action
	 * @param data données pouvant être utilisées pour calculer dynamiquement l'objet
	 * @returns 
	 */
	// eslint-disable-next-line unused-imports/no-unused-vars
	protected getDisabledActionButtons(data: { [key: string]: any }): DashboardDisabledActionButtons {
		return {
			edit: false,
			view: false,
			duplicate: false,
			delete: false,
			print: false
		};
	}

	protected setDataTitle(data: HeaderDashboardLine[]) {
		const N_title = document.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
		N_title.setData(data);
	}

	/**
	 * OVERRIDE METHOD
	 */

	protected buttonAdd() {
		throw new Error('override this method');
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected buttonEdit(id: string) {
		throw new Error('override this method');
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected buttonView(id: string) {
		throw new Error('override this method');
	}

	protected buttonDelete(id: string) {
		this.tableService.delete(id).then(() => {
			this.getData();
		});
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected buttonDuplicate(id: string) {
		throw new Error('override this method');
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected buttonPrint(id: string) {
		throw new Error('override this method');
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected getTitleContextMenu(data: { [key: string]: any }): string {
		return '';
	}

	public destructor(): void {
		this.abortController.abort('destructor');

		if (this.N_grid && this.N_grid.api) {
			this.N_grid.api.destroy();
		}

		DashboardUser.clearCache();

		const N_datepicker = document.querySelectorAll('.daterangepicker');

		for (const N_el of N_datepicker) {
			N_el.remove();
		}

		const N_tooltip = document.querySelectorAll('.tooltip');

		for (const N_el of N_tooltip) {
			N_el.remove();
		}

		document.body.classList.remove('loading');
	}
}

export default DashboardController;
