import axios, { AxiosProgressEvent } from 'axios';
import { LoggedUser } from '@autoprog/core-client';
import _ from 'lodash';

import { UploadedFile } from '../models/UploadedFile';

class UploadService {
	private route = 'upload';

	public async upload(files: Array<UploadedFile>, onSending?: (loaded: number, total: number, percent: number) => void) {
		const formData = new FormData();
		files.forEach(file => {
			formData.append('upload', file.data);
		});

		const user = LoggedUser.getInstance().get('ID');
		await axios.post(`/api/${this.route}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Content-Encoding': 'UTF-8',
				'Import-Filenames': `(${files.map(file => file.filename).join('/')})`,
				'Import-type': `(${_.uniq(files.map(file => file.type)).join('|')})`,
				'Transfer-owner': `${user}`
			},
			//TODO:: typage a confirmer ProgressEvent non accepté
			onUploadProgress: (event: AxiosProgressEvent) => {
				const { loaded, total } = event as unknown as ProgressEvent;
				const percent = parseInt(Math.round((loaded * 100) / total).toString(), 10);
				onSending && onSending(loaded, total, percent);
			}
		});
	}

	public async getFilesToParse(): Promise<Array<File>> {
		const result = await axios.get(`/api/${this.route}`);
		return result.data;
	}

	public async removeFile(fileName: string): Promise<void> {
		return await axios.delete(`/api/${this.route}/${fileName}`);
	}

	public async getSynchronizers(): Promise<Array<{ label: string, table: string }>> {
		const result = await axios.get(`/api/${this.route}/synchronizers`);
		return result.data;
	}

	public async import(tableSelected: { [file: string]: string }) {
		const result = await axios.put(`/api/${this.route}/import`, { tableSelected });
		return result.data;
	}

	public async export(table: string) {
		const result = await axios.get(`/api/${this.route}/export`, { params: { table } });
		return result.data;
	}
}

export default UploadService;
