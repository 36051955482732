import h from 'hyperscript';

import { Controller, LoggedUser, toaster } from '@autoprog/core-client';

import UploadService from '../../services/UploadService';

import { UploadedFile } from '../../models/UploadedFile';

import '../../css/fileUploadInterface.scss';
import ExportUtils from '@js/libs/utils/ExportUtils';
import axios from 'axios';
import moment from 'moment';

//Prendre en compte que les fichiers .CSV , .TXT et .TSV
const FilenameRegex = /^(.*\.((txt|csv|tsv)$))?[^.]*$/;

class UploadFileInterface extends Controller {
	private uploadFilesList: Array<File> = [];
	private serverFilesList: Array<File> = [];
	private synchroniserList: Array<{ label: string, table: string }> = [];
	private N_fileListUpload: HTMLDivElement | undefined;

	private _uploadService: UploadService;
	private isLoading: boolean;

	private element: HTMLElement;

	public constructor(el: HTMLElement) {
		super(el);

		this.element = el;

		this.isLoading = false;
		this._uploadService = new UploadService();
		this.init();
	}

	private async init() {
		await this.updateServerFileList();
		await this.updateSynchroniserList();
		this.initListExport();
		this.initEvent();
		this.requestUpdate();
	}

	private async updateServerFileList(): Promise<void> {
		this.serverFilesList = await this._uploadService.getFilesToParse();
	}

	private async updateSynchroniserList(): Promise<void> {
		this.synchroniserList = await this._uploadService.getSynchronizers();
	}

	private initListExport() {
		const N_select = this.element.querySelector<HTMLSelectElement>('#list-table-export');

		N_select?.append(h('option', { selected: true, disabled: true, attrs: { value: '' } }, 'Sélectionnez une table'));
		for (const item of this.synchroniserList) {
			N_select?.append(h('option', { attrs: { value: item.table } }, item.label));
		}
	}

	private initEvent() {
		const N_uploadArea = this.element.querySelector<HTMLDivElement>('.upload-area');
		const N_inputFile = this.element.querySelector<HTMLInputElement>('#file');
		const N_add = this.element.querySelector<HTMLButtonElement>('#add');
		const N_uploadFileButton = this.element.querySelector<HTMLButtonElement>('#upload-file-button');
		const N_importFileButton = this.element.querySelector<HTMLButtonElement>('#import-file-btn');
		const N_exportFileButton = this.element.querySelector<HTMLButtonElement>('#export-file-btn');

		N_uploadArea?.addEventListener('drop', (event) => {
			this.handleDrop(event);
		});

		N_uploadArea?.addEventListener('dragover', (event) => {
			this.handleDragOver(event);
		});

		N_inputFile?.addEventListener('change', (event) => {
			this.handleInputFileChange(event);
		});

		N_add?.addEventListener('click', () => {
			this.add();
		});

		N_uploadFileButton?.addEventListener('click', () => {
			this.handleUpload();
		});

		N_importFileButton?.addEventListener('click', () => {
			this.handleImport();
		});

		N_exportFileButton?.addEventListener('click', () => {
			this.handleExport();
		});
	}

	private add() {
		const N_inputFile: HTMLInputElement = this.element.querySelector<HTMLInputElement>('.input-file')!;
		N_inputFile.value = '';
		N_inputFile.click();
	}

	private handleInputFileChange(event: Event) {
		if (!this.isLoading) {
			const inputElement = event.target as HTMLInputElement | null;
			const files = inputElement?.files || [];

			for (const file of Array.from(files)) {
				this.displayFileUpload(file);
			}

			this.requestUpdate();
		} else {
			toaster.error('Envoi en cours');
			this.clearFileList();
		}
	}

	private async handleUpload() {
		const user = LoggedUser.getInstance().get('ID');
		const uploaded = this.uploadFilesList.map<UploadedFile>(file => ({ data: file, user, filename: file.name, size: file.size, type: file.type }));

		if (!this.uploadFilesList.length) {
			return toaster.warning('Aucun fichier à envoyer');
		}

		try {
			toaster.warning('Envoi des fichiers');
			await this._uploadService.upload(uploaded, this.onProgress.bind(this));
			this.clearFileList();
			await this.updateServerFileList();
			this.requestUpdate();
		} catch (e) {
			toaster.error('Erreur lors de l\'envoi des fichiers');
			throw new Error((e as Error).message);
		}
	}

	private handleDrop(event: DragEvent) {
		event.stopPropagation();
		event.preventDefault();
		const dataTransfer = event.dataTransfer;

		if (dataTransfer && dataTransfer.files) {
			for (const file of Array.from(dataTransfer.files)) {
				this.displayFileUpload(file);
			}

			this.requestUpdate();
		}
	}

	private handleDragOver(event: DragEvent) {
		event.stopPropagation();
		event.preventDefault();
		event.dataTransfer!.dropEffect = 'copy';
	}

	private displayFileUpload(file: File) {
		const nameList = this.uploadFilesList.map(file => file.name);
		if (nameList.indexOf(file.name) === -1) {
			if (file.name.match(FilenameRegex)) {
				this.uploadFilesList.push(file);

				this.requestUpdate();
			} else {
				toaster.error(`Fichier ${file.name} ne correspond pas aux type CSV|TXT`);
			}
		} else {
			toaster.error(`Fichier ${file.name} en doublon`);
		}
	}

	private removeFile = (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		const fileName = target.parentElement?.querySelector('.name') as HTMLDivElement;
		this.uploadFilesList = this.uploadFilesList.filter(file => file.name.trim() !== fileName.textContent?.trim());
		this.requestUpdate();
	};

	private clearFileList() {
		while (this.N_fileListUpload?.firstChild) {
			this.N_fileListUpload.removeChild(this.N_fileListUpload.firstChild);
		}
		this.uploadFilesList = [];
		this.requestUpdate();
	}

	//Stackoverflow : https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
	public humanFileSize(bytes: number, si: boolean = false, dp: number = 1) {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}

		const units = si
			? ['Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
			: ['Kio', 'Mio', 'Gio', 'Tio', 'Pio', 'Eio', 'Zio', 'Yio'];

		let u = -1;
		const r = 10 ** dp;

		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return bytes.toFixed(dp) + ' ' + units[u];
	}

	private onProgress(_loaded: number, _total: number, percent: number) {
		const N_bar = this.element.querySelector('.progress-bar') as HTMLDivElement;
		this.isLoading = true;
		N_bar.style.width = percent + '%';
		if (percent === 100) {
			this.isLoading = false;
			//Eviter le toaster et leprogress bar réagissent trop vites
			setTimeout(() => {
				N_bar.style.width = '0%';
				toaster.success('Fichiers envoyés');
			}, 1000);
		}
	}

	private async handleImport() {
		if (!this.serverFilesList.length) {
			return toaster.warning('Aucun fichier à importer');
		}
		const tableSelected: { [file: string]: string } = {};
		const selectTable = this.element.querySelectorAll<HTMLSelectElement>('#import-container #upload-file-list .file-item-wrapper select');

		for (const N_select of selectTable) {
			if (N_select.checkValidity() && N_select.dataset.file) {
				tableSelected[N_select.dataset.file] = N_select.value;
			} else {
				toaster.error('Veuillez sélectionner une table pour tous les fichiers');
				return;
			}
		}

		try {
			toaster.warning('Import en cours', '', {
				timeOut: 0,
				extendedTimeOut: 0
			});
			await this._uploadService.import(tableSelected);
			toaster.clear();
			toaster.success('Import terminé');
			await this.updateServerFileList();
			this.requestUpdate();
		} catch (e) {
			toaster.clear();
			console.error(e);
			if (axios.isAxiosError(e)) {
				toaster.error('Erreur lors de l\'import', e.response?.data);
			} else {
				toaster.error('Erreur lors de l\'import');
			}
			throw e;
		}
	}

	private async handleExport() {
		const N_select = this.element.querySelector<HTMLSelectElement>('#list-table-export')!;

		if (!N_select.value) {
			return toaster.warning('Aucune table selectionnée');
		}

		try {
			toaster.warning('Export en cours', '', {
				timeOut: 0,
				extendedTimeOut: 0
			});
			const data = await this._uploadService.export(N_select.value);
			const synchronizer = this.synchroniserList.find((item) => item.table === N_select.value);
			ExportUtils.exportFile(data, moment().format('YYYY_MM_DD') + '_' + synchronizer?.label + '.csv');
			toaster.clear();
			toaster.success('Export terminé');
			this.requestUpdate();
		} catch (e) {
			console.error(e);
			toaster.clear();
			toaster.error('Erreur lors de l\'import');
			throw e;
		}
	}

	private removeServeFile = async (event: MouseEvent) => {
		const target = event.target as HTMLElement;
		const fileName = target.parentElement?.querySelector('.name') as HTMLDivElement;
		try {
			if (fileName.textContent) {
				await this._uploadService.removeFile(fileName.textContent);
			}

			toaster.success(`Fichier ${fileName.textContent} supprimé`);
		} catch (error) {
			toaster.error('Echec de l\'opération');
		} finally {
			this.serverFilesList = await this._uploadService.getFilesToParse();
			this.requestUpdate();
		}
	};

	protected uploadFileListRenderer() {
		const N_container = this.element.querySelector<HTMLDivElement>('#upload-file-list')!;
		this.uploadFilesList.forEach((file: File) => N_container.append(this.fileRenderer(file, this.removeFile.bind(this), false)));
	}

	protected uploadResumeRenderer() {
		const N_container = this.element.querySelector<HTMLDivElement>('#validation-upload .files-number')!;
		N_container.innerHTML = `${this.uploadFilesList.length > 0 ? `${this.uploadFilesList.length} fichier(s) sélectionné(s)` : 'Aucun fichier(s) sélectionné(s)'}`;
	}

	/* ------------------------------ render import ----------------------------- */

	protected importRenderer() {
		const N_container = this.element.querySelector<HTMLDivElement>('#import-container #upload-file-list')!;
		N_container.innerHTML = `<p class="files-number" >${this.serverFilesList.length > 1 ? 'Fichiers' : 'Fichier'} à importer</p>`;
		this.serverFilesList.forEach((file: File) => N_container.append(this.fileRenderer(file, this.removeServeFile, true)));
	}

	/* ------------------------------ render commun ----------------------------- */
	protected fileRenderer(file: File, removeFunction: (event: MouseEvent) => void, isImport: boolean) {
		const N_close = h('div.close', { attrs: { permission: 'IMPORT.DELETE_FILE' } }, { innerHTML: '&#10006;' });

		const options: HTMLOptionElement[] = [];
		options.push(h('option', { selected: true, disabled: true, attrs: { value: '' } }, 'Sélectionnez une table'));
		for (const item of this.synchroniserList) {
			options.push(h('option', { attrs: { value: item.table } }, item.label));
		}

		const N_div = h('div.file-item-wrapper',
			h('div.name', file.name),
			h('span', this.humanFileSize(file.size, true, 2)),
			isImport ? h('select', { attrs: { required: true, 'data-file': file.name } }, ...options) : h('div'),
			N_close
		);

		N_close.addEventListener('click', (event) => {
			removeFunction(event as MouseEvent);
		});

		return N_div;
	}

	private requestUpdate() {
		const N_container = this.element.querySelector<HTMLDivElement>('#upload-file-list')!;
		N_container.innerHTML = '';
		this.importRenderer();
		this.uploadFileListRenderer();
		this.uploadResumeRenderer();
	}

	public destructor() {

	}
}

export default UploadFileInterface;
