import { MenuItemDef } from '@ag-grid-enterprise/all-modules';
import h from 'hyperscript';
import { toaster, utils } from '@autoprog/core-client';

import M_EditBillingRequest from '@modules/OrdersCustomer/js/modals/EditBillingRequest';
import M_PrintPreview from '@libs/modals/PrintPreview';

import Decimal from '@libs/utils/Decimal';

import C_BillsCustomer from '../../js/controllers/Bills.Customer';

import CE_Aggrid from '@libs/customElement/AgGrid';
import CE_PageTabsMenu from '@js/libs/customElement/PageControllerID/PageTabsMenu';
import CE_PageTabsMenuItem from '@js/libs/customElement/PageControllerID/PageTabsMenuItem';

import PriceWithPercentModel from '@libs/model/_app/PriceWithPercent';

import S_C_Bill from '@services/Customer/CustomerBillService';
import S_C_BillingRequest from '@services/Customer/CustomerBillingRequestService';

export type updateFormEvent = {
	priceHT: Decimal
	priceCredit: Decimal,
	notPaymentPrice: string
	paymentPrice: string
};

class BillsCustomerTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-bills-customers-tab';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private selectorTab: string = '';

	private idTab: string = '';

	private billingRequests: any | null = null;
	private errorCompta: boolean = false;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || BillsCustomerTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="bill-euro/line" icon-active="bill-euro/fill" text="Factures" hasNumber></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Factures
				<div class="page-tabs-title-right">
					<ap-button class="d-none cell-loading" id="view-billing-requests" icon="bill/line" type="action">Voir les demandes de facturation <span id="billing-request-count">(0)</span></ap-button>
					<ap-button class="d-none cell-loading" id="add-bill" permission="BILLS._CUSTOMERS.ADD" type="add" disabled></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
		this.initButtons();
	}

	private async initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucune Facture' },
			defaultColDef: {
				suppressMenu: true,
				sortable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Index',
					field: 'infos.index',
					width: 100,
					sort: 'asc'
				}, {
					headerName: 'Numéro',
					field: 'infos.number'
				}, {
					headerName: 'Type',
					field: 'infos.type'
				}, {
					headerName: 'Date',
					field: 'infos.date'
				}, {
					headerName: 'Montant HT (€)',
					field: 'price',
					cellClass: 'text-right  text-monospace'
				}, {
					headerName: 'Montant TTC (€)',
					field: 'priceTTC',
					cellClass: 'text-right text-monospace'
				}, {
					headerName: 'Montant à payer (€)',
					field: 'notPaymentPrice',
					cellClass: 'text-right  text-monospace'
				}, {
					headerName: 'Montant payé (€)',
					field: 'paymentPrice',
					cellClass: 'text-right text-monospace'
				}, {
					headerName: '',
					pinned: 'right',
					width: 80,
					editable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						}

						const N_edit = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit', tooltip: 'Editer', permission: 'BILLS._CUSTOMERS.EDIT' } });

						N_edit.addEventListener('click', () => {
							C_BillsCustomer.open(params.data._id.value);
						});

						const N_print = h('ap-button.btn-action-aggrid', { attrs: { type: 'print', tooltip: 'Impression' } });

						N_print.addEventListener('click', () => {
							new M_PrintPreview('bills', params.data._id.value).open();
						});

						const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit, N_print);

						return N_div;
					}

				}
			],
			getContextMenuItems: (params) => {
				if (params.node) {
					const result: (MenuItemDef | string)[] = [
						{
							name: `N° ${params.node.data.infos.number.value}`,
							disabled: true,
							cssClasses: ['title-context-menu']
						}, {
							name: 'Éditer',
							icon: '<ap-icon name="edit/line"></ap-icon>',
							disabled: !params.node,
							action: () => {
								C_BillsCustomer.open(params.node.data._id.value);
							}
						}, {
							name: 'Imprimer',
							icon: '<i class="icon icon-printer-outiline "></i>',
							action: () => {
								new M_PrintPreview('bills', params.node.data._id.value).open();
							}
						}
					];

					result.push({
						name: 'Créer un avoir',
						icon: '<ap-icon name="bill/line"></ap-icon>',
						disabled: params.node.data.isCredit.value,
						action: () => {
							new M_EditBillingRequest(this.idOrder, params.node.data._id.value).setMode('create-credit').open();
						}
					});

					return result;
				} else {
					return [];
				}
			},
			onRowDataChanged: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});
	}

	private refreshButtonsDisplay() {
		const N_create = this.N_el!.querySelector('#add-bill') as HTMLButtonElement;
		const N_viewBillingRequests = this.N_el!.querySelector('#view-billing-requests') as HTMLButtonElement;
		const N_viewBillingRequestsCount = N_viewBillingRequests.querySelector('#billing-request-count') as HTMLSpanElement;

		N_create.classList.toggle('d-none', this.billingRequests?.length);
		N_viewBillingRequests.classList.toggle('d-none', !this.billingRequests?.length);
		N_viewBillingRequestsCount.innerHTML = '(' + (this.billingRequests?.length ?? 0) + ')';
	}

	private initButtons() {
		const N_create = this.N_el!.querySelector('#add-bill') as HTMLButtonElement;

		N_create.addEventListener('click', () => {
			if (this.errorCompta) {
				toaster.error('Compte comptable manquant');
			} else {
				new M_EditBillingRequest(this.idOrder).setMode('create-bill').open().then((data) => {
					if (data === 'error') {
						toaster.error('Compte comptable manquant');
					}
				});
			}
		});

		const N_viewBillingRequests = this.N_el!.querySelector('#view-billing-requests') as HTMLButtonElement;

		N_viewBillingRequests.addEventListener('click', () => {
			const N_PageTabsMenu = this.parentElement as CE_PageTabsMenu;
			const N_BillingRequestTabMenuItem = this.parentElement?.querySelector<CE_PageTabsMenuItem>('ap-billing-request-tab ap-page-tabs-menu-item');

			if (N_BillingRequestTabMenuItem) {
				N_PageTabsMenu.setActive(N_BillingRequestTabMenuItem.href);
			}
		});
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector<HTMLElement>('#number')!;

		if (this.N_grid!.isLoad) {
			if (number) {
				N_number.innerHTML = number.toString();
				N_number.classList.remove('d-none');
			} else {
				N_number.classList.add('d-none');
			}
		} else {
			N_number.classList.add('d-none');
		}
	}

	public disabledCreateButton(value: boolean) {
		const N_create = this.N_el!.querySelector('#add-bill') as HTMLButtonElement;
		N_create.disabled = value;
	}

	private updatePinnedRowData() {
		const result = {
			price: new Decimal(0),
			priceCredit: new Decimal(0),
			priceTTC: new Decimal(0),
			notPaymentPrice: new Decimal(0),
			paymentPrice: new Decimal(0)
		};

		this.N_grid!.forEachNode((node) => {
			const price = Decimal.setDisplayNumber(node.data.price.value);
			const priceTTC = Decimal.setDisplayNumber(node.data.priceTTC.value);
			const notPaymentPrice = Decimal.setDisplayNumber(node.data.notPaymentPrice.value);
			const paymentPrice = Decimal.setDisplayNumber(node.data.paymentPrice.value);

			if (node.data.infos.type.value === 'credit') {
				result.priceCredit = result.priceCredit.plus(price);
			}

			result.price = result.price.plus(price);
			result.priceTTC = result.priceTTC.plus(priceTTC);
			result.notPaymentPrice = result.notPaymentPrice.plus(notPaymentPrice);
			result.paymentPrice = result.paymentPrice.plus(paymentPrice);
		});

		result.price = result.price.toDecimalPlaces(2);
		result.priceTTC = result.priceTTC.toDecimalPlaces(2);
		result.notPaymentPrice = result.notPaymentPrice.toDecimalPlaces(2);
		result.paymentPrice = result.paymentPrice.toDecimalPlaces(2);

		this.N_grid!.pinnedBottomValue = [{
			price: { value: result.price.toNumber(), formattedValue: result.price.setSuffixAndHumanizeNumber('€') },
			priceTTC: { value: result.priceTTC.toNumber(), formattedValue: result.priceTTC.setSuffixAndHumanizeNumber('€') },
			notPaymentPrice: PriceWithPercentModel.calculAndConvertToModel(result.notPaymentPrice, result.priceTTC).toDashboard(),
			paymentPrice: PriceWithPercentModel.calculAndConvertToModel(result.paymentPrice, result.priceTTC).toDashboard()
		}];

		this.dispatchEvent(new CustomEvent<updateFormEvent>('update.form', {
			detail: {
				priceHT: result.price,
				priceCredit: result.priceCredit,
				notPaymentPrice: PriceWithPercentModel.calculAndConvertToModel(result.notPaymentPrice, result.priceTTC).getText(),
				paymentPrice: PriceWithPercentModel.calculAndConvertToModel(result.paymentPrice, result.priceTTC).getText()
			}
		}));
	}

	public get sum() {
		let sum = new Decimal(0);

		this.N_grid!.forEachNode((node) => {
			const price = Decimal.setDisplayNumber(node.data.price.value);
			sum = sum.plus(price);
		});

		return sum;
	}

	private get idOrder() {
		return utils.getQuery().id;
	}

	public async initData(errorCompta:boolean) {
		const N_containerButton = this.N_el?.querySelector<HTMLElement>('.page-tabs-title-right');
		this.errorCompta = errorCompta;

		N_containerButton?.classList.add('loading');

		if (this.idOrder) {
			const [bills, billingRequests] = await Promise.all([
				S_C_Bill.getInstance().getByOrder(this.idOrder),
				S_C_BillingRequest.getInstance().getByOrder(this.idOrder)
			]);

			this.billingRequests = billingRequests.filter((item: any) => item.state.value === 'waiting');

			this.dispatchEvent(new CustomEvent('load', { detail: { number: bills.length } }));

			this.N_grid!.value = bills;
		} else {
			this.N_grid!.value = [];
		}

		this.updatePinnedRowData();
		this.refreshButtonsDisplay();

		N_containerButton?.classList.remove('loading');
	}

	public get bills() {
		return this.N_grid!.value;
	}

	public static register() {
		customElements.define(BillsCustomerTab.tagName, BillsCustomerTab);
	}

	public get isLoad() {
		return this.N_grid?.isLoad;
	}
}

export default BillsCustomerTab;
