import round from './libsV1_V2/round';
import roundUp from './libsV1_V2/roundUp';

import QuoteData from '../QuoteData';

import calculatePUGroup from './libsV1_V2/calculatePUGroup';
import calculateSumGroup from './libsV1_V2/calculateSumGroup';
import humanizeNumber from './libsV1_V2/humanizeNumber';
import verifSum from './libsV1_V2/verifSum';

class CalculV2 {
	private constructor() { }

	public static calculatePrice(item: { [key: string]: any }, pref: { [key: string]: any }) {
		pref.price = pref.price || {};
		pref.marges = pref.marges || {};

		pref.coefTotal = pref.coefTotal || '1';

		pref.coefTotal = pref.coefTotal.toString().replace(',', '.');

		const result = {
			price: 0,
			sumMat: 0,
			sumMO: 0,
			sum: 0
		};

		if (item.quantity && !item.disabled) {
			const type = item.type || pref.type || '';

			if (item.time && pref.price[type]) {
				item.time = item.time.toString().replace(',', '.');

				let tmp = parseFloat(item.time) * pref.price[type];

				if (!item.notApplyCoefOnMO) {
					tmp *= pref.coefTotal;
				}

				result.sumMO = tmp * item.quantity;
			}

			if (item.price) {
				let tmp = 0;
				let marge = 0;

				const typeMat = (item.typeMat || pref.typeMat || '').trim();
				item.price = item.price.toString().replace(',', '.');

				// Si on applique une marge commerciale
				if (pref.calculMarkUp) {
					// Marge commerciale = 1 - (taux marge / 100)
					marge = 1 - ((pref.marges[typeMat] || 0) / 100);

					// Caclul prix avec marge commerciale = Prix / Marge commerciale = prix / ( 1 - (taux marge / 100))
					tmp = parseFloat(item.price) / marge;
				} else { // sinon on applique un coef simple
					marge = (pref.marges[typeMat] || 0) / 100 + 1;
					tmp = parseFloat(item.price) * marge;
				}

				if (typeMat === QuoteData.DISCOUNT) {
					result.sumMat = tmp * item.quantity * -1;
				} else {
					if (!item.notApplyCoefOnMat) {
						tmp *= pref.coefTotal;
					}

					result.sumMat = tmp * item.quantity;
				}
			}
		}

		result.sumMat = round(result.sumMat, {
			decimalNumber: 2
		});

		result.sumMO = round(result.sumMO, {
			decimalNumber: 2
		});

		result.sum = round(result.sumMO + result.sumMat, pref);

		result.price = round(result.sum / item.quantity, {
			decimalNumber: 2
		});

		return verifSum(result, pref as { decimalNumber: number });
	}

	public static calculateGlobalPrice(grid: any[], pref: { [key: string]: any } = {}) {
		const data = {
			all: 0,
			option: 0,
			notOption: 0
		};

		for (const group of grid) {
			let price = 0;

			if (!group.disabled) {
				for (const item of group.details) {
					price += this.calculatePrice(item, pref).sum;
				}
			}

			const tmp = (price * (group.quantity || 0));

			data.all += tmp;

			if (group.isOption) {
				data.option += tmp;
			} else {
				data.notOption += tmp;
			}
		}

		return {
			all: roundUp(data.all, pref),
			option: roundUp(data.option, pref),
			notOption: roundUp(data.notOption, pref)
		};
	}

	public static humanizeNumber(number: string, pref: { [key: string]: any }, ignoreRound = false) {
		return humanizeNumber(number, pref, ignoreRound);
	}

	public static calculatePUGroup(data: any) {
		return calculatePUGroup(data, this);
	}

	public static calculateSumGroup(data: any) {
		return calculateSumGroup(data, this);
	}
}

export default CalculV2;
