import h from 'hyperscript';

import '../../css/priceBillProvider.scss';

import CE_Select from '@libs/customElement/Select';

import CalculTaxe from '@modules/Comptabilité/js/libs/CalculTaxe';
import S_Taxes from '@modules/Comptabilité/js/services/TaxesService';

import DecimalApps from '@js/libs/utils/Decimal';

class PriceBillProvider extends HTMLElement {
	public static readonly tagName: string = 'ap-price-bill-provider';

	private notBill: { [key: string]: any } = {};

	private N_InputNotbillPriceHT: HTMLInputElement | null = null;
	private N_InputNotbillPriceTTC: HTMLInputElement | null = null;

	private _mode: string = 'create-bill';

	public connectedCallback() {
		this.innerHTML = `

			<form>
				<label>Montant HT</label>

				<div class="line line-header cell-loading" id="priceHT-container">
					<div class="col-left"></div>
					<div class="col-center">
						<div></div>
						<ap-input-addon type="number" suffix="€" id="priceHT" required></ap-input-addon>
						<ap-button id="notbillPriceHT" class="d-none" type="action">Restant</ap-button>
					</div>
					<div class="col-right"></div>
				</div>

				<div class="tva-block-header">
					<label>TVA</label>
					<ap-button type="add" id="add"></ap-button>
				</div>	

				<div class="tva-block">
					<div class="line cell-loading">
						<div class="col-left">&nbsp;</div>
						<div class="col-center">&nbsp;</div>
						<div class="col-right">&nbsp;</div>
					</div>
				</div>

				<label>Montant TTC</label>
				<div class="line line-header cell-loading" id="priceTTC-container">
					<div class="col-left"></div>
					<div class="col-center">
						<div></div>
						<ap-input-addon type="number" suffix="€" id="priceTTC" required></ap-input-addon>
						<ap-button id="notbillPriceTTC" type="action">Restant</ap-button>
					</div>
					<div class="col-right"></div>
				</div>
			</form>
		`;

		this.N_InputNotbillPriceHT = this.querySelector<HTMLInputElement>('#priceHT input')!;
		this.N_InputNotbillPriceTTC = this.querySelector<HTMLInputElement>('#priceTTC input')!;

		const N_notbillPriceHT = this.querySelector<HTMLButtonElement>('#notbillPriceHT')!;

		N_notbillPriceHT?.addEventListener('click', () => {
			this.N_InputNotbillPriceHT!.valueAsNumber = this.notBill.priceHT as number;
			this.updateByHT();
		});

		const N_notbillPriceTTC = this.querySelector<HTMLButtonElement>('#notbillPriceTTC')!;

		N_notbillPriceTTC?.addEventListener('click', () => {
			this.N_InputNotbillPriceTTC!.valueAsNumber = this.notBill.priceTTC as number;
			this.updateByTTC();
		});

		this.initAdd();
		this.initInput();
	}

	private initAdd() {
		const N_add = this.querySelector<HTMLElement>('#add');

		N_add?.addEventListener('click', () => {
			this.addTvaLine();
			this.dispatchEvent(new CustomEvent('update'));
		});
	}

	private initInput() {
		const N_priceTTC = this.querySelector<HTMLInputElement>('#priceTTC input')!;

		this.N_InputNotbillPriceHT!.addEventListener('input', async () => {
			this.updateByHT();
		});

		N_priceTTC.addEventListener('input', async () => {
			this.updateByTTC();
		});
	}

	private async updateByHT(updateTVA: boolean = true) {
		const N_tvaContainer = this.querySelectorAll<HTMLElement>('.tva-container');

		const HTprice = DecimalApps.setDisplayNumber(this.N_InputNotbillPriceHT!.valueAsNumber);

		let sum = HTprice;

		for (const N_el of N_tvaContainer) {
			const N_select = N_el.querySelector<CE_Select>('ap-select')!;
			const N_input = N_el.querySelector<HTMLInputElement>('input')!;

			if (updateTVA) {
				const tva = new CalculTaxe(N_select.value as string);
				await tva.init();

				N_input.valueAsNumber = tva.calculatePriceTVAByHT(HTprice).toNumber();
			}

			sum = sum.plus(DecimalApps.setDisplayNumber(N_input.valueAsNumber));
		}

		this.N_InputNotbillPriceTTC!.valueAsNumber = sum.toDecimalPlaces(2).toNumber();

		this.dispatchEvent(new CustomEvent('update'));
	}

	private async updateByTTC() {
		const N_tvaContainer = this.querySelectorAll<HTMLElement>('.tva-container');

		const TTCprice = DecimalApps.setDisplayNumber(this.N_InputNotbillPriceTTC!.valueAsNumber);

		let sum = TTCprice;

		for (const N_el of N_tvaContainer) {
			const N_select = N_el.querySelector<CE_Select>('ap-select')!;
			const N_input = N_el.querySelector<HTMLInputElement>('input')!;

			const tva = new CalculTaxe(N_select.value as string);
			await tva.init();

			N_input.valueAsNumber = tva.calculatePriceTVAByTTC(TTCprice).toNumber();

			sum = sum.minus(DecimalApps.setDisplayNumber(N_input.valueAsNumber));
		}

		this.N_InputNotbillPriceHT!.valueAsNumber = sum.toNumber();

		this.dispatchEvent(new CustomEvent('update'));
	}

	/**
	 * Ajoute une ligne de TVA
	 * @param autoCreate Indique si la ligne est auto généré à partir de la commande
	 * @returns 
	 */
	public addTvaLine(autoCreate: boolean = false) {
		const N_tvaBlock = this.querySelector<HTMLElement>('.tva-block');

		const N_line = h<HTMLElement>('div.line');

		if (autoCreate) {
			N_line.innerHTML = `
				<div class="col-left"></div>
				<div class="tva-container col-center">
					<ap-select required disabled></ap-select>
					<ap-input-addon type="number" suffix="€" required></ap-input-addon>
					<ap-button id="notbill" type="action">Restant</ap-button>
				</div>
				<div class="col-right"></div>
			`;
		} else {
			N_line.innerHTML = `
				<div class="col-left">À facturer : non renseigné </div>
				<div class="tva-container col-center">
					<ap-select required></ap-select>
					<ap-input-addon type="number" suffix="€" required></ap-input-addon>
					<ap-button id="notbill" type="action" disabled>Restant</ap-button>
				</div>
				<div class="col-right"><ap-button class="btn-icon delete-tva" type="delete" icon="close/line" tooltip="Supprimer"></ap-button></div>
			`;
		}

		N_tvaBlock?.append(N_line);

		const N_select = N_line.querySelector<CE_Select>('ap-select')!;
		const N_notbill = N_line.querySelector<HTMLButtonElement>('#notbill')!;

		N_select.options = {
			ajax: {
				url: new S_Taxes().createSelect2URL(),
				getParams: (search) => {
					return {
						search,
						refData: JSON.stringify({
							type: 'purchase'
						})
					};
				}
			}
		};

		const N_inputLine = N_line.querySelector<HTMLInputElement>('ap-input-addon input')!;

		N_select.addEventListener('change', async () => {
			this.updateByHT();
		});

		N_inputLine.addEventListener('input', () => {
			this.updateByHT(false);
		});

		N_notbill?.addEventListener('click', () => {
			N_inputLine.valueAsNumber = this.notBill.tva[N_select.value as string];
			this.updateByHT(false);
		});

		if (!autoCreate) {
			const N_deleteTva = N_line.querySelector<HTMLButtonElement>('.delete-tva')!;
			N_deleteTva?.addEventListener('click', () => {
				N_line.remove();
				this.updateByHT(false);
			});
		}

		return N_line;
	}

	public async init(data: { [key: string]: any }, billTVA?: any) {
		this.notBill = data;

		let priceTTC = DecimalApps.setDisplayNumber(data.priceHT);

		this.querySelector<HTMLElement>('.tva-block')!.innerHTML = '';

		for (const IDTVA in billTVA ?? data.tva) {
			const N_line = this.addTvaLine(!!data.tva[IDTVA]);
			const N_select = N_line.querySelector<CE_Select>('ap-select')!;
			const N_notBill = N_line.querySelector<HTMLElement>('.col-left')!;
			N_select.value = await new S_Taxes().getDataToSelect2ByID(IDTVA);
			N_select.disabled = true;

			N_line.dataset.tva = IDTVA;

			const priceTVA = DecimalApps.setDisplayNumber(data.tva[IDTVA]);

			if (this._mode === 'create-bill') {
				N_notBill.innerHTML = `À facturer : ${priceTVA.setSuffixAndHumanizeNumber('€')}`;
			}

			if (this._mode === 'create-credit') {
				N_notBill.innerHTML = `Montant facture : ${priceTVA.setSuffixAndHumanizeNumber('€')}`;
			}

			priceTTC = priceTTC.plus(priceTVA);
		}

		this.notBill.priceTTC = priceTTC.toNumber();

		const N_priceHTContainer = this.querySelector<HTMLElement>('#priceHT-container .col-left')!;
		const N_priceTTCContainer = this.querySelector<HTMLElement>('#priceTTC-container .col-left')!;

		if (this._mode === 'create-bill') {
			N_priceHTContainer.innerHTML = `À facturer : ${DecimalApps.setDisplayNumber(data.priceHT).setSuffixAndHumanizeNumber('€')}`;
			N_priceTTCContainer.innerHTML = `À facturer : ${priceTTC.setSuffixAndHumanizeNumber('€')}`;
		}

		if (this._mode === 'create-credit') {
			N_priceHTContainer.innerHTML = `Montant facture : ${DecimalApps.setDisplayNumber(data.priceHT).setSuffixAndHumanizeNumber('€')}`;
			N_priceTTCContainer.innerHTML = `Montant facture : ${priceTTC.setSuffixAndHumanizeNumber('€')}`;
		}
	}

	public setLastPrice(isLast: boolean = false) {
		const N_inputNotbillPriceTTC = this.querySelector<HTMLInputElement>('#priceTTC input')!;
		const N_notbillPriceHT = this.querySelector<HTMLButtonElement>('#notbillPriceHT')!;

		this.N_InputNotbillPriceHT!.valueAsNumber = this.notBill.priceHT;
		N_notbillPriceHT.classList.toggle('d-none', !isLast);

		for (const tva in this.notBill.tva) {
			const N_input = this.querySelector<HTMLInputElement>(`[data-tva="${tva}"] ap-input-addon input`);
			N_input!.valueAsNumber = this.notBill.tva[tva];
		}

		N_inputNotbillPriceTTC.valueAsNumber = this.notBill.priceTTC;
	}

	public get data() {
		const res = {
			priceHT: 0,
			tva: {} as { [key: string]: number }
		};

		res.priceHT = this.N_InputNotbillPriceHT!.valueAsNumber;

		const N_listLineTVA = this.querySelectorAll<HTMLElement>('.tva-container');

		for (const N_line of N_listLineTVA) {
			const N_input = N_line.querySelector<HTMLInputElement>('input')!;
			const N_select = N_line.querySelector<CE_Select>('ap-select')!;
			if (N_select.value) {
				res.tva[N_select.value as string] = N_input.valueAsNumber || 0;
			}
		}

		return res;
	}

	public set data(value: { [key: string]: any }) {
		let priceTTC = DecimalApps.setDisplayNumber(value.priceHT);

		this.N_InputNotbillPriceHT!.valueAsNumber = DecimalApps.setDisplayNumber(value.priceHT).toNumber();

		const N_listLineTVA = this.querySelectorAll<HTMLElement>('.tva-container');

		for (const N_line of N_listLineTVA) {
			const N_input = N_line.querySelector<HTMLInputElement>('input')!;
			const N_select = N_line.querySelector<CE_Select>('ap-select')!;
			const tva = DecimalApps.setDisplayNumber(value.tva ? value.tva[N_select.value as string] : 0);
			N_input.valueAsNumber = tva.toNumber();
			priceTTC = priceTTC.plus(tva);
		}

		this.N_InputNotbillPriceTTC!.valueAsNumber = priceTTC.toNumber();
	}

	public checkError() {
		let hasError = false;

		if (isNaN(this.N_InputNotbillPriceHT!.valueAsNumber)) {
			hasError = true;
			this.N_InputNotbillPriceHT?.parentElement?.classList.add('error');
		} else {
			this.N_InputNotbillPriceHT?.parentElement?.classList.remove('error');
		}

		const N_listLineTVA = this.querySelectorAll<HTMLElement>('.tva-container');

		for (const N_line of N_listLineTVA) {
			const N_input = N_line.querySelector<HTMLInputElement>('input')!;
			if (isNaN(N_input.valueAsNumber)) {
				hasError = true;
				N_input?.parentElement?.classList.add('error');
			} else {
				N_input?.parentElement?.classList.remove('error');
			}
		}

		if (isNaN(this.N_InputNotbillPriceTTC!.valueAsNumber)) {
			hasError = true;
			this.N_InputNotbillPriceTTC?.parentElement?.classList.add('error');
		} else {
			this.N_InputNotbillPriceTTC?.parentElement?.classList.remove('error');
		}

		return hasError;
	}

	public set mode(value: string) {
		this._mode = value;
	}

	public get priceNotBill() {
		return this.notBill.priceHT;
	}

	public get price() {
		return this.N_InputNotbillPriceHT?.value;
	}

	public static register() {
		customElements.define(PriceBillProvider.tagName, PriceBillProvider);
	}
}

export default PriceBillProvider;
