import Utilsbills from '../libs/Utilsbills';

import Decimal from '@libs/utils/Decimal';

import S_C_Bills from '@services/Customer/CustomerBillService';

import CE_Aggrid from '@libs/customElement/AgGrid';
import Settings from '@modules/Settings/js/libs/Settings';
import { toaster } from '@autoprog/core-client';

class ComptaBillTab extends HTMLElement {
	public static readonly tagName: string = 'ap-compta-bill-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private N_grid: CE_Aggrid | null = null;

	private _infosBill: { [key: string]: any } = {};

	private _comptaDetailsCredit: { [key: string]: any } = {};

	private getFormData: () => any = () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || ComptaBillTab.tagName;

		const settingsAccounting = Settings.getInstance().get('accountings');

		if (!settingsAccounting?.enabled) {
			this.classList.add('d-none');
		}

		this.setAttribute('permission', 'BILLS._CUSTOMERS._COMPTA.SHOW');

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-list-3/line" icon-active="file-list-3/fill" text="Comptabilité"></ap-page-tabs-menu-item>`;

		this.removeAttribute('id');
	}

	public postInit() {
		this.update();
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.N_grid?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('page-tabs-container', 'tab-pane');
		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="page-tabs-title">

				Export Comptable

				<div class="page-tabs-title-right">
					
					<ap-button type="export" id="export" permission="BILLS._CUSTOMERS._COMPTA.EXPORT"></ap-button>

					<ap-button class="btn-icon" type="reload" id="update"></ap-button>

					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>

				</div>

			</div>
			<div class="alert alert-danger d-none" id="alert"></div>				
			<div class="flex-grow-1 w-100">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		this.N_grid = N_div.querySelector<CE_Aggrid>('#grid');

		const N_update = N_div.querySelector('#update') as HTMLButtonElement;

		N_update.addEventListener('click', async () => {
			const data = await S_C_Bills.getInstance().getComptaData(this.getFormData());
			this.N_grid!.value = data;
		});

		const N_export = N_div.querySelector('#export') as HTMLButtonElement;

		N_export.addEventListener('click', () => {
			if (this._infosBill.number) {
				Utilsbills.exportCompta(this.N_grid!.value, this._infosBill.number);
			} else {
				toaster.error('Veuillez sauvegarder la facture pour pouvoir exporter.');
			}
		});

		N_container.append(N_div);

		this.initGrid();
	}

	private initGrid() {
		this.N_grid!.setGridOptions({
			columnDefs: [
				{
					headerName: 'Journal',
					field: 'log'
				},
				{
					headerName: 'Date de pièce',
					field: 'date'
				},
				{
					headerName: 'Compte',
					field: 'compta'
				},
				{
					headerName: 'Libellé compte',
					field: 'customer'
				},
				{
					headerName: 'N° de pièces (Facture)',
					field: 'bill.number'
				},
				{
					headerName: 'Libellé Mouvement',
					field: 'bill.object'
				},
				{
					headerName: 'Débit',
					field: 'debit',
					cellClass: ['text-right', 'text-monospace'],
					cellRenderer: (params) => {
						if (params.value) {
							return Decimal.setDisplayNumber(params.value).humanizeNumber();
						} else {
							return '';
						}
					}
				},
				{
					headerName: 'Crédit',
					field: 'credit',
					cellClass: ['text-right', 'text-monospace'],
					cellRenderer: (params) => {
						if (params.value) {
							return Decimal.setDisplayNumber(params.value).humanizeNumber();
						} else {
							return '';
						}
					}
				},
				{
					headerName: 'CODE TVA',
					field: 'tva'
				}
			]
		});
	}

	public set infosBill(value: { [key: string]: any }) {
		if (value.type === 'credit' || value.type === 'credit-error') {
			const N_edit = document.querySelector(`#${this.idTab} #edit`) as HTMLButtonElement;
			N_edit.classList.remove('d-none');
		}

		this._infosBill = value;
	}

	public set data(data: any[]) {
		if (data[0] && data[0].log === 'ERR') {
			const N_container = document.querySelector(`#${this.idTab}`) as HTMLElement;
			const N_alert = N_container.querySelector('#alert') as HTMLElement;
			const N_edit = N_container.querySelector('#edit') as HTMLButtonElement;
			const N_update = N_container.querySelector('#update') as HTMLButtonElement;
			const N_export = N_container.querySelector('#export') as HTMLButtonElement;

			N_edit.disabled = true;
			N_update.disabled = true;
			N_export.disabled = true;

			N_alert.classList.remove('d-none');
			N_alert.innerHTML = data[0].bill.object;
		}

		this.N_grid!.value = data;
	}

	public get data(): any[] {
		return this.N_grid!.value;
	}

	public setCallback(cb: () => any) {
		this.getFormData = cb;
	}

	public async update() {
		const data = await S_C_Bills.getInstance().getComptaData(this.getFormData());
		this.N_grid!.value = data;
	}

	public set comptaDetailsCredit(value: { [key: string]: any }) {
		this._comptaDetailsCredit = value || {};
	}

	public get comptaDetailsCredit() {
		return this._comptaDetailsCredit;
	}

	public static register() {
		customElements.define(ComptaBillTab.tagName, ComptaBillTab);
	}
}

export default ComptaBillTab;
