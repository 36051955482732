import { ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';

import '../../../css/grid-sidebar.scss';

/**
 * CustomElement ajoutant une barre latérale à droite d'un ag-grid souhaité.
 *
 * Pour s'en servir, il suffit de l'ajouter après un ag-grid dans un template et de l'initialiser avec sa méthode initSideBar(gridOptions: GridOptions)
 * en lui donnant le GridOptions de l'ag-grid.
 *
 * Exemple :
 * 		<div class="h-100 d-flex">
 *			<div id="grid" class="pl-3 py-3 h-100 ag-theme-alpine w-100"></div>
 *			<ap-grid-sidebar></ap-grid-sidebar>
 *		</div>
 *
 * (Adapter le css en fonction du template)
 */
class GridSidebar extends HTMLElement {
	public static readonly tagName: string = 'ap-grid-sidebar';

	private gridOptions: GridOptions = {};

	private columnsCheckboxes: { [key: string]: HTMLInputElement } = {};
	private headerCheckbox: HTMLInputElement | null = null;

	private columnsListElements: { name: string, element: HTMLLIElement }[] = [];
	private defaultSortedColumnsListElements: { name: string, element: HTMLLIElement }[] = [];

	private columnsStatesLoaded: boolean = false;

	private alphabetize: string = 'not';

	private abortController: AbortController | null = null;

	private _closeGrid: boolean = false;

	public async connectedCallback() {
		const className = this.className;
		this.abortController = new AbortController();
		this.innerHTML = `

            <div class="h-100 ${className} d-flex">

                <div class="ap-grid-sidebar-column-panel h-100 p-3 d-none" id="sidebar-column-panel">
                    <div class="ap-grid-sidebar-column-filters w-100 border-bottom d-flex pb-3">
						<button id="ap-grid-column-alphabetize" type="button" title="Trier par ordre alphabétique" class="mr-2">
							<i class="icon icon-solid-sort-alpha-down border-dark" id="alpha-asc"></i>
							<i class="icon icon-solid-sort-alpha-up border-bottom border-dark d-none" id="alpha-desc"></i>
						</button>

						<input id="ap-grid-column-search" type="text" class="w-100 form-control">
					</div>
					<div id="ap-grid-column-header" class="d-flex p-1 border-bottom">
					</div>
                    <ul class="ap-grid-sidebar-column-list p-1 w-100 m-0" id="sidebar-column-list"></ul>
                </div>

                <div class="ap-grid-sidebar h-100 d-flex justify-content-center">
                    <div class="ap-grid-sidebar-container"  id="columns-btn">
                        <div class="ap-grid-sidebar-btn bg-transparent border-0">
                            <i class="ap-grid-sidebar-btn-icon icon icon-solid-columns mb-1"></i>
                            <span class="ap-grid-sidebar-btn-text">Colonnes</span>
                        </div>
                    <div>
                </div>
            <div>
        `;

		this.className = '';
		//this.classList.add('d-none');
	}

	public static register() {
		customElements.define(GridSidebar.tagName, GridSidebar);
	}

	/**
	 * Initialise la sidebar à droite de l'ag-grid correspondant à l'objet GridOptions donné en paramètre.
	 * @param gridOptions options de la grille ag-grid sur laquelle brancher la sidebar
	 */
	public initSideBar(gridOptions: GridOptions) {
		this.gridOptions = gridOptions;

		this.init();

		//Permet de forcer la fermeture à l'initialisation des données (besoin sur la vue Utilisateurs/Groupes)
		if (this._closeGrid) {
			this.forceCloseGrid();
		}
	}

	/**
	 * Modifie les bordures de l'ag-grid associé pour enlever l'arrondi sur les coins de droite
	 */
	private customizeGridBorders() {
		const N_grid_wrapper = this.parentElement?.querySelector('.ag-root-wrapper');
		N_grid_wrapper?.setAttribute('style', N_grid_wrapper?.getAttribute('style') || '' + 'border-top-right-radius: 0px; border-bottom-right-radius: 0px;');
	}

	private init() {
		this.gridOptions.api?.addEventListener('firstDataRendered', () => {
			this.classList.remove('d-none');
		});

		this.initButtons();
		this.initColumnPanel();
		this.customizeGridBorders();
	}

	private initButtons() {
		this.abortController?.abort();
		this.abortController = new AbortController();
		const N_columns_btn = this.querySelector('#columns-btn') as HTMLButtonElement;

		N_columns_btn?.addEventListener('click', () => this.togglePanel(), { signal: this.abortController.signal });

		const N_alphabetize = this.querySelector('#ap-grid-column-alphabetize') as HTMLButtonElement;
		N_alphabetize?.addEventListener('click', () => this.toggleFilterAlphabetize(), { signal: this.abortController.signal });

		const N_search = this.querySelector('#ap-grid-column-search') as HTMLInputElement;
		N_search?.addEventListener('input', () => this.filterSearch(N_search.value), { signal: this.abortController.signal });
	}

	private initColumnPanel() {
		this.initColumnHeader();
		this.initColumnList();
	}

	private initColumnHeader() {
		const N_column_header = document.querySelector('#ap-grid-column-header') as HTMLDivElement;
		N_column_header.innerHTML = '';

		const N_header_checkbox = document.createElement('input') as HTMLInputElement;
		N_header_checkbox.setAttribute('type', 'checkbox');
		N_header_checkbox.classList.add('mr-3');

		N_header_checkbox.addEventListener('change', () => {
			const fields: string[] = [];
			for (const field in this.columnsCheckboxes) {
				if (!this.columnsCheckboxes[field].parentElement!.classList.contains('d-none')) {
					this.columnsCheckboxes[field].checked = N_header_checkbox.checked;
					fields.push(field);
				}
			}

			this.gridOptions.columnApi?.setColumnsVisible(fields, N_header_checkbox.checked);
		});

		this.headerCheckbox = N_header_checkbox;

		const N_header_column = document.createElement('span');
		N_header_column.classList.add('font-weight-bold');
		N_header_column.innerHTML = 'Colonnes';

		N_column_header.append(N_header_checkbox);
		N_column_header.append(N_header_column);
	}

	private initColumnList() {
		const N_list = this.querySelector('#sidebar-column-list') as HTMLUListElement;
		N_list.innerHTML = '';

		if (this.gridOptions.columnDefs) {
			for (const colDef of this.gridOptions.columnDefs) {
				if (colDef.toolPanelClass !== 'd-none' && !colDef.suppressColumnsToolPanel) {
					const field = (colDef as ColDef).field || '';

					const id = field?.replace(/\./gmi, '_');

					const N_list_el = document.createElement('li') as HTMLLIElement;
					N_list_el.classList.add('d-flex', 'mb-1');

					const N_checkbox = document.createElement('input') as HTMLInputElement;
					N_checkbox.setAttribute('type', 'checkbox');
					N_checkbox.classList.add('mr-3');
					N_checkbox.id = id;

					N_checkbox.addEventListener('change', () => {
						this.gridOptions.columnApi?.setColumnVisible(field, N_checkbox.checked);

						this.updateHeaderCheckbox();
					});

					if (field) {
						this.columnsCheckboxes[field] = N_checkbox;
					}

					const N_column = document.createElement('label');
					N_column.setAttribute('for', id);
					N_column.classList.add('mb-0');
					N_column.innerHTML = colDef.headerName || '';

					N_list_el.append(N_checkbox);
					N_list_el.append(N_column);

					this.columnsListElements.push({ name: colDef.headerName || '', element: N_list_el });
					this.defaultSortedColumnsListElements.push({ name: colDef.headerName || '', element: N_list_el });

					N_list?.append(N_list_el);
				}
			}
		}
	}

	private updateHeaderCheckbox() {
		let checkedCount = 0;
		let lengthOfVisibles = 0;

		for (const key in this.columnsCheckboxes) {
			if (!this.columnsCheckboxes[key].parentElement!.classList.contains('d-none')) {
				lengthOfVisibles++;
				if (this.columnsCheckboxes[key].checked) {
					checkedCount++;
				}
			}
		}

		if (checkedCount === 0) {
			this.headerCheckbox!.checked = false;
			this.headerCheckbox!.indeterminate = false;
		} else if (checkedCount === lengthOfVisibles) {
			this.headerCheckbox!.checked = true;
			this.headerCheckbox!.indeterminate = false;
		} else {
			this.headerCheckbox!.checked = false;
			this.headerCheckbox!.indeterminate = true;
		}
	}

	private togglePanel() {
		if (!this.columnsStatesLoaded) {
			this.loadColumnsStates();
			this.updateHeaderCheckbox();
		}

		const N_panel = this.querySelector('#sidebar-column-panel') as HTMLDivElement;
		N_panel?.classList.toggle('d-none');
	}

	/**
	 * 3 états pour le filtre :
	 * 'not' : ne filtre pas
	 * 'desc' : filtre les colonnes par ordre alphabétique décroissant
	 */
	private toggleFilterAlphabetize() {
		if (this.alphabetize === 'not') {
			this.alphabetize = 'asc';
		} else if (this.alphabetize === 'asc') {
			this.alphabetize = 'desc';
		} else if (this.alphabetize === 'desc') {
			this.alphabetize = 'not';
		}

		this.toggleAlphabetizeIcon();
		this.filterAlphabetize();
	}

	private toggleAlphabetizeIcon() {
		const N_icon_alpha_asc = this.querySelector('#alpha-asc');
		const N_icon_alpha_desc = this.querySelector('#alpha-desc');

		if (this.alphabetize === 'not') {
			N_icon_alpha_desc?.classList.add('d-none');
			N_icon_alpha_asc?.classList.remove('border-bottom', 'd-none');
		} else if (this.alphabetize === 'asc') {
			N_icon_alpha_asc?.classList.add('border-bottom');
		} else if (this.alphabetize === 'desc') {
			N_icon_alpha_asc?.classList.add('d-none');
			N_icon_alpha_desc?.classList.remove('d-none');
		}
	}

	private filterAlphabetize() {
		const N_list = this.querySelector('#sidebar-column-list') as HTMLUListElement;

		if (this.alphabetize === 'asc' || this.alphabetize === 'desc') {
			const isAsc = this.alphabetize === 'asc';

			this.columnsListElements = this.columnsListElements.sort((a, b) => {
				// Normalisation des chaines de caractère pour la comparaison (on les met en minuscule / on retire les accents)
				const nameA = a.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
				const nameB = b.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

				if (nameA < nameB) {
					return isAsc ? -1 : 1;
				}
				if (nameA > nameB) {
					return isAsc ? 1 : -1;
				}
				return 0;
			});
		} else if (this.alphabetize === 'not') {
			this.columnsListElements = this.defaultSortedColumnsListElements.slice(0);
		}

		for (const item of this.columnsListElements) {
			N_list?.append(item.element);
		}
	}

	private forceCloseGrid() {
		const N_panel = this.querySelector('#sidebar-column-panel') as HTMLDivElement;
		//Permet de forcer la fermeture à l'initialisation des données (besoin sur la vue Utilisateurs/Groupes)

		N_panel?.classList.add('d-none');

		this._closeGrid = false;
	}

	private filterSearch(search: string) {
		for (const item of this.columnsListElements) {
			// Normalisation des chaines de caractère pour la comparaison (on les met en minuscule / on retire les accents / on enleve les espaces pour la recherche)
			const itemName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

			search = search.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

			if (itemName.includes(search)) {
				item.element.classList.replace('d-none', 'd-flex');
			} else {
				item.element.classList.replace('d-flex', 'd-none');
			}
		}
		this.updateHeaderCheckbox();
	}

	private loadColumnsStates() {
		const states = this.gridOptions.columnApi?.getColumnState() || [];

		for (const state of states) {
			const colId = state.colId;

			if (colId && this.columnsCheckboxes[colId]) {
				this.columnsCheckboxes[colId].checked = !state.hide;
			}
		}
	}

	public set closeGrid(closeGrid:boolean) {
		this._closeGrid = closeGrid;
	}
}

export default GridSidebar;
