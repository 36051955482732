import 'daterangepicker';
import moment from 'moment';

import drpC from '@libs/utils/daterangepickerConfig';

import 'daterangepicker/daterangepicker.css';

class DatePickerRangeFilter {
	private params: any;
	private eGui: HTMLElement;
	private eDateInput: HTMLInputElement | null = null;

	private startDate: Date | null = null;
	private endDate: Date | null = null;

	constructor() {
		this.eGui = document.createElement('div');
	}

	public init(params: any) {
		this.params = params;

		this.eGui.classList.add('ag-input-text-wrapper', 'ag-date-filter');
		this.eGui.id = this.params.column.colId;
		this.eGui.innerHTML = `
            <input class="form-control" type='text' data-input value="" />
            <a class='input-button' title='clear' data-clear>
                <i class='icon icon-solid-times'></i>
            </a>
        `;

		this.eDateInput = this.eGui.querySelector('input');

		const N_clear = this.eGui.querySelector('[data-clear]');

		if (N_clear) {
			N_clear.addEventListener('click', () => {
				if (this.eDateInput) {
					this.startDate = null;
					this.endDate = null;

					const N_input = $(this.eDateInput);
					N_input.val('');

					this.updateFilter();
				}
			});
		}

		if (this.eDateInput) {
			const N_input = $(this.eDateInput);

			//@ts-ignore
			N_input.daterangepicker(drpC({
				autoUpdateInput: false,
				startDate: new Date()
			}));

			N_input.on('apply.daterangepicker', (ev, picker) => {
				this.startDate = picker.startDate.toDate();
				this.endDate = picker.endDate.toDate();

				N_input.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));

				this.updateFilter();
			});

			N_input.val('');
		}
	}

	public updateFilter() {
		this.params.parentFilterInstance((instance: any) => {
			if (this.startDate && this.endDate) {
				const filter = this.params.api.getFilterInstance(this.params.column.colDef.field);

				filter.setModel({
					type: 'inRange',
					filter: parseInt(moment(this.startDate).format('x')),
					filterTo: parseInt(moment(this.endDate).format('x'))
				});

				this.params.api.onFilterChanged();
			} else {
				instance.onFloatingFilterChanged(null, null);
			}
		});
	}

	public onParentModelChanged(parentModel: any) {
		if (this.eDateInput) {
			const N_input = $(this.eDateInput);

			if (parentModel) {
				const startDate = moment(parentModel.filter, 'x');
				const endDate = moment(parentModel.filterTo, 'x');

				const picker = N_input.data('daterangepicker');

				if (picker) {
					picker.setStartDate(startDate);
					picker.setEndDate(endDate);
				}

				N_input.val(startDate.format('DD/MM/YYYY') + ' - ' + endDate.format('DD/MM/YYYY'));

				this.startDate = startDate.toDate();
				this.endDate = endDate.toDate();
			}
		}
	}

	public getGui(): HTMLElement {
		return this.eGui;
	}

	public destroy() {
		if (this.eDateInput) {
			$(this.eDateInput).remove();
		}
	}
}

export default DatePickerRangeFilter;
