import { Module, Modules, Router } from '@autoprog/core-client';

import T_BillsCustomer from './tpl/bills.customer.html';

import Notifications from '@modules/Apps/js/libs/Notifications';

import C_BillsCustomer from './js/controllers/Bills.Customer';

import CE_BillsCustomersTab from './js/customElements/BillsCustomersTab';
import CE_ComptaTab from './js/customElements/ComptaTab';
import CE_ContentTab from './js/customElements/ContentTab';
import CE_PaymentTab from './js/customElements/Payment';
import CE_PrintOptionTab from './js/customElements/BillPrintOption';
import CE_SettingsTab from './js/customElements/SettingsTab';

import C_SettingsBill from './js/controllers/Settings.Bill';
import T_SettingsBill from './tpl/settings.Bill.html';

import SettingsNotificationRegister from '@modules/Notifications/js/libs/SettingsNotificationRegister';
import SettingsRegister from '@modules/Settings/js/libs/SettingsRegister';

class BillCustomerModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_PaymentTab.register();
		CE_PrintOptionTab.register();
		CE_SettingsTab.register();
		CE_ComptaTab.register();
		CE_ContentTab.register();
		CE_BillsCustomersTab.register();
	}

	public async preInit() {
		const settingsNotificationRegister = SettingsNotificationRegister.getInstance();

		settingsNotificationRegister.addInput({
			text: 'Factures',
			select: [
				{
					text: 'Retard',
					name: 'bills.relunch'
				}
			]
		}, 0);

		Notifications.addCategory(['bill-relunch'], {
			textFilter: 'Facture',
			isFilter: true,
			icon: 'icon-outline-file-invoice-dollar',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					C_BillsCustomer.open(item.data.id);
				}
			}
		});

		const settingsRegister = SettingsRegister.getInstance();
		settingsRegister.addCategory({
			id: 'general',
			order: 0
		});

		settingsRegister.addLink({
			id: 'apps',
			Controller: C_SettingsBill,
			tpl: T_SettingsBill,
			icon: 'bill-euro/line',
			order: 10,
			category: 'general',
			name: 'Facture - Client',
			permission: 'BILLS._CUSTOMERS.DEFAULT_SETTINGS'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/billsPage/customer',
			controller: C_BillsCustomer as any,
			tpl: T_BillsCustomer,
			requireValidUser: true,
			permission: 'BILLS._CUSTOMERS.OPEN'
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Factures-Client', BillCustomerModule);
