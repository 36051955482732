import { GridOptions } from '@ag-grid-enterprise/all-modules';

export type HeaderDashboardLine = { label: string, value: string, labelClass?: string, valueClass?: string };

/**
 * Custom element qui permet gerer l'entete des dashboard
 */
class HeaderDashboard extends HTMLElement {
	public static readonly tagName: string = 'ap-header-dashboard';

	public data: HeaderDashboardLine[] = [];

	private timeout: any = null;
	private timeout2: any = null;

	public async connectedCallback() {
		this.classList.add('mr-auto');
		this.innerHTML = `
			<h4 id="title"></h4>
			<span class="ml-1 h4" id="number"></span>
			<div class="container-popover-infos">
				<div class="cursor-pointer" id="icon">
					<i class="icon icon-ri-information-line h3"></i>
				</div>
				<div class="popover-infos mt-1 ml-1 d-none">
				</div>
			</div>
        `;

		const N_container = this.querySelector('.container-popover-infos') as HTMLElement;
		const N_iconContainer = this.querySelector('#icon') as HTMLElement;
		const N_icon = this.querySelector('.icon') as HTMLElement;

		let disabledAutoClose = false;

		N_container.addEventListener('mouseover', () => {
			this.open();
		});

		N_container.addEventListener('mouseleave', () => {
			if (!disabledAutoClose) {
				this.close();
			}
		});

		N_iconContainer.addEventListener('click', () => {
			disabledAutoClose = !disabledAutoClose;

			N_icon.classList.toggle('icon-ri-information-line');
			N_icon.classList.toggle('icon-ri-information-fill');

			if (!disabledAutoClose) {
				this.close(true);
			} else {
				this.open();
			}
		});

		this.render();
	}

	private close(direct = false) {
		const N_container = this.querySelector('.container-popover-infos') as HTMLElement;
		const N_popover = this.querySelector('.popover-infos') as HTMLElement;
		clearTimeout(this.timeout);
		clearTimeout(this.timeout2);

		if (direct) {
			N_container.classList.remove('active');
			N_popover.classList.add('d-none');
		} else {
			this.timeout = setTimeout(() => {
				N_container.classList.remove('active');

				this.timeout2 = setTimeout(() => {
					N_popover.classList.add('d-none');
				}, 500);
			}, 100);
		}
	}

	private open() {
		const N_container = this.querySelector('.container-popover-infos') as HTMLElement;
		const N_popover = this.querySelector('.popover-infos') as HTMLElement;
		clearTimeout(this.timeout);
		clearTimeout(this.timeout2);
		N_container.classList.add('active');
		N_popover.classList.remove('d-none');
	}

	private render() {
		const N_container = this.querySelector('.popover-infos') as HTMLElement;
		const N_icon = this.querySelector('i') as HTMLElement;

		if (this.data.length) {
			N_icon.classList.remove('d-none');
		} else {
			N_icon.classList.add('d-none');
		}

		const html: string[] = [];

		for (const item of this.data) {
			if (item.label === 'separator') {
				html.push(`
						<div class='border-bottom'></div>
					`);
			} else {
				html.push(`
					<div class="d-flex my-3">
						<span class="mr-3 ${item.labelClass ?? ''}">${item.label} ${item.value ? ':' : ''} </span> 
						<span class="font-weight-bold ml-auto ${item.valueClass ?? ''}">${item.value}</span>
					</div>
				`);
			}
		}

		N_container.innerHTML = html.join('');
	}

	public setData(data: HeaderDashboardLine[]) {
		this.data = data;
		this.render();
	}

	public setTitle(title: string) {
		const N_title = this.querySelector('#title') as HTMLElement;
		N_title.innerHTML = title;
	}

	public setGridOptions(gridOptions: GridOptions) {
		this.setNumber(gridOptions);

		gridOptions.api?.addEventListener('filterChanged', () => {
			this.setNumber(gridOptions);
		});

		gridOptions.api?.addEventListener('rowDataChanged', () => {
			this.setNumber(gridOptions);
		});

		gridOptions.api?.addEventListener('gridReady', () => {
			this.setNumber(gridOptions);
		});
	}

	public displayNumber() {
		const N_number = this.querySelector('#number') as HTMLElement;
		N_number.classList.remove('d-none');
	}

	public hideNumber() {
		const N_number = this.querySelector('#number') as HTMLElement;
		N_number.classList.add('d-none');
	}

	private setNumber(gridOptions: GridOptions) {
		let number = 0;

		gridOptions.api?.forEachNodeAfterFilter(() => {
			number++;
		});

		const N_number = this.querySelector('#number') as HTMLElement;
		N_number.innerHTML = `(${number})`;
	}

	public reset() {
		const N_number = this.querySelector('#number') as HTMLElement;
		N_number.innerHTML = '(0)';

		this.data = [];
		this.render();
	}

	public static register() {
		customElements.define(HeaderDashboard.tagName, HeaderDashboard);
	}
}

export default HeaderDashboard;
