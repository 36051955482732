import { Controller, LoggedUser, Router, Tabs } from '@autoprog/core-client';

import C_Database from '@js/controllers/Database';

import C_Tab from '@js/controllers/TabDatabase';
import T_blockTabs from '@tpl/blocks/tabs.html';

import Permissions from '@libs/Permissions';

class TabsController extends Controller {
	private tabs: Tabs;

	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		this.tabs = new Tabs({
			navElement: el.querySelector('.tabs-container') as HTMLElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		params = params || {};

		const loggedUser = LoggedUser.getInstance();

		const defaultTab = this.getDefaultTab(params.tabs, data.tabs, loggedUser);

		for (const item of data.tabs) {
			this.tabs.add({
				name: item.name,
				controller: C_Tab,
				data: {
					...item,
					instance: C_Database
				},
				default: defaultTab === item.database,
				permission: `${Permissions[item.database]}.SHOW`,
				template: T_blockTabs
			});
		}

		let firstLoad = true;
		this.tabs.on('change', (item: { [key: string]: any }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				history.pushState(null, data.name, `#tabs/${data.id || data.index}/${item.data.database}`);
			}
		});
	}

	public destructor() {
		this.tabs.destructor();

		const N_datepicker = document.querySelectorAll('.daterangepicker');

		for (const N_el of N_datepicker) {
			N_el.remove();
		}

		const N_tooltip = document.querySelectorAll('.tooltip');

		for (const N_el of N_tooltip) {
			N_el.remove();
		}
	}

	//Permet de retourner la bonne page en fonction de l'uri saisie par l'utilisateur
	private getDefaultTab(tabUri: string | undefined, tabList: Array<{tab: string, database: string, name: string, type: string}>, user: LoggedUser): string | undefined {
		//Si l'onglet n'est pas précisé, on retourne la première page avec les permissions
		if (tabUri === undefined) {
			for (const item of tabList) {
				if (user.hasPermission(`${Permissions[item.database]}.SHOW`)) {
					return item.database;
				}
			}
			Router.getInstance().navigate('#403');
		} else { //Sinon, on vérifie l'onglet passé dans l'uri
			const matchingTab = tabList.find((e) => e.database === tabUri);
			if (matchingTab) {
				if (user.hasPermission(`${Permissions[matchingTab.database]}.SHOW`)) {
					return matchingTab.database;
				} else {
					Router.getInstance().navigate('#403');
				}
			} else {
				Router.getInstance().navigate('#404');
			}
		}
	}
}

export default TabsController;
