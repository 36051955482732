import Decimal from '@libs/utils/Decimal';
import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/createBillProvider.html';

import '../../css/createBillProvider.scss';

import C_BillsProvider from '../controllers/Bills.Provider';

import S_P_Bill from '@services/Provider/ProviderBillService';
import S_P_Order from '@services/Provider/ProviderOrderService';

import CE_PriceBillProvider from '../customElements/PriceBillProvider';

class CreateBillProvider extends Modal {
	private id_order: string;
	private id_edit: string | undefined;

	private mode: string = 'create-bill';

	constructor(id_order: string, id_edit?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.id_order = id_order;
		this.id_edit = id_edit;

		this.on('opened', async () => {
			this.element.classList.add('loading');

			const N_title = this.element.querySelector<HTMLElement>('#title');

			const N_type = this.element.querySelector<HTMLSelectElement>('#type')!;
			const N_designation = this.element.querySelector<HTMLInputElement>('#designation')!;
			const N_comment = this.element.querySelector<HTMLTextAreaElement>('#comment');
			const N_labelType = this.element.querySelector<HTMLTextAreaElement>('[for="type"]');
			const N_labelDesignation = this.element.querySelector<HTMLTextAreaElement>('[for="designation"]');

			const N_PriceBillProvider = this.element.querySelector<CE_PriceBillProvider>(CE_PriceBillProvider.tagName)!;
			const N_save = this.element.querySelector<HTMLInputElement>('#save');

			N_type.addEventListener('change', () => {
				N_PriceBillProvider.setLastPrice(N_type.value === 'last');
				this.updateSaveButton();
			});

			N_designation.addEventListener('input', () => {
				this.updateSaveButton();
			});

			if (this.mode === 'create-bill') {
				N_title!.innerHTML = 'Création facture fournisseur';
			}

			if (this.mode === 'create-credit') {
				N_title!.innerHTML = 'Création avoir facture fournisseur';
				N_labelType!.innerHTML = 'Type d\'avoir';
				N_labelDesignation!.innerHTML = 'Motif de l’avoir';
			}

			this.updateOptionsType();

			const order = await S_P_Order.getInstance().getById(this.id_order);
			N_designation!.value = order.infos.label;

			let billState = {};

			if (this.mode === 'create-credit') {
				billState = await S_P_Bill.getInstance().getBillStateByID(this.id_edit!);
			} else {
				billState = await S_P_Bill.getInstance().getBillStateByOrder(this.id_order);
			}

			N_PriceBillProvider.mode = this.mode;

			await N_PriceBillProvider.init(billState);

			N_PriceBillProvider.addEventListener('update', () => {
				this.updateSaveButton();
			});

			this.updateTitle(order);

			N_save?.addEventListener('click', () => {
				if (this.validateForm()) {
					const data = {
						id_bill: this.id_edit,
						id_order: this.id_order,
						type: N_type.value,
						label: N_designation.value,
						comment: N_comment!.value,
						...N_PriceBillProvider.data
					};

					this.ignoreChangeRoute = true;
					C_BillsProvider.open(null, data);

					this.resolve();
				}
			});

			this.updateSaveButton();

			this.element.classList.remove('loading');
		});
	}

	public setMode(value: 'create-bill' | 'create-credit') {
		this.mode = value;
		return this;
	}

	private updateOptionsType() {
		const N_options_advance = this.element.querySelector<HTMLOptionElement>('#type option[value="advance"]')!;
		const N_options_normal = this.element.querySelector<HTMLOptionElement>('#type option[value="normal"]')!;
		const N_options_last = this.element.querySelector<HTMLOptionElement>('#type option[value="last"]')!;
		const N_options_credit = this.element.querySelector<HTMLOptionElement>('#type option[value="credit"]')!;
		const N_options_creditError = this.element.querySelector<HTMLOptionElement>('#type option[value="credit-error"]')!;

		if (this.mode === 'create-credit') {
			N_options_advance.remove();
			N_options_normal.remove();
			N_options_last.remove();
		} else {
			N_options_credit.remove();
			N_options_creditError.remove();
		}
	}

	private async updateTitle(order: { [key: string]: any }) {
		const N_addonTitle = this.element.querySelector<HTMLElement>('.modal-title-addon')!;

		if (this.mode === 'create-credit') {
			const bill = await S_P_Bill.getInstance().getById(this.id_edit!);
			N_addonTitle!.innerHTML = bill.infos.internalNumber;
		} else {
			N_addonTitle!.innerHTML = order.infos.number;
		}
	}

	private updateSaveButton() {
		const N_save = this.element.querySelector<HTMLButtonElement>('#save')!;

		N_save.disabled = !this.validateForm();
	}

	private validateForm() {
		let validity = true;

		const N_PriceBillProvider = this.element.querySelector<CE_PriceBillProvider>(CE_PriceBillProvider.tagName)!;
		const N_type = this.element.querySelector<HTMLSelectElement>('#type')!;
		const N_errorMessage = this.element.querySelector<HTMLButtonElement>('#error-message')!;

		if (N_type.value !== 'last' && Decimal.setDisplayNumber(N_PriceBillProvider.price).equals(Decimal.setDisplayNumber(N_PriceBillProvider.priceNotBill))) {
			N_errorMessage.innerHTML = '<ap-icon name="error-warning/line"></ap-icon>Seule une facture définitive peut solder une commande.';
			validity = false;
		}

		if (N_PriceBillProvider.checkError()) {
			validity = false;
		}

		const N_labelDesignation = this.element.querySelector('[for="designation"]') as HTMLLabelElement;
		const N_designation = this.element.querySelector('#designation') as HTMLInputElement;

		if (!N_designation.value.trim()) {
			N_designation.classList.add('is-invalid');
			N_labelDesignation.classList.add('text-red');
			validity = false;
		} else {
			N_designation.classList.remove('is-invalid');
			N_labelDesignation.classList.remove('text-red');
		}

		validity && (N_errorMessage.innerHTML = '');

		return validity;
	}
}

export default CreateBillProvider;
