import { GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import Options from '../GridOptions';

import M_EditGroup from '../../modals/AddQuote/EditGroup';
import M_SelectFavorite from '../../modals/AddQuote/SelectFavorite';
import M_SelectQuote from '../../modals/AddQuote/SelectAddQuote';

import Clipboard from '../Clipboard';

import AgGridStateSaver from '@libs/agGrid/StateSaver';
import Calcul from '../Calcul';
import QuoteOptions from '../QuoteOptions';

import AggridUtils from '@libs/utils/Aggrid';
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

import tippy, { Instance, Props } from 'tippy.js';

import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_TVA from '@libs/customElement/TVA';

import _ from 'lodash';

import QuoteData from '../QuoteData';

class GridQuote extends HTMLElement {
	public static readonly tagName: string = 'ap-grid-quote';

	private _optionsGrid: Options = new Options();
	private _quoteOptions: QuoteOptions | null = null;

	private _isLock: boolean = false;

	private N_grid: CE_AgGrid | null = null;

	private _textNotOption: string = '';
	private _settingsDetails: { [key: string]: any } = {};
	private _stateSaverMaster: AgGridStateSaver | null = null;
	private _notFinishGeneral: boolean = false;
	private intervalFlagNotFinish: any = null;

	private instanceTooltipGlobalPrice: Instance<Props> | null = null;

	public async connectedCallback() {
		this.innerHTML = `
			<div class="d-flex flex-column h-100">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>

				<div class="d-flex mt-2">

					<div class="d-flex flex-wrap flex-grow-1 align-items-center" style="white-space:nowrap">

						<div class="font-weight-bold mr-2">
							Légende :
						</div>
						<small class="d-flex align-items-center mr-2">
							<div class="badge-legend">
								<i class="icon icon-solid-flag" id="flagNotFinish"></i>
							</div>
							À finir
						</small>
						<small class="d-flex align-items-center text-purple-900 mr-2">
							<div class="badge-legend bg-purple-100">
							</div>
							Catégorie en option
						</small>
						<small class="d-flex align-items-center text-red-900 mr-2">
							<div class="badge-legend bg-red-100">
							</div>
							Coef total désactivé
						</small>
						<small class="d-flex align-items-center text-blue-900 mr-2">
							<div class="badge-legend bg-blue-50">
							</div>
							Masquer à l'impression
						</small>
						<small class="d-flex align-items-center text-orange-900 mr-2">
							<div class="badge-legend bg-orange-50">
							</div>
							Produit "supprimé"
						</small>
					</div>

					<div class="align-items-center d-flex ">
						<div id="globalPrice" class="price-quote" style="white-space:nowrap"></div>
					</div>

				</div>

			</div>
		`;

		this.N_grid = this.querySelector<CE_AgGrid>('#grid');

		this.initGrid();

		const N_flagNotFinish = this.querySelector('#flagNotFinish') as HTMLElement;

		this.intervalFlagNotFinish = setInterval(() => {
			const data = this.data as any;

			if (this._notFinishGeneral || _.find(data, { notFinish: true })) {
				N_flagNotFinish.classList.toggle('text-danger');
			} else {
				N_flagNotFinish.classList.remove('text-danger');
			}
		}, 3000);
	}

	public disconnectedCallback() {
		clearInterval(this.intervalFlagNotFinish);
	}

	public set isLock(value: boolean) {
		this._isLock = value;
		this._optionsGrid!.isLock = value;
	}

	private async initGrid() {
		this.N_grid!.setGridOptions({
			masterDetail: true,
			detailRowHeight: 500,
			rowDragManaged: true,
			...this._optionsGrid!.gridMaster('quote'),
			onGridReady: (params) => {
				this._stateSaverMaster = new AgGridStateSaver(params as any, 'add-quote');
			},
			onCellEditingStarted: (params) => {
				if (params.column.getColId() === 'name') {
					params.node.setRowHeight((params.node.rowHeight || 0) + 20);
					params.api.onRowHeightChanged();
				}
			},
			onCellEditingStopped: (params) => {
				this.updateGlobalPrice();

				this.dispatchEvent(new CustomEvent('update'));

				params.api.resetRowHeights();
				params.api.refreshCells({ force: true });
			},
			getContextMenuItems: (params) => {
				const addLine = {
					name: 'Ajouter un groupe',
					disabled: this._isLock,
					action: () => {
						AggridUtils.addLine(params, {
							quantity: '1',
							unit: QuoteData.DEFAULT_UNIT,
							_idGrid: Utils.generateId(),
							details: []
						});
					}
				};

				const insertLine = {
					name: 'Inserer un groupe',
					disabled: this._isLock,
					action: () => {
						AggridUtils.insertLine(params, {
							quantity: '1',
							unit: QuoteData.DEFAULT_UNIT,
							_idGrid: Utils.generateId(),
							details: []
						});
					}
				};

				const addFavorite = {
					name: 'Ajouter un favori',
					disabled: this._isLock,
					icon: '<i class="icon icon-star"></i>',
					action: async () => {
						new M_SelectFavorite().open().then((data) => {
							AggridUtils.addLines(params, data);

							params.api?.resetRowHeights();
							params.api?.refreshCells({ force: true });
						});
					}
				};

				const addQuote = {
					name: 'Ajouter un devis',
					disabled: this._isLock,
					icon: '<i class="icon icon-solid-file-contract"></i>',
					action: () => {
						new M_SelectQuote(this.id).open().then((data) => {
							AggridUtils.addLines(params, data);

							params.api?.resetRowHeights();
							params.api?.refreshCells({ force: true });
						});
					}
				};

				const editGroup = {
					name: 'Modifier groupe',
					disabled: this._isLock,
					action: () => {
						new M_EditGroup(this.data, params.node.data._idGrid).open().then((data) => {
							data = {
								...params.node.data,
								...data
							};

							params.node.setData(data);

							params.node.detailNode?.detailGridInfo?.api?.setRowData(data.details);
							params.node.detailNode?.detailGridInfo?.api?.setPinnedBottomRowData([{}]);

							params.api?.refreshCells({ rowNodes: [params.node], force: true });

							params.api?.resetRowHeights();

							this.updateGlobalPrice();

							this.dispatchEvent(new CustomEvent('update'));
						});
					}
				};

				const options = {
					name: 'Mettre/Enlever de l\'options',
					disabled: this._isLock,
					action: () => {
						params.node.data.isOption = !params.node.data.isOption;

						params.node.setData(params.node.data);

						params.api?.refreshCells({ force: true });

						this.updateGlobalPrice();

						this.dispatchEvent(new CustomEvent('update'));
					}
				};

				const notFinish = {
					name: 'À finir',
					disabled: this._isLock,
					subMenu: [{
						name: `Groupe ${params.node ? (params.node.data.notFinish ? '(enlever)' : '(définir)') : ''}`,
						disabled: !params.node,
						action: async () => {
							params.node.data.notFinish = !params.node.data.notFinish;

							params.node.setDataValue('notFinish', params.node.data.notFinish);

							params.api?.refreshCells({ force: true });

							this.dispatchEvent(new CustomEvent('update'));
						}
					}, {
						name: `Général (${this._notFinishGeneral ? 'enlever' : 'définir'})`,
						action: async () => {
							this._notFinishGeneral = !this._notFinishGeneral;

							this.dispatchEvent(new CustomEvent('update'));
						}
					}]
				};

				const clipboard = Clipboard.getInstance();

				const copy = {
					name: 'Copier',
					disabled: !params.node,
					icon: '<i class="icon icon-copy"></i>',
					action: () => {
						clipboard.setData(_.cloneDeep(params.node.data), 'master');
					}
				};

				const paste = {
					name: 'Coller',
					disabled: this._isLock || !clipboard.getData('master'),
					icon: '<i class="icon icon-solid-paste"></i>',
					action: () => {
						const data = _.cloneDeep(clipboard.getData('master'));

						data._idGrid = Date.now().toString(36);

						params.api?.applyTransaction({
							add: [data]
						});

						params.api?.refreshCells({ force: true });
					}
				};

				const style = this._optionsGrid!.styleContextMenu(params, () => {
					this.dispatchEvent(new CustomEvent('update'));
				});

				if (params.node) {
					return [
						editGroup,
						addLine,
						insertLine,
						'separator',
						options,
						notFinish,
						'separator',
						style,
						'separator',
						addFavorite,
						addQuote,
						'separator',
						copy,
						paste
					];
				} else {
					return [
						addLine,
						'separator',
						notFinish,
						'separator',
						addFavorite,
						addQuote,
						'separator',
						copy,
						paste
					];
				}
			},
			onRowDragEnd: () => {
				this.dispatchEvent(new CustomEvent('update'));
			},
			onRowDataUpdated: () => {
				this.updateGlobalPrice();
				this.dispatchEvent(new CustomEvent('update'));
			},
			onRowDataChanged: () => {
				this.updateGlobalPrice();
			},
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			},
			getRowStyle: (params: any) => {
				if (params.data.isOption) {
					return {
						'background-color': 'var(--ap-purple-50)',
						color: 'var(--ap-purple-900)'
					};
				} else {
					return {
						background: '',
						color: ''
					};
				}
			},
			detailCellRendererParams: {
				detailGridOptions: agUtils.french<GridOptions>({
					...this._optionsGrid!.gridDetails('edit', () => {
						this.updateGlobalPrice();
						this.N_grid!.refreshCells();
						this.dispatchEvent(new CustomEvent('update'));
					}),
					onRowDragEnd: () => {
						this.dispatchEvent(new CustomEvent('update'));
					},
					onRowDataUpdated: () => {
						this.dispatchEvent(new CustomEvent('update'));
						this.updateGlobalPrice();
					},
					onGridReady: (params: any) => {
						const stateSaver = new AgGridStateSaver(params as any, 'add-quote-details');
						stateSaver.setData(this._settingsDetails);
						stateSaver.onUpdate((value) => {
							this._settingsDetails = value;
						});
					}
				}),
				template: () => {
					return '<div class="h-100"><div ref="eDetailGrid" class="h-100" style="padding: 3px;background: black;"></div></div>';
				},
				getDetailRowData: (params: any) => {
					for (const i in params.data.details) {
						params.data.details[i]._idGrid = Utils.generateId();
					}
					params.successCallback(params.data.details);
				}

			}
		});
	}

	private updateGlobalPrice() {
		if (this.N_grid!.isLoad) {
			const N_text = this.querySelector('#globalPrice') as HTMLElement;

			const data = new Calcul().calculateGlobalPrice(this.data);

			const textAll = Decimal.setDisplayNumber(data.all).setSuffixAndHumanizeNumber('€', this._quoteOptions?.pref.decimalNumber);
			this._textNotOption = Decimal.setDisplayNumber(data.notOption).setSuffixAndHumanizeNumber('€', this._quoteOptions?.pref.decimalNumber);
			const textOption = Decimal.setDisplayNumber(data.option).setSuffixAndHumanizeNumber('€', this._quoteOptions?.pref.decimalNumber);

			N_text.innerHTML = this._textNotOption;

			this.instanceTooltipGlobalPrice?.destroy();

			this.instanceTooltipGlobalPrice = tippy(N_text, {
				allowHTML: true,
				trigger: 'mouseenter',
				content: `
					<div class="px-3">
						<div class="py-2 font-weight-bold">Détail Prix:</div>
						<div>Avec option : ${textAll}</div>
						<div>Option : ${textOption}</div>
					</div>
				`
			});
		}
	}

	public set data(data: any[]) {
		this._quoteOptions = QuoteOptions.getInstance();
		this._optionsGrid!.init();
		this.N_grid!.value = data;
		this.updateGlobalPrice();
	}

	public get data(): any[] {
		const results: any[] = [];

		this.N_grid?.forEachNode((node) => {
			const obj = node.data;

			obj.name = obj.name || '';
			obj.category = obj.category || '';
			obj.subCategory = obj.subCategory || '';
			obj.tva = obj.tva || CE_TVA.getDefaultID();

			if (node.detailNode?.detailGridInfo) {
				obj.details = [];

				const details = node.detailNode.detailGridInfo;

				details.api?.forEachNode((nodeDetails) => {
					const tmp = nodeDetails.data;

					tmp.typeMat = this._optionsGrid!.getTypeMat(nodeDetails);
					tmp.type = this._optionsGrid!.getType(nodeDetails);
					tmp.tva = this._optionsGrid!.getTVA(nodeDetails);
					tmp.comptaVente = this._optionsGrid!.getComptaVente(nodeDetails);

					tmp.label = tmp.label || '';

					obj.details.push(tmp);
				});

				node.data.details = obj.details;
			}

			results.push(obj);
		});

		return results;
	}

	public async resetPrice() {
		const productsCache: Record<string, { price: number, costPrice: number }> = {};

		const data = this.data;

		for (const item of data) {
			for (const detail of item.details) {
				if (detail.reference) {
					try {
						if (!productsCache[detail.reference]) {
							const product = await this._optionsGrid!.convertProduct(detail.reference, detail);
							productsCache[detail.reference] = {
								price: product.price,
								costPrice: product.costPrice
							};
						}

						detail.price = productsCache[detail.reference].price;
						detail.costPrice = productsCache[detail.reference].costPrice;
					} catch (e) {

					}
				}
			}
		}

		this.data = data;
	}

	public refresh() {
		this.N_grid!.refreshCells();
		this.N_grid!.forEachNode((node) => {
			node.detailNode?.detailGridInfo?.api?.setPinnedBottomRowData([{}]);

			node.detailNode?.detailGridInfo?.api?.refreshCells({ force: true });

			node.detailNode?.detailGridInfo?.columnApi?.setColumnVisible('PU_Total', this._quoteOptions?.pref.displayPUDetails);
		});

		this.updateGlobalPrice();
	}

	public set settings(data: { [key: string]: any }) {
		data.settingsDetails.columns = {};

		this._settingsDetails = data.settingsDetails;

		this._stateSaverMaster!.setData(data.settings);
	}

	public set notFinishGeneral(notFinishGeneral: boolean) {
		this._notFinishGeneral = notFinishGeneral;
	}

	public get notFinishGeneral() {
		return this._notFinishGeneral;
	}

	public get globalPriceText() {
		return this._textNotOption;
	}

	public get grid() {
		return this.N_grid;
	}

	public static register() {
		customElements.define(GridQuote.tagName, GridQuote);
	}
}

export default GridQuote;
