import { Alert, DOM, LoggedUser, Router, toaster, utils } from '@autoprog/core-client';

import _ from 'lodash';

import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';

import M_NotifNotFinish from '../modals/AddQuote/NotifNotFinish';
import M_SaveAs from '../modals/AddQuote/SaveAs';

import M_ChangeState from '../modals/AddQuote/ChangeState';

import M_InformationQuoteToDo from '../modals/QuotesToDo/Information';
import M_Intervention from '@modules/Apps/js/modals/Interventions';

import C_CommandCustomer from '@modules/OrdersCustomer/js/controllers/Orders.Customer';

import CE_BrochureTab from '../libs/customElement/BrochureTab';
import CE_CGVTab from '@libs/customElement/CGVTab';
import CE_GridQuote from '../libs/customElement/GridQuote';
import CE_QuoteOptionReadonly from '../libs/customElement/QuoteOptionReadonly';
import CE_QuotePrintOption from '../libs/customElement/QuotePrintOption';
import CE_RecapProductsTab from '../libs/customElement/RecapProductsTab';
import CE_RecapTab from '../libs/customElement/RecapTab';
import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';

import CE_Infos from '../libs/customElement/QuoteInformations';

import QuoteOption from '../libs/QuoteOptions';

import '../../css/quotes.scss';
import '../../css/pageControllerID.scss';

import S_Quote from '@services/QuoteService';

import VerticalAccordion, { VerticalAccordionData } from '@libs/customElement/VerticalAccordion';
import History from '@libs/History';
import OpenDocuments from '@libs/customElement/OpenDocuments';
import Utils from '@libs/utils/Utils';

import DecimalApps from '@js/libs/utils/Decimal';
import { QuoteState } from './QuotesTabs';

class QuotesCtrl extends ControllerPageID {
	private N_QuoteOptionReadonly: CE_QuoteOptionReadonly | null = null;
	private N_QuotePrintOption: CE_QuotePrintOption | null = null;
	private N_CGVTab: CE_CGVTab | null = null;
	private N_GridQuote: CE_GridQuote | null = null;
	private N_RecapTab: CE_RecapTab | null = null;
	private N_BrochureTab: CE_BrochureTab | null = null;
	private N_RecapProductsTab: CE_RecapProductsTab | null = null;
	private N_infos: CE_Infos | null = null;

	private quoteOption: QuoteOption;

	private order: { [key: string]: string } = {};

	private intervalSocketIo: any = null;

	private notificationSession: { [key: string]: boolean } = {};

	private forceEdit: boolean = false;
	private allowForceEdit: boolean = false;

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = QuotesCtrl.options;

		QuotesCtrl.options = {};

		this.routeReturn = 'module/quotes';

		this.quoteOption = QuoteOption.newInstance();

		this.init('quotes', id);
	}

	private static options: { [key: string]: any } = {};

	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		QuotesCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'quotes');

			const data = await S_Quote.getInstance().getById(id);

			if (data.quoteToDo) {
				QuotesCtrl.options.quoteToDo = data.quoteToDo;
			}

			if (data.intervention) {
				QuotesCtrl.options.intervention = data.intervention;
			}

			let suffix = '';
			if (options.forceEdit) {
				suffix = '&forceEdit=true';
			}

			Router.getInstance().navigate(`#module/quote/add?id=${id}${suffix}`);
		} else {
			if (options.quoteToDo) {
				QuotesCtrl.options.quoteToDo = options.quoteToDo;
			}

			if (options.intervention) {
				QuotesCtrl.options.intervention = options.intervention;
			}

			Router.getInstance().navigate('#module/quote/add');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initVerticalAccordion();

		await DOM.nextTick();

		this.N_infos = this.el.querySelector<CE_Infos>(CE_Infos.tagName)!;

		this.initTabs();
		this.initNewQuote();

		this.initDropdownMenu();
		this.initValidationButton();

		const data = await this.getData();

		await this.setData(data);

		this.updateEditButtons();
		this.updateDocumentLink();
		this.updateGlobalPrice();

		this.postInit();
		this.initFullscreen();

		if (!this.id) {
			this.N_infos.openGeneralInformation(true);
		}
	}

	private initDropdownMenu() {
		const N_resetPrice = this.el.querySelector('#resetPrice') as HTMLButtonElement;
		N_resetPrice.addEventListener('click', async () => {
			toaster.info('Actualisation en cours', '', {
				timeout: 0
			});
			N_resetPrice.disabled = true;
			try {
				await this.N_GridQuote!.resetPrice();
			} finally {
				toaster.clear();
				N_resetPrice.disabled = false;
			}
		});

		const N_openCommand = this.el.querySelector('#open-command') as HTMLDivElement;
		N_openCommand.addEventListener('click', async () => {
			C_CommandCustomer.open(this.order.id);
		});

		const N_forceEdit = this.el.querySelector('#forceEditQuote') as HTMLButtonElement;
		N_forceEdit.addEventListener('click', () => {
			if (this.allowForceEdit) {
				if (this.forceEdit) {
					this.forceEdit = false;
					N_forceEdit.lastElementChild!.innerHTML = 'Forcer l\'édition';
					N_forceEdit.firstElementChild!.setAttribute('name', 'lock-unlock/line');
					this.el.querySelector('#forceEditBadge')?.classList.add('d-none');
				} else {
					this.forceEdit = true;
					N_forceEdit.lastElementChild!.innerHTML = 'Bloquer l\'édition';
					N_forceEdit.firstElementChild!.setAttribute('name', 'lock/line');
					this.el.querySelector('#forceEditBadge')?.classList.remove('d-none');
				}
				this.updateEditButtons();
			}
		});

		const N_changeState = this.el.querySelector('#changeState') as HTMLElement;
		N_changeState.addEventListener('click', async () => {
			await this.save();
			new M_ChangeState(this.id).open().then(async () => {
				await this.reload();
				this.updateEditButtons();
			});
		});
	}

	/**
	 * Initialise les boutons de validation lorsque le devis est en état "À valider"
	 */
	private initValidationButton() {
		const N_notification = this.el.querySelector('#notification') as HTMLElement;
		const N_yes = N_notification.querySelector('#yes') as HTMLButtonElement;
		const N_no = N_notification.querySelector('#no') as HTMLButtonElement;

		N_yes.addEventListener('click', async () => {
			this.setDataForm({
				infos: {
					state: '5'
				}
			});

			this.N_GridQuote?.refresh();

			await this.save();

			await (this.service as S_Quote)!.validateNotification(this.id, this.options.notification);

			toaster.success('Notification envoyée');

			N_notification.remove();
		});

		N_no.addEventListener('click', async () => {
			this.setDataForm({
				infos: {
					state: '0'
				}
			});

			this.N_GridQuote?.refresh();

			await this.save();

			await (this.service as S_Quote)!.refuseNotification(this.id, this.options.notification);

			toaster.success('Notification envoyée');

			N_notification.remove();
		});
	}

	protected initButton() {
		super.initButton();

		const N_saveAs = this.el.querySelector('#save-as') as HTMLDivElement;

		N_saveAs.addEventListener('click', async () => {
			new M_SaveAs(this.id).open().then((newID) => {
				QuotesCtrl.open(newID);
			}).catch(() => {

			});
		});

		const N_interventions = this.el.querySelector('#interventions') as HTMLButtonElement;

		N_interventions.addEventListener('click', () => {
			new M_Intervention(this.options.intervention).open();
		});

		const N_quoteToDo = this.el.querySelector('#quotes_to_do') as HTMLButtonElement;

		N_quoteToDo.addEventListener('click', () => {
			new M_InformationQuoteToDo(this.options.quote_to_do).open();
		});
	}

	private initTabs() {
		this.initCGV();
		this.initBrochures();
		this.initQuotes();
		this.initPreviewPrint();
		this.initGrid();
		this.initRecap();
		this.initMaterials();
	}

	private initCGV() {
		this.N_CGVTab = this.el.querySelector(CE_CGVTab.tagName) as CE_CGVTab;
		this.N_CGVTab.setParentElement(this.el);

		this.N_CGVTab!.setOnUpdate(() => {
			this.enableSaveButton();
		});
	}

	private initQuotes() {
		this.N_QuoteOptionReadonly = this.el.querySelector(CE_QuoteOptionReadonly.tagName) as CE_QuoteOptionReadonly;
		this.N_QuoteOptionReadonly.setParentElement(this.el);

		this.N_QuoteOptionReadonly.addEventListener('update', () => {
			this.N_GridQuote?.refresh();

			this.updateGlobalPrice();
			this.updateTitle();
			this.enableSaveButton();
		});
	}

	private initPreviewPrint() {
		this.N_QuotePrintOption = this.el.querySelector(CE_QuotePrintOption.tagName) as CE_QuotePrintOption;

		this.N_QuotePrintOption!.setParentElement(this.el);

		this.N_QuotePrintOption!.setID(() => {
			return this.id;
		});

		this.N_QuotePrintOption!.setGetData(() => {
			return this.saveData;
		});

		this.N_QuotePrintOption!.setOnUpdate(() => {
			this.enableSaveButton();
		});
	}

	private initGrid() {
		this.N_GridQuote = this.el.querySelector(CE_GridQuote.tagName);

		this.N_GridQuote?.addEventListener('update', () => {
			this.updateGlobalPrice();
			this.enableSaveButton();
		});
	}

	private initRecap() {
		this.N_RecapTab = this.el.querySelector(CE_RecapTab.tagName) as CE_RecapTab;

		this.N_RecapTab!.setParentElement(this.el);

		this.N_RecapTab!.setIsSave(() => {
			return this.isSaved;
		});

		this.N_RecapTab!.setGetData(() => {
			return this.saveData;
		});
	}

	private initMaterials() {
		this.N_RecapProductsTab = this.el.querySelector(CE_RecapProductsTab.tagName) as CE_RecapProductsTab;

		this.N_RecapProductsTab!.setParentElement(this.el);

		this.N_RecapProductsTab!.setID(() => {
			return this.id;
		});

		this.N_RecapProductsTab!.setGetData(() => {
			return this.saveData;
		});
	}

	private initBrochures() {
		this.N_BrochureTab = this.el.querySelector(CE_BrochureTab.tagName) as CE_BrochureTab;

		this.N_BrochureTab?.setParentElement(this.el);

		this.N_BrochureTab!.setOnUpdate(() => {
			this.enableSaveButton();
		});
	}

	private initNewQuote() {
		if (!this.id) {
			const N_verticalAccordion = this.el.querySelector('ap-vertical-accordion') as VerticalAccordion;
			N_verticalAccordion.open('quotes-informations');
		}
	}

	private initVerticalAccordion() {
		const N_verticalAccordion = this.el.querySelector('ap-vertical-accordion') as VerticalAccordion;

		const N_gridContainer = this.el.querySelector('#grid-container') as HTMLDivElement;
		const N_quotesInformations = this.el.querySelector('#quotes-informations') as HTMLDivElement;

		const accordionData: VerticalAccordionData = {
			[N_gridContainer.id]: {
				element: N_gridContainer,
				title: 'Devis'
			},
			[N_quotesInformations.id]: {
				element: N_quotesInformations,
				title: 'Paramètres'
			}
		};

		N_quotesInformations.addEventListener('update.title', () => {
			this.updateTitle();
		});

		N_quotesInformations.addEventListener('update.saveButton', () => {
			this.enableSaveButton();
		});

		N_quotesInformations.addEventListener('return', () => {
			this.return();
		});

		N_verticalAccordion.data = accordionData;
	}

	private get isLock() {
		const dataForm = this.N_infos!.data;

		const query = utils.getQuery();

		//TODO: à gérer avec la propriété forceEdit
		if (query.forceEdit === 'true') {
			return false;
		}

		if (this.forceEdit) {
			return false;
		}

		//"0": "En Cours", "6": "À Valider"
		if (['0', '6'].includes(dataForm.infos.state) && dataForm.infos.sendDate) {
			return true;
		}

		if (!['0', '6'].includes(dataForm.infos.state)) {
			return true;
		}

		return !LoggedUser.getInstance().hasPermission('QUOTES.EDIT');
	}

	protected async setData(data: DataServer) {
		super.setData(data);

		this.updateFileNumber(data.data);

		this.updateButtonNotification(data.validate);

		this.quoteOption.pref = data.data.pref;
		this.quoteOption.version = data.data.version;
		this.quoteOption.infos = data.data.infos;
		this.quoteOption.quoteOptions = data.data.quoteOptions;

		this.N_infos!.data = data;

		this.N_RecapProductsTab!.setData(data.materials);

		this.N_RecapTab!.data = data.recap.data;

		this.N_CGVTab!.data = data.data.CGV;
		this.N_QuotePrintOption!.data = data.data.pref;
		this.N_GridQuote!.notFinishGeneral = data.data.notFinishGeneral;

		this.N_GridQuote!.data = data.data.data;
		this.N_GridQuote!.settings = {
			settings: data.settings,
			settingsDetails: data.settingsDetails
		};

		this.N_BrochureTab!.data = data.data.brochures || {};

		// Si le devis est en état "Accepté", "Refusé" ou "Révisé"
		// -> on supprime les boutons "Actualiser prix" et "Changement état"

		if ([QuoteState.ACCEPTED, QuoteState.REFUSED, QuoteState.REVISED].includes(data.data.infos.state)) {
			const N_resetPrice = this.el.querySelector('#resetPrice') as HTMLButtonElement;
			N_resetPrice.remove();

			const N_changeState = this.el.querySelector('#changeState') as HTMLElement;
			N_changeState.remove();
		}

		// Si le devis est en état "Prêt à être envoyé", "Envoyé" ou "Attente commande"
		// -> on affiche le bouton "Forcer/Bloquer l'édition"

		const N_forceEdit = this.el.querySelector('#forceEditQuote') as HTMLButtonElement;

		if (N_forceEdit) {
			if ([QuoteState.READY_TO_BE_SENT, QuoteState.SEND, QuoteState.WAITING_FOR_ORDER].includes(data.data.infos.state)) {
				N_forceEdit.classList.remove('d-none');
				this.allowForceEdit = true;
			} else {
				N_forceEdit.classList.add('d-none');
				this.allowForceEdit = false;
			}
		}

		this.order = data.order;

		if (data.order.id) {
			const N_openCommand = this.el.querySelector('#open-command') as HTMLButtonElement;
			N_openCommand.classList.remove('d-none');
		}

		if (data.data.intervention) {
			this.options.intervention = data.data.intervention;
		}

		if (data.data.quote_to_do) {
			this.options.quote_to_do = data.data.quote_to_do;
		}

		const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
		N_sites.update(data.data.infos.hasFinalCustomer);

		const N_stateOther = this.el.querySelector('#state_other') as HTMLElement;
		const N_stateSend = this.el.querySelector('#state_send') as HTMLElement;

		//"1" : accepté, "2" : refusé, "7" : révisé
		if (N_stateOther && N_stateSend) {
			if (data.data.infos.sendDate && !['1', '2', '7'].includes(data.data.infos.state)) {
				N_stateOther.classList.add('d-none');
				N_stateSend.classList.remove('d-none');
			} else {
				N_stateOther.classList.remove('d-none');
				N_stateSend.classList.add('d-none');
			}
		}

		this.N_GridQuote?.refresh();
		this.N_QuoteOptionReadonly?.updateSettingQuoteOptions();
		this.N_QuoteOptionReadonly?.updateFormData();
	}

	protected postInit(): void {
		super.postInit();

		this.N_QuotePrintOption?.postInit();
	}

	protected getPageData(newData: { [key: string]: any }) {
		newData = this.N_infos!.data;
		newData.data = this.N_GridQuote!.data;
		newData.notFinishGeneral = this.N_GridQuote!.notFinishGeneral;

		if (this.options.quote_to_do) {
			newData.quoteToDo = this.options.quote_to_do;
		}

		if (this.options.intervention) {
			newData.intervention = this.options.intervention;
		}

		if (newData.infos.date) {
			newData.infos.date = newData.infos.date.format('YYYY-MM-DD');
		}

		if (newData.infos.sendDate) {
			newData.infos.sendDate = newData.infos.sendDate.format('YYYY-MM-DD');
		}

		if (newData.infos.validityDate) {
			newData.infos.validityDate = newData.infos.validityDate.format('YYYY-MM-DD');
		}

		if (newData.infos.relunch?.date) {
			newData.infos.relunch.date = newData.infos.relunch.date.format('YYYY-MM-DD');
		}

		newData.quoteOptions = this.N_QuoteOptionReadonly!.settingQuoteOptions;

		newData.brochures = this.N_BrochureTab!.data;

		newData.pref = {
			...newData.pref,
			...this.N_QuoteOptionReadonly!.data,
			...this.N_QuotePrintOption!.data
		};

		newData.CGV = this.N_CGVTab?.data;

		return newData;
	}

	protected setUrl() {
		const href: string[] = [];

		href.push(`id=${this.id}`);

		const query = utils.getQuery();

		if (query.forceEdit === 'true') {
			href.push('forceEdit=true');
		}

		const url = `#${Router.getInstance().getCurrentRoute()?.url}`;

		History.getInstance().replaceState(`${url}?${href.join('&')}`);
	}

	protected async postSave(newData: { [key: string]: any }) {
		//notification "Devis a finir"
		if (!this.notificationSession.notFinish && ['6', '0'].includes(newData.infos.state) && !newData.infos.sendDate && newData.notFinish) {
			try {
				const { reopen } = await new M_NotifNotFinish(newData._id).open();

				this.notificationSession.notFinish = reopen;
			} catch (e) {

			}
		}

		this.updateFileNumber(newData);

		const query = utils.getQuery();

		if (((query.forceEdit === 'true' || this.forceEdit) && this.order.id)) {
			Alert.confirm(this.order.value, 'Voulez-vous mettre à jour la commande avec les modifications apportées ?', {
				noText: 'Non',
				yesText: 'Oui'
			}).then(() => {
				C_CommandCustomer.open(this.order.id, { updatedQuoteId: this.id });
			}).catch(() => {
				this.disableSaveButton();
			});
		}
	}

	private updateEditButtons() {
		this.N_GridQuote!.isLock = this.isLock;
		this.N_QuoteOptionReadonly!.isLock = this.isLock;
		this.N_CGVTab!.isLock = this.isLock;
		this.N_infos!.isLock = this.isLock;
	}

	private updateGlobalPrice() {
		const N_verticalAccordion = this.el.querySelector('ap-vertical-accordion') as VerticalAccordion;
		N_verticalAccordion.setTabSubTitle('grid-container', '<span class="text-green">' + this.N_GridQuote!.globalPriceText + '</span>');
	}

	private updateFileNumber(data: { [key: string]: any }) {
		const numberFile = _.compact(_.values(data.attachments || {})).length.toString();

		const N_verticalAccordion = this.el.querySelector('ap-vertical-accordion') as VerticalAccordion;

		const N_quotesInformations = this.el.querySelector('#quotes-informations') as HTMLDivElement;

		if (N_verticalAccordion) {
			N_verticalAccordion.setTabSubTitle(N_quotesInformations.id, '<span class="text-red">Fichiers : ' + numberFile + '</span>');
		}
	}

	private updateDocumentLink() {
		const N_interventions = this.el.querySelector('#interventions') as HTMLButtonElement;
		const N_quoteToDo = this.el.querySelector('#quotes_to_do') as HTMLButtonElement;

		N_interventions.classList.add('d-none');
		N_quoteToDo.classList.add('d-none');

		if (this.options.intervention) {
			N_interventions.classList.remove('d-none');
		}

		if (this.options.quote_to_do) {
			N_quoteToDo.classList.remove('d-none');
		}
	}

	private updateButtonNotification(data: { userID: string, active: boolean, userText: string }) {
		const N_notification = this.el.querySelector('#notification') as HTMLElement;
		const N_waitingValidate = this.el.querySelector('#waiting-validate') as HTMLElement;

		if (data.active) {
			if (data.userID === Utils.userID) {
				N_notification.classList.remove('d-none');
				N_notification.classList.add('d-flex');
			} else {
				N_waitingValidate.classList.remove('d-none');
				N_waitingValidate.classList.add('d-flex');
				N_waitingValidate.innerHTML = 'En attente de validation par ' + data.userText;
			}
		}
	}

	protected get N_form(): HTMLFormElement | null {
		return null;
	}

	protected async save(disabledReload: boolean = false, disabledSetUrl: boolean = false) {
		//On vérifie que les groupes ont été saisis
		this.N_GridQuote?.data.forEach(group => {
			if (!group.category) {
				toaster.error('Veuillez saisir une catégorie par groupe');
				//TODO: catch l'erreur
				throw Error('invalid group');
			}

			//On vérifie que les lignes ont bien un libellé
			for (const row of group.details) {
				//Permet de gérer les nombre écrit avec une virgule 

				if (row.quantity) {
					row.quantity = DecimalApps.setDisplayNumber(row.quantity).toNumber();
				}

				if (row.times) {
					row.times = DecimalApps.setDisplayNumber(row.times).toNumber();
				}

				if (!row.label) {
					toaster.error("Veuillez renseigner un libellé pour chaque ligne d'un groupe");
					throw Error('invalid label');
				}
				if (!row.tva) {
					toaster.error("Veuillez renseigner une tva pour chaque ligne d'un groupe");
					throw Error('invalid tva');
				}
			}
		});
		const N_save = this.el.querySelector('#btn-save') as HTMLButtonElement;
		N_save.disabled = true;

		Utils.removeTooltip();

		await this._save(disabledReload, disabledSetUrl);
	}

	public destructor() {
		super.destructor();

		clearInterval(this.intervalSocketIo);
	}
}

export default QuotesCtrl;
