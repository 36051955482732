import h from 'hyperscript';

import DecimalApps from '@libs/utils/Decimal';

import CE_RecapQuote, { dataRecapQuote, priceByGroup } from './RecapQuote';
import CE_RecapLine from './RecapLine';

type priceByQuoteAndGroup = {
	[idQuote: string]: priceByGroup
};

class RecapOrder extends HTMLElement {
	public static readonly tagName: string = 'ap-billing-request-recap-order';

	private N_recapSum: CE_RecapLine | null = null;
	private N_loadingRecapQuote: CE_RecapQuote | null = null;

	private N_quotes: CE_RecapQuote[] = [];

	public connectedCallback() {
		this.innerHTML = '';

		const N_recapTitle = h<CE_RecapLine>('ap-billing-request-recap-line', { attrs: { type: 'title' } });
		this.append(N_recapTitle);

		N_recapTitle.setDataTitle({
			textColLeft: 'Récapitulatif de facturation de l\'affaire',
			textColRight: 'Montant nouvelle facture'
		});

		this.N_recapSum = h<CE_RecapLine>('ap-billing-request-recap-line', { attrs: { type: 'sum', hideLastBtn: true } });
		this.append(this.N_recapSum);

		this.N_loadingRecapQuote = h<CE_RecapQuote>('ap-billing-request-recap-quote');
		this.append(this.N_loadingRecapQuote);

		this.N_loadingRecapQuote.loading();

		this.initEvent();
	}

	public async setData(quotes: dataRecapQuote[], priceByGroup: priceByQuoteAndGroup) {
		this.N_loadingRecapQuote?.remove();

		const priceQuote = {
			sum: new DecimalApps(0),
			bill: new DecimalApps(0),
			notBill: new DecimalApps(0)
		};

		const promises: Promise<void>[] = [];
		const registerQuotes: { id: string, text: string }[] = [];

		for (const item of quotes) {
			const N_recapQuote = h<CE_RecapQuote>('ap-billing-request-recap-quote');
			this.append(N_recapQuote);

			N_recapQuote.mode = this.mode;

			promises.push(N_recapQuote.setData(item, priceByGroup[item.quoteID]));

			N_recapQuote.addEventListener('update.price', () => {
				this.updateSum();
			});

			N_recapQuote.addEventListener('register.quote', ((e: CustomEvent<{ id: string, text: string }>) => {
				registerQuotes.push(e.detail);
			}) as EventListener);

			N_recapQuote.addEventListener('select.group', ((e: CustomEvent<any>) => {
				this.dispatchEvent(new CustomEvent('select.group', e));
			}) as EventListener);

			N_recapQuote.addEventListener('update.error', () => {
				this.dispatchEvent(new CustomEvent('update.error'));
			});

			for (const idGroup in priceByGroup[item.quoteID]) {
				for (const tva in priceByGroup[item.quoteID][idGroup]) {
					priceQuote.sum = priceQuote.sum.plus(DecimalApps.setDisplayNumber(priceByGroup[item.quoteID][idGroup][tva].sum));
					priceQuote.bill = priceQuote.bill.plus(DecimalApps.setDisplayNumber(priceByGroup[item.quoteID][idGroup][tva].bill));
					priceQuote.notBill = priceQuote.notBill.plus(DecimalApps.setDisplayNumber(priceByGroup[item.quoteID][idGroup][tva].notBill));
				}
			}

			this.N_quotes.push(N_recapQuote);
		}

		this.N_recapSum!.mode = this.mode;
		this.N_recapSum!.setDataTextLine({ category: 'Total', textColRight: 'Total', tva: '' });
		this.N_recapSum!.setDataPriceLine({
			sum: priceQuote.sum.toNumber(),
			bill: priceQuote.bill.toNumber(),
			notBill: priceQuote.notBill.toNumber(),
			tva: ''
		});

		await Promise.all(promises);

		this.dispatchEvent(new CustomEvent('register.quotes', { detail: registerQuotes }));
	}

	private initEvent() {
		this.N_recapSum?.addEventListener('update.percent', () => {
			for (const N_line of this.N_quotes) {
				N_line!.setPercentFromOrder(this.N_recapSum!.percent);
			}

			let sum = new DecimalApps(0);
			for (let i = 0; i < this.N_quotes.length - 1; i++) {
				const N_line = this.N_quotes[i];
				N_line!.setPercentFromOrder(this.N_recapSum!.percent);

				sum = sum.plus(DecimalApps.setDisplayNumber(N_line.price));
			}

			const lastLine = this.N_quotes[this.N_quotes.length - 1];
			const priceLastLine = DecimalApps.setDisplayNumber(this.N_recapSum!.price).minus(sum).toNumber();

			lastLine!.setPriceFromOrder(priceLastLine);
		});

		this.N_recapSum?.addEventListener('check.error', () => {
			this.dispatchEvent(new CustomEvent('check.error'));
		});

		this.N_recapSum?.addEventListener('update.lastPrice', () => {
			this.setLastPrice();
			this.N_recapSum?.checkError();
		});
	}

	private updateSum() {
		let price = new DecimalApps(0);

		for (const N_line of this.N_quotes) {
			price = price.plus(N_line.price);
		}

		this.N_recapSum!.setPrice(price.toNumber());
	}

	public set value(data: { [key: string]: any }) {
		for (const N_line of this.N_quotes) {
			N_line.value = data[N_line.idQuote];
		}
	}

	public get value() {
		const res: { [key: string]: any } = {};
		for (const N_line of this.N_quotes) {
			res[N_line.idQuote] = N_line.value;
		}
		return res;
	}

	public get error() {
		const N_RecapLine = this.querySelectorAll<CE_RecapLine>(CE_RecapLine.tagName);

		for (const N_el of N_RecapLine) {
			if (N_el.error) {
				return true;
			}
		}

		if (!this.N_recapSum!.price) {
			return true;
		}

		if (this.N_recapSum!.price < 0) {
			return true;
		}

		return false;
	}

	public set mode(value: string) {
		const test = this.querySelector<CE_RecapLine>('ap-billing-request-recap-line');
		if (test && (value === 'create-credit' || value === 'edit-credit')) {
			test.setDataTitle({
				textColLeft: 'Récapitulatif de la facture',
				textColRight: "Montant de l'avoir"
			});
		}

		this.setAttribute('mode', value);
	}

	public get mode() {
		return this.getAttribute('mode')!;
	}

	public get priceNotBill() {
		return this.N_recapSum!.priceNotBill;
	}

	public get price() {
		return this.N_recapSum!.price;
	}

	public setLastPrice() {
		this.N_recapSum!.setLastPrice();

		for (const N_line of this.N_quotes) {
			N_line.setLastPrice();
		}
	}

	public toggleLastPrice(isLast: boolean) {
		this.N_recapSum!.toggleLastPrice(isLast);

		for (const N_line of this.N_quotes) {
			N_line.toggleLastPrice(isLast);
		}
	}

	public static register() {
		customElements.define(RecapOrder.tagName, RecapOrder);
	}
}

export default RecapOrder;
