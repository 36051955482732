import { Module, Modules, Router } from '@autoprog/core-client';

import Navigation, { LinkItem } from '@libs/Navigation';

import C_ThirdParty from './js/ThirdParty';

import T_Tabs from '@tpl/tabs.html';

import '@modules/BillsCustomer/module';
import '@modules/BillsProvider/module';

class ThirdPartyModule<T> extends Module {
	private pageLinkItem: LinkItem;

	public constructor(options: Record<string, T>) {
		super(options);

		this.pageLinkItem = {
			category: 'Données',
			order: 0,
			name: 'Clients / Fournisseurs',
			type: 'tabs',
			icon: 'group/line',
			id: 'customers_providers',
			tabs: [
				{
					name: 'Clients',
					type: 'database',
					database: 'customers'

				},
				{
					name: 'Fournisseurs',
					type: 'database',
					database: 'providers'
				},
				{
					name: 'Transporteurs',
					type: 'database',
					database: 'transporters'
				}
			]
		};
	}

	public async preInit() {
		const navigation = Navigation.getInstance();

		navigation.addCategory({
			name: 'Données',
			order: 0
		});

		navigation.addLink(this.pageLinkItem);
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: 'tabs/customers_providers',
			controller: C_ThirdParty,
			tpl: T_Tabs,
			data: this.pageLinkItem,
			requireValidUser: true,
			default: false,
			permission: 'TRANSPORTERS.SHOW || CUSTOMERS.SHOW || PROVIDERS.SHOW'
		});

		router.addRoute({
			url: 'tabs/customers_providers/:tabs',
			controller: C_ThirdParty,
			tpl: T_Tabs,
			data: this.pageLinkItem,
			requireValidUser: true,
			default: false,
			permission: 'TRANSPORTERS.SHOW || CUSTOMERS.SHOW || PROVIDERS.SHOW'
		});
	}

	public async postInit() { }
}

Modules.registerModule('Tiers', ThirdPartyModule);
