import round from './round';

export default (result: { sumMO: number, sumMat: number, price: number, sum: number }, pref: { decimalNumber: number }) => {
	const percentMO = 100 * result.sumMO / (result.sumMO + result.sumMat);

	result.sumMO = round(result.sum * percentMO / 100, pref);
	result.sumMat = round(result.sum - result.sumMO, pref);

	//sécurité pour eviter les NaN (division par 0)
	result.sumMat = isNaN(result.sumMat) ? 0 : result.sumMat;
	result.sumMO = isNaN(result.sumMO) ? 0 : result.sumMO;
	result.sum = isNaN(result.sum) ? 0 : result.sum;
	result.price = isNaN(result.price) ? 0 : result.price;

	return result;
};
